import { MeServices } from 'types'
import API, { ME, headers } from 'api'
import { errors, api } from 'services'

const response = async (promise, key: string = null) => {
  const res = promise.status === 200 ? await promise.json() : promise
  return {
    ...res,
    status: promise.status,
    response: key ? errors.notify(key[promise.status], promise.status) : null,
  }
}

const Me: MeServices = {
  once: async () => {
    const promise = await fetch(`${API(ME.DEFAULT)}`, {
      method: 'GET',
      headers: headers('default', true),
    })
    return await response(promise, api.me.once)
  },
}

export default Me
