import React, { ReactElement } from 'react'

import APPLE_ROUNDED from 'assets/apple-rounded.svg'
import GOOGLE_ROUNDED from 'assets/google-rounded.svg'
import * as CardS from 'components/CardPreview/styles'
import * as S from './styles'
import { CompanyState } from 'types'
import { NotificationOnceResponse } from 'types/api'
import { parseApiPicture } from 'utils'

type WalletProps = {
  notification: NotificationOnceResponse
  company: CompanyState
}

const Devices = ({ notification, company }: WalletProps): ReactElement => {
  const mobileRender = (
    <>
      <S.MobileContent />
      <S.TitleNotification>Notifications</S.TitleNotification>
      <S.NotifContainer>
        <CardS.Row>
          <S.CompanyIcon src={parseApiPicture({ img: company?.logo, defaultImg: '' })} />
          <S.TitleUnderline>{company?.name}</S.TitleUnderline>
        </CardS.Row>
        <CardS.Divider />
        <S.TextContent>{notification?.text}</S.TextContent>
      </S.NotifContainer>
    </>
  )

  return (
    <CardS.Wrapper>
      <CardS.Row>
        <CardS.Icon src={APPLE_ROUNDED} />
        <CardS.Device type="ios">{mobileRender}</CardS.Device>
        <CardS.Device type="android">{mobileRender}</CardS.Device>
        <CardS.Icon src={GOOGLE_ROUNDED} />
      </CardS.Row>
    </CardS.Wrapper>
  )
}

export default Devices
