import React, { FC, useState, useEffect } from 'react'

import { ProviderProps, MyInformationsContextProps } from 'types'
import { MeInformations } from 'types/api'
import { me as meService, cookies } from 'services'

export const MyInformationsContext = React.createContext<MyInformationsContextProps | undefined>(
  undefined,
)

MyInformationsContext.displayName = 'Company'

const initialState = {
  user: {
    id: '',
    email: '',
    role: {
      value: '',
      key: '',
    },
    switched: false,
  },
  company: {
    id: '',
    name: '',
    wallets: [],
  },
  api: {
    version: '',
  },
}

const MyInformations: FC<ProviderProps> = ({ children }: ProviderProps) => {
  const [myInformations, setMyInformations] = useState<MeInformations>(initialState)

  const getInformations = () => {
    if (cookies.get('token')) {
      meService.once().then((resMyInformations) => {
        setMyInformations(resMyInformations)
      })
    }
  }

  useEffect(() => {
    getInformations()
  }, [])

  return (
    <MyInformationsContext.Provider
      value={{ myInformations, setMyInformations, refetchMyInformations: getInformations }}
    >
      {children}
    </MyInformationsContext.Provider>
  )
}

export default MyInformations
