import React, { ReactElement, ReactNode, useContext, Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import { Wrapper, Row, Device, Container, Icon, HeadBanner, SwitchArrow } from './styles'
import APPLE_ROUNDED from 'assets/apple-rounded.svg'
import GOOGLE_ROUNDED from 'assets/google-rounded.svg'
import { CompanyContext } from 'providers'
import Wallet from './Wallet'
import { CompanyState } from 'types'

type PreviewProps = {
  children?: ReactNode
  showVerso: boolean
  companyProps?: CompanyState
  setShowVerso?: Dispatch<SetStateAction<boolean>>
}

const CardPreview = ({ showVerso, setShowVerso, companyProps }: PreviewProps): ReactElement => {
  const { t } = useTranslation()
  const company = companyProps?.id ? companyProps : useContext(CompanyContext).company

  return (
    <Wrapper>
      <HeadBanner>
        {`${showVerso ? 'Verso' : 'Recto'} ${t('preview.wallet.head')}`}
        {setShowVerso && <SwitchArrow onClick={() => setShowVerso((prevState) => !prevState)} />}
      </HeadBanner>
      <Row>
        <Icon src={GOOGLE_ROUNDED} />
        <Device type="android">
          <Container>
            <Wallet showVerso={showVerso} os="android" company={company} />
          </Container>
        </Device>
        <Device type="ios">
          <Container>
            <Wallet showVerso={showVerso} os="ios" company={company} />
          </Container>
        </Device>
        <Icon src={APPLE_ROUNDED} />
      </Row>
    </Wrapper>
  )
}

export default CardPreview
