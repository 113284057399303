import React, { ReactElement } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import LOGO from 'assets/logo-black.svg'
import { routes } from 'router'

import { Container, Wrapper, Logo, Title, Guard, Cross, Image404 } from '../styles'

type Props = {
  closeModal: () => void
}

const ExpiredTokenModal = ({ closeModal }: Props): ReactElement => {
  const { t } = useTranslation()
  const { push } = useHistory()

  const close = () => {
    closeModal()
    push(routes.login.path)
  }

  return (
    <Wrapper>
      <Cross onClick={close} />
      <Logo src={LOGO} />
      <Container>
        <Title align={'center'} color={'primary'}>
          {t('login.forgot.expiredToken.title')}
        </Title>
        <Guard align={'center'}>{t('login.forgot.expiredToken.text')}</Guard>
      </Container>
      <Image404 />
    </Wrapper>
  )
}

export default ExpiredTokenModal
