import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import { cookies } from 'services'

export const lang = ['fr', 'en']

export const changeLanguage = (lang: 'fr' | 'en'): Promise<unknown> => {
  cookies.set([{ key: 'lang', value: lang }])
  return i18n.changeLanguage(lang)
}

export const initializeLanguage = (): Promise<unknown> => i18n.changeLanguage(cookies.get('lang'))

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    whitelist: lang,
    fallbackLng: 'fr',
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  })
  .then(() => 'i18next: initialized')

export default i18n
