import React, { ReactElement } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { isDesktop } from 'react-device-detect'

import {
  Company,
  Dashboard,
  Login,
  CreateEditCard,
  Customer,
  Notification,
  Admin,
  CreateEditNotification,
  DesktopDownloadWallet,
  MobileDownloadWallet,
} from 'layouts'
import routes from 'router/routes'
import PrivateRoute from 'router/Private'

const Router = (): ReactElement => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path={routes.login.path} exact component={Login} />
        <Route
          path={routes.downloadWallet.path}
          exact
          component={isDesktop ? DesktopDownloadWallet : MobileDownloadWallet}
        />
        <PrivateRoute path={routes.dashboard.path} exact>
          <Dashboard />
        </PrivateRoute>
        <PrivateRoute path={routes.company.path} exact>
          <Company />
        </PrivateRoute>
        <PrivateRoute path={routes.edit.path}>
          <CreateEditCard />
        </PrivateRoute>
        <PrivateRoute path={routes.customer.path} exact>
          <Customer />
        </PrivateRoute>
        <PrivateRoute path={routes.customersCardEdit.path} exact>
          <CreateEditCard />
        </PrivateRoute>
        <PrivateRoute path={routes.create.path} exact>
          <CreateEditCard />
        </PrivateRoute>
        <PrivateRoute path={routes.notification.path} exact>
          <Notification />
        </PrivateRoute>
        <PrivateRoute path={routes.createNotification.path} exact>
          <CreateEditNotification />
        </PrivateRoute>
        <PrivateRoute path={routes.duplicateNotification.path} exact>
          <CreateEditNotification />
        </PrivateRoute>
        <PrivateRoute path={routes.editNotification.path} exact>
          <CreateEditNotification />
        </PrivateRoute>
        <PrivateRoute path={routes.admin.path} exact>
          <Admin />
        </PrivateRoute>
      </Switch>
    </BrowserRouter>
  )
}

export { routes }
export default Router
