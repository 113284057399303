import React, { FC, useEffect, useState } from 'react'

import { useDarkMode } from 'hooks'
import { darkTheme, lightTheme } from 'theme'
import { ProviderProps, ThemeContextProps, ThemeState } from 'types'

export const ThemeContext = React.createContext<ThemeContextProps | undefined>(undefined)

ThemeContext.displayName = 'Theme'

const Theme: FC<ProviderProps> = ({ children }: ProviderProps) => {
  const [theme, themeToggler] = useDarkMode()
  const [currentTheme, setCurrentTheme] = useState<ThemeState>(
    theme === 'light' ? lightTheme : darkTheme,
  )

  //Prevent infinit loop in switch component
  const switchTheme = () => themeToggler()

  useEffect(() => {
    setCurrentTheme(theme === 'light' ? lightTheme : darkTheme)
  }, [theme])

  const context = {
    currentTheme,
    setCurrentTheme,
    switchTheme,
  }

  return <ThemeContext.Provider value={context}>{children}</ThemeContext.Provider>
}

export default Theme
