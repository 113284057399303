import baseStyled, { ThemedStyledInterface, ThemeProvider, css, keyframes } from 'styled-components'

import GlobalStyle from 'theme/global'
import { lightTheme } from 'theme/light'
import { darkTheme } from 'theme/dark'

export default GlobalStyle
export { ThemeProvider, lightTheme, darkTheme, css, keyframes }
export type Theme = typeof lightTheme
export { size, device } from './breakpoints'
export const styled = baseStyled as ThemedStyledInterface<Theme>
