import { FiUser, FiEye, FiEyeOff } from 'react-icons/fi'

import { styled, css } from 'theme'
import { InputProps } from './index'

const marginMixin = css`
  margin-top: ${(input: InputProps) => `${input.margin[0]}px`};
  margin-right: ${(input: InputProps) => `${input.margin[1]}px`};
  margin-bottom: ${(input: InputProps) => `${input.margin[2]}px`};
  margin-left: ${(input: InputProps) => `${input.margin[3]}px`};
`

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.color.alternative};
  ${(input: InputProps) => (input.margin ? marginMixin : 'margin: 0')}
`

export const Field = styled.input`
  width: 100%;
  font-size: 14px;
  line-height: 21px;
  padding: 16px 23px 16px ${(input: InputProps) => (input.noPadding ? '0' : '23px')};
  border: none;
  background: ${(props) => props.theme.color.background};
  color: ${(props) => props.theme.color.accent};
  &::placeholder {
    color: ${(props) => props.theme.color.alternative};
  }
  &:focus {
    outline-style: none;
    box-shadow: none;
    border-color: transparent;
  }
  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px ${(props) => props.theme.color.background} inset;
  }
  &:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 50px ${(props) => props.theme.color.background} inset;
    -webkit-text-fill-color: ${(props) => props.theme.color.accent};
  }
  -webkit-text-fill-color: ${(props) => props.theme.color.accent};
`

export const Email = styled(FiUser)`
  margin: 0 23px;
  font-size: 30px;
  color: ${(props) => props.theme.color.alternative};
`

export const PasswordHidden = styled(FiEyeOff)`
  margin: 0 23px;
  font-size: 30px;
  color: ${(props) => props.theme.color.alternative};
  cursor: pointer;
`

export const PasswordShown = styled(FiEye)`
  margin: 0 23px;
  font-size: 30px;
  color: ${(props) => props.theme.color.alternative};
  cursor: pointer;
`

export const Selector = styled.input`
  width: 42px;
  padding: 0;
  height: 42px;
  margin-right: -10px;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  ::-webkit-color-swatch {
    border-radius: 6px;
    border: 1px solid ${(props) => props.theme.color.alternative};
    padding: 0;
  }
  ::-webkit-color-swatch-wrapper {
    border-radius: 6px;
    padding: 0;
  }
`

export const Label = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 14px;
  line-height: 21px;
  padding: 10px 23px;
  border: none;
  border-radius: 6px;
  color: ${(props) => props.theme.color.alternative};
`

export const InvertedField = styled.input`
  width: 100%;
  font-size: 14px;
  line-height: 21px;
  padding: 16px 23px 16px ${(input: InputProps) => (input.noPadding ? '0' : '23px')};
  border: none;
  background: ${(props) => props.theme.color.accent};
  color: ${(props) => props.theme.color.background};
  &::placeholder {
    color: ${(props) => props.theme.color.alternative};
  }
  &:focus {
    outline-style: none;
    box-shadow: none;
    border-color: transparent;
  }
  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px ${(props) => props.theme.color.accent} inset;
  }
  &:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 50px ${(props) => props.theme.color.accent} inset;
    -webkit-text-fill-color: ${(props) => props.theme.color.background};
  }
  -webkit-text-fill-color: ${(props) => props.theme.color.background};
`
