import React, { Dispatch, SetStateAction, ReactElement } from 'react'

import {
  NextStep,
  PrevStep,
  Next,
  Prev,
  RowCenter,
  ButtonStyled,
  Submit,
  ValidCheck,
} from './styles'

export type BottomStepsProps = {
  currentStep: number
  setCurrentStep: Dispatch<SetStateAction<number>>
  submitNotification: () => void
  t: (string) => string
}

const BottomSteps = ({
  currentStep,
  setCurrentStep,
  submitNotification,
  t,
}: BottomStepsProps): ReactElement => (
  <RowCenter>
    <PrevStep
      onClick={() => setCurrentStep((prevState) => prevState - 1)}
      color={currentStep === 0 && 'grey'}
    >
      <Prev color={currentStep === 0 && 'grey'} />
      {t('edit.prev')}
    </PrevStep>
    {currentStep === 2 ? (
      <Submit>
        <ButtonStyled onClick={submitNotification}>
          {t('edit.content.validate')}
          <ValidCheck />
        </ButtonStyled>
      </Submit>
    ) : (
      <NextStep onClick={() => currentStep !== 2 && setCurrentStep((prevState) => prevState + 1)}>
        {t('edit.next')}
        <Next />
      </NextStep>
    )}
  </RowCenter>
)

export default BottomSteps
