export const size = {
  xs: 480,
  sm: 768,
  md: 1024,
  lg: 1366,
  xlg: 1980,
}

export const device = {
  xs: `@media (max-width: ${size.xs}px)`,
  sm: `@media (max-width: ${size.sm}px)`,
  md: `@media (max-width: ${size.md}px)`,
  lg: `@media (max-width: ${size.lg}px)`,
  xlg: `@media (max-width: ${size.xlg}px)`,
}
