import { useHistory } from 'react-router-dom'

import { usePermission } from 'hooks'
import { routes } from 'router'

export default function useRouteProtector(): void {
  const { permission } = usePermission()
  const { location, push } = useHistory()
  const currentRoute = location.pathname
  const routesPath = Object.values(routes).filter((route) => route.access && route.path !== '/')
  const allowedRoutes = routesPath.filter((route) => route.access.includes(permission.type))
  const matches = allowedRoutes.filter((route) => currentRoute.includes(route.path))

  const publicRoutes = [routes.login.path, routes.downloadWallet.path, routes.dashboard.path]

  // Access to dashboard is available for every logged in users.
  if (!publicRoutes.includes(currentRoute)) {
    // Testing with includes for routes preventing encountering a error with slug params.
    // Every defined sub routes eg: /routes/[definedSubRoute]/:slug .
    if (matches.length) {
      matches.forEach((route) => {
        if (!route.access.includes(permission.type)) return push(routes.dashboard.path)
      })
    } else return push(routes.dashboard.path)
  }
}
