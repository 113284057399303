import { baseUrl } from 'api'

const parseApiPicture = ({ img, defaultImg }) => {
  if (!img) {
    return defaultImg || ''
  } else if (typeof img === 'string') {
    return baseUrl.replace('/v1/', '') + img
  } else {
    return URL.createObjectURL(img)
  }
}

export default parseApiPicture
