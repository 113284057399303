import { styled } from 'theme'
import { colors } from 'theme/colors'

type Props = {
  active: boolean
}

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 40px;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Step = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin-right: 25px;
  margin-bottom: 20px;
  & span {
    color: ${(step: Props) => !step.active && colors.greyish};
    border-color: ${(step: Props) => !step.active && colors.greyish};
  }
`
export const Text = styled.span`
  font-style: italic;
  font-size: 12px;
  color: ${(props) => props.theme.color.accent};
`
export const TitleStep = styled.span`
  color: ${(props) => props.theme.color.primary};
  font-size: 14px;
  font-weight: 600;
  padding: 8px 2px;
  border-bottom: 1px solid ${(props) => props.theme.color.accent};
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`
