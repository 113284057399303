import { AdminServices } from 'types'
import API, { ADMIN, headers } from 'api'
import { errors, api } from 'services/index'

const response = async (promise, key: string = null, methodName: 'DELETE' | null = null) => {
  const res = methodName !== 'DELETE' ? await promise.json() : {}

  if (key) {
    return {
      ...res,
      status: promise.status,
      response: errors.notify(key[promise.status], promise.status),
    }
  }
  return {
    ...res,
    status: promise.status,
    response: null,
  }
}

const admin: AdminServices = {
  collection: async ({ page, itemsPerPage } = { page: 1, itemsPerPage: 30 }) => {
    const params = [page && `page=${page}`, itemsPerPage && `itemsPerPage=${itemsPerPage}`]
    const url = `${API(ADMIN.DEFAULT)}?${params.join('&')}`
    const promise = await fetch(url, { method: 'GET', headers: headers() })
    return response(promise, api.admin.collection)
  },
  once: async ({ id }) => {
    const promise = await fetch(`${API(ADMIN.DEFAULT)}/${id}`, {
      method: 'GET',
      headers: headers(),
    })
    return response(promise, api.admin.once)
  },
  create: async ({ email }) => {
    const promise = await fetch(API(ADMIN.DEFAULT), {
      method: 'POST',
      body: JSON.stringify({ email }),
      headers: headers(),
    })
    return response(promise, api.admin.create)
  },
  update: async ({ id }, body: { email }) => {
    const queryParams = `${API(ADMIN.DEFAULT)}/${id}`
    const promise = await fetch(`${queryParams}`, {
      method: 'PATCH',
      body: JSON.stringify(body),
      headers: headers(),
    })
    return response(promise, api.admin.update)
  },
  remove: async ({ id }) => {
    const queryParams = `${API(ADMIN.DEFAULT)}/${id}`
    const promise = await fetch(`${queryParams}`, {
      method: 'DELETE',
      headers: headers(),
    })
    return response(promise, api.admin.remove, 'DELETE')
  },
}

export default admin
