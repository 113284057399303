import { FiCheckCircle, FiAlertTriangle, FiInfo, FiXCircle } from 'react-icons/fi'
import { CSVLink } from 'react-csv'
import { MdOutlineClose, BiSortAlt2 } from 'react-icons/all'

import { styled, css, keyframes } from 'theme'
import { colors } from 'theme/colors'
import { Text } from 'components'

type NotificationType = {
  type: string
  play?: number
  duration: number
}

const slideInUp = keyframes`
  0% {
    opacity: 0;
    bottom: -460px;
  }
  10% {
    opacity: 1;
    bottom: 24px;
  }
  90% {
    opacity: 1;
    bottom: 24px;
  }
  100% {
    opacity: 0;
    bottom: -460px;
  }
`

const slideInDown = keyframes`
  0% {
    opacity: 0;
    top: -100px;
  }
  10% {
    opacity: 1;
    top: 16px;
  }
  90% {
    opacity: 1;
    top: 16px;
  }
  100% {
    opacity: 0;
    top: -100px;
  }
`

const toaster = css`
  animation: ${(notification: NotificationType) => notification.play && slideInDown}
    ${(notification: NotificationType) => notification.duration}ms forwards;
`

const animation = css`
  animation: ${(notification: NotificationType) => notification.play && slideInUp}
    ${(notification: NotificationType) => notification.duration}ms forwards;
`

const genericStyle = css`
  z-index: 101;
  position: fixed;
  display: flex;
  border-radius: 8px;
  opacity: 0;
  box-shadow: ${(props) => props.theme.shadow};
  background: ${(notification: NotificationType) => colors[notification.type]};
`

export const Wrapper = styled.div`
  bottom: -460px;
  width: 460px;
  right: 24px;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 8px;
  ${() => genericStyle}
  ${() => animation}
`

export const Container = styled.div`
  ${() => genericStyle};
  ${() => animation};
  width: calc(100vw - (26px * 2));
  margin: 0 auto;
  right: 0;
  left: 0;
  top: 16px;
  align-items: center;
  justify-content: center;
  padding: 16px 26px;
  border-radius: 8px;
  opacity: 1;
  z-index: 1001;
  ${() => toaster}
`

export const Cross = styled(MdOutlineClose)`
  position: absolute;
  font-size: 30px;
  top: 13px;
  right: 13px;
  cursor: pointer;
`

export const Content = styled.div`
  padding: 15px 26px;
`

export const Title = styled(Text)`
  margin: 0;
  padding-bottom: 4px;
  font-size: 15px;
  text-transform: uppercase;
  color: ${colors.primaryWhite};
  font-weight: 600;
`

export const Message = styled(Text)`
  margin: 0;
  font-size: 14px;
  opacity: 0.7;
  line-height: 21px;
  color: ${colors.primaryWhite};
`

export const Type = styled(Text)`
  margin: 0;
  padding: 15px 26px;
  font-size: 20px;
  opacity: 0.68;
  text-transform: uppercase;
  color: ${colors.primaryWhite};
  font-weight: 600;
  text-align: right;
`

const Icon = css`
  margin-right: 10px;
  margin-top: -3px;
  font-size: 40px;
  font-weight: bold;
  color: ${colors.primaryWhite};
`

export const Success = styled(FiCheckCircle)`
  ${Icon}
`

export const Error = styled(FiXCircle)`
  ${Icon}
`

export const Warning = styled(FiAlertTriangle)`
  ${Icon}
`

export const Info = styled(FiInfo)`
  ${Icon}
`

export const TableRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 97%;
  position: relative;
`

export const TabColumns = styled.div`
  width: 100%;
  overflow-x: scroll;
  &::-webkit-scrollbar-thumb {
    background: grey;
  }
  &::-webkit-scrollbar {
    height: 10px;
  }
`

export const DownloadCustomers = styled(CSVLink)`
  display: none;
`

export const SortIcon = styled(BiSortAlt2)`
  height: 20px;
  margin-left: 5px;
  cursor: pointer;
  margin-top: ${({ marginTop }: { marginTop?: number }) => (marginTop ? `${marginTop}px` : '0')};
`
