import React, { ReactElement, useState } from 'react'

import {
  Wrapper,
  Field,
  PasswordShown,
  Email,
  PasswordHidden,
  Label,
  Selector,
  InvertedField,
} from './styles'

export type InputProps = React.HTMLProps<HTMLInputElement> & {
  margin?: [number, number, number, number]
  placeholder?: string
  type?: 'password' | 'email' | 'color'
  icon?: boolean
  initial?: string
  invert?: boolean
  noPadding?: boolean
  required?: boolean
  value?: string
  onPressEnter?: () => void
  onChange?: (event) => void
}

const Input = ({
  placeholder,
  type,
  margin,
  value,
  onChange,
  icon,
  invert,
  noPadding,
  onPressEnter,
  required,
}: InputProps): ReactElement => {
  const [passwordVisibility, togglePasswordVisibility] = useState<boolean>(false)

  function renderIcon(): JSX.Element {
    if (type === 'email') return <Email />
    if (type === 'password' && passwordVisibility)
      return <PasswordShown onClick={() => togglePasswordVisibility(false)} />
    return <PasswordHidden onClick={() => togglePasswordVisibility(true)} />
  }

  function setType(): string {
    if (type === 'password' && passwordVisibility) return 'text'
    return type || 'text'
  }

  const ColorPicker = ({ placeholder, initial }: InputProps): JSX.Element => (
    <Label>
      {placeholder}
      <Selector
        defaultValue={initial}
        type="color"
        value={value}
        onChange={(e) => onChange && onChange(e)}
      />
    </Label>
  )

  if (type === 'color')
    return (
      <Wrapper margin={margin}>
        <ColorPicker placeholder={placeholder} />
      </Wrapper>
    )

  const whenPressEnter = (event) => event.key === 'Enter' && onPressEnter?.()

  return (
    <Wrapper margin={margin}>
      {invert ? (
        <InvertedField
          noPadding={noPadding}
          required={required}
          placeholder={placeholder}
          value={value}
          onChange={(event) => onChange?.(event)}
          onKeyPress={whenPressEnter}
          // @ts-ignore
          type={setType()}
        />
      ) : (
        <Field
          noPadding={noPadding}
          required={required}
          placeholder={placeholder}
          value={value}
          onChange={(event) => onChange?.(event)}
          onKeyPress={whenPressEnter}
          // @ts-ignore
          type={setType()}
        />
      )}
      {type && icon && renderIcon()}
    </Wrapper>
  )
}

export default Input
