import { styled, css, keyframes } from 'theme'
import { FiArrowRight, FiPlus } from 'react-icons/fi'
import { ButtonType } from './index'
import { colors } from 'theme/colors'

const marginMixin = css`
  margin-top: ${(input: ButtonType) => `${input.margin[0]}px`};
  margin-right: ${(input: ButtonType) => `${input.margin[1]}px`};
  margin-bottom: ${(input: ButtonType) => `${input.margin[2]}px`};
  margin-left: ${(input: ButtonType) => `${input.margin[3]}px`};
`

export const Wrapper = styled.button`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 80px;
  border: none;
  background: ${colors.mikadoYellow};
  ${(input: ButtonType) => (input.margin ? marginMixin : 'margin: 0')};
  cursor: pointer;
  transition: 300ms;
  &:hover {
    transform: scale(1.02);
  }
  &:focus {
    outline: none;
  }
`

export const Text = styled.p`
  font-size: 14px;
  line-height: 21px;
  padding: 15px 23px;
  margin: 0;
  color: ${colors.primaryWhite};
`

export const Arrow = styled(FiArrowRight)`
  margin: 0 23px;
  font-size: 30px;
  color: ${colors.primaryWhite};
`

export const Add = styled(FiPlus)`
  margin: 0 23px;
  font-size: 30px;
  color: ${colors.primaryWhite};
  cursor: pointer;
`

const spin = keyframes`
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`

export const Loading = styled.div`
  border: 2px solid transparent;
  border-radius: 50%;
  border-top: 3px solid ${colors.primaryWhite};
  width: 24px;
  height: 24px;
  margin: 0 18px;
  -webkit-animation: ${spin} 700ms linear infinite;
  animation: ${spin} 700ms linear infinite;
`
