import React, { ReactElement, useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Content, Step, Text, TitleStep, Header } from './styles'
import Layout from 'layouts'
import { Title, Loader } from 'components'
import FIELD_LOCATIONS from 'constants/fieldLocations'
import { Company } from 'providers'
import { Informations, Formatting, Contents } from 'components/FormCreateEditNotification'
import { Notification, CompanyState, Cards } from 'types'
import { getUrlParams } from 'utils'
import { wallet as walletService, notification as NotificationService } from 'services'
import { NotificationContext } from 'providers'
import { sendNotification } from 'components/FormCreateEditNotification/functions/SendNotification'

export type Step = { id: number; title: string; disabled: boolean; active: boolean }

export type StepsState = {
  company: Step[]
  customer: Step[]
}

const tomorrow = new Date()
tomorrow.setDate(tomorrow.getDate() + 1)

const dayAfterTomorrow = new Date()
dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 2)

const minDiffBetweenNowAndSentAt = 15

const datePlusMinDiff = (date: Date | string) => {
  const newDate = new Date(date)
  const dateTime = newDate.getTime()
  newDate.setTime(dateTime + minDiffBetweenNowAndSentAt * 60000)
  return newDate
}

export const defaultSentAt = datePlusMinDiff(new Date())
export const defaultEndAt = dayAfterTomorrow

const differenceOfTwoDatesInMinutes = (date1: Date, date2: Date) => {
  const diff = date1.getTime() - date2.getTime()
  return diff / (1000 * 60 * 60)
}

const CreateEditNotification = (): ReactElement => {
  const { t } = useTranslation()
  const { notify } = useContext(NotificationContext)
  const { location, push } = useHistory()
  const [currentStep, setCurrentStep] = useState<number>(0)
  const [company, setCompany] = useState<CompanyState>()
  const [isLoad, setIsLoad] = useState<boolean>(false)
  const [isNotificationSent, setIsNotificationSent] = useState<boolean>(false)
  const [notification, setNotification] = useState<Notification>({
    targetSending: true,
    definedSending: false,
    text: '',
    head: '',
    customers: [],
    sentAt: defaultSentAt,
    endAt: defaultEndAt,
    name: '',
    fields: [
      {
        title: '',
        subtitle: '',
        type: 'text',
        location: FIELD_LOCATIONS.BACK,
        id: 1,
        isOverride: false,
      },
    ],
  })

  const { notificationId: updateNotificationId, duplicateNotificationId } = getUrlParams()

  const steps: Array<string> = [t('edit.steps.0'), t('edit.steps.1'), t('edit.steps.2')]

  const edit: boolean = location.pathname.includes('/edit')

  const submitNotification = () => {
    let action = 'create'
    if (duplicateNotificationId) {
      action = 'duplicate'
    } else if (updateNotificationId) {
      action = 'update'
    }

    sendNotification({
      action,
      notification,
      notificationId: updateNotificationId || duplicateNotificationId,
      setIsLoad,
      t,
      notify,
      push,
    })
  }

  const factoCards = (fields: Cards) =>
    fields?.map((field, index) => ({
      title: field.label,
      subtitle: field.value,
      type: field.type,
      location: field.location || FIELD_LOCATIONS.BACK,
      id: index + 1,
      isOverride: false,
    }))

  const getDefaultNotification = (notificationId) => {
    NotificationService.once({ id: notificationId }).then((res) => {
      if (res.status === 200) {
        const sentAt = duplicateNotificationId ? defaultSentAt : new Date(res.sentAt)?.toISOString()
        const endAt = duplicateNotificationId ? defaultEndAt : new Date(res.endAt)?.toISOString()
        setIsNotificationSent(new Date(sentAt) < new Date())
        setNotification({
          head: res.head || '',
          customers: res.customers?.map((customer) => customer.sharedKey) || [],
          customersTargeted: res.customersTargeted,
          text: res.text || '',
          name: res.name || '',
          sentAt,
          endAt,
          targetSending: res.customersTargeted || res.customers?.length ? true : false,
          definedSending:
            differenceOfTwoDatesInMinutes(new Date(sentAt), new Date()) >
            minDiffBetweenNowAndSentAt,
          fields: res.fields?.length ? factoCards(res.fields) : notification.fields,
        })
      }
    })
  }

  useEffect(() => {
    // GET DEFAULT NOTIFICATION DATA IF IT'S AN EDITION OR COPY
    if (updateNotificationId) {
      getDefaultNotification(updateNotificationId)
    }
    if (duplicateNotificationId) {
      getDefaultNotification(duplicateNotificationId)
    }

    walletService.collection().then((res) => {
      if (res.status === 200) {
        const companyResponse = res['hydra:member']?.[0]
        setCompany(companyResponse)
      }
    })
  }, [])

  // CHANGE WALLET HEAD WITH NEW NOTIFICATION HEAD
  useEffect(() => {
    if (notification.head) setCompany({ ...company, head: notification.head })
  }, [notification])

  return (
    <Layout>
      <Loader active={isLoad} />
      <Company>
        <Title margin={20} size={28}>
          {t(`notification.form.${edit ? 'update' : 'create'}.title`)}
        </Title>

        <Header>
          {steps.map((step, index) => (
            <Step
              onClick={() => setCurrentStep(index)}
              active={index === currentStep}
              key={index.toString()}
            >
              <Text>
                {t('edit.form.step')} {index + 1}
              </Text>
              <TitleStep>{step}</TitleStep>
            </Step>
          ))}
        </Header>

        <Content>
          {currentStep === 0 ? (
            <Informations
              t={t}
              setNotification={setNotification}
              notification={notification}
              bottomStepsProps={{ currentStep, setCurrentStep, submitNotification, t }}
              isNotificationSent={isNotificationSent}
            />
          ) : currentStep === 1 ? (
            <Formatting
              t={t}
              company={company}
              setNotification={setNotification}
              notification={notification}
              bottomStepsProps={{ currentStep, setCurrentStep, submitNotification, t }}
            />
          ) : (
            <Contents
              company={company}
              setNotification={setNotification}
              notification={notification}
              bottomStepsProps={{ currentStep, setCurrentStep, submitNotification, t }}
            />
          )}
        </Content>
      </Company>
    </Layout>
  )
}

export default CreateEditNotification
