import { useEffect, useState } from 'react'
import { statistics as statisticsServices } from 'services'
import { initialState } from 'providers/Statistics'

type MonthStats = {
  x: string
  y: number
}

type BrandValues = {
  total: number
  months: Array<MonthStats>
}

type StatsValues = {
  apple: BrandValues
  google: BrandValues
}

type TotalMonths = {
  apple: Array<MonthStats>
  google: Array<MonthStats>
}

const defaultDate = (monthIndex) =>
  new Intl.DateTimeFormat('en').format(new Date(new Date().getFullYear(), monthIndex, 1))
const defaultMonths = [
  {
    x: defaultDate(0),
    y: 0,
  },
  {
    x: defaultDate(1),
    y: 0,
  },
  {
    x: defaultDate(2),
    y: 0,
  },
  {
    x: defaultDate(3),
    y: 0,
  },
  {
    x: defaultDate(4),
    y: 0,
  },
  {
    x: defaultDate(5),
    y: 0,
  },
  {
    x: defaultDate(6),
    y: 0,
  },
  {
    x: defaultDate(7),
    y: 0,
  },
  {
    x: defaultDate(8),
    y: 0,
  },
  {
    x: defaultDate(9),
    y: 0,
  },
  {
    x: defaultDate(10),
    y: 0,
  },
  {
    x: defaultDate(11),
    y: 0,
  },
]

const useStatistics = (setStatistics, statistics) => {
  const [statsValues, setStatsValues] = useState<StatsValues>()
  const [totalMounths, setTotalMounths] = useState<TotalMonths>()

  const calcMonthsStats = (stats) => {
    const apple = []
    const google = []

    const now = new Date()
    const nextMonth = (month) => (month === 11 ? 0 : month + 1)
    const lastYear = new Date(now.getFullYear() - 1, nextMonth(now.getMonth()), 1)
    const getMonthValue = (month, index) => {
      const calcMonth = month + index
      if (calcMonth > 12) {
        return calcMonth - 12
      }
      return calcMonth
    }

    const getCompleteDate = (month, index) => {
      const calcMonth = month + index
      if (calcMonth > 11) {
        return new Intl.DateTimeFormat('en').format(
          new Date(lastYear.getFullYear() + 1, calcMonth - 12, 1),
        )
      }
      return new Intl.DateTimeFormat('en').format(new Date(lastYear.getFullYear(), calcMonth, 1))
    }

    Object.keys(stats.appleMonths).forEach((months, index) => {
      apple.push({
        x: getCompleteDate(lastYear.getMonth(), index),
        y: parseInt(stats.appleMonths[getMonthValue(lastYear.getMonth(), index + 1)]),
      })
      google.push({
        x: getCompleteDate(lastYear.getMonth(), index),
        y: parseInt(stats.googleMonths[getMonthValue(lastYear.getMonth(), index + 1)]),
      })
    })
    setTotalMounths({ apple, google })
  }

  const parseStats = (stats) => {
    setStatistics(stats)
    calcMonthsStats(stats)
  }

  const getStatistics = () => {
    statisticsServices.collection().then((res) => {
      if (res.status === 200) {
        parseStats(res)
      } else {
        setStatistics(initialState)
      }
    })
  }

  useEffect(() => {
    getStatistics()
  }, [])

  useEffect(() => {
    if (totalMounths) {
      setStatsValues({
        apple: {
          total: statistics.appleTotal,
          months: totalMounths.apple,
        },
        google: {
          total: statistics.googleTotal,
          months: totalMounths.google,
        },
      })
    } else {
      setStatsValues({
        apple: {
          total: 0,
          months: defaultMonths,
        },
        google: {
          total: 0,
          months: defaultMonths,
        },
      })
    }
  }, [totalMounths])

  return { statsValues, getStatistics }
}

export default useStatistics
