import { Link as LinkTo } from 'react-router-dom'

import { Text as Typography } from 'components'
import { styled } from 'theme'

const LeftSpacing = '74px'

export const Wrapper = styled.div`
  position: absolute;
  width: calc(100vw - 297px);
  height: 74px;
  background: ${({ theme }) => theme.color.background};
  margin-left: -${LeftSpacing};
  border-radius: 0 0 13px 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  bottom: 5px;
  z-index: 10;
`

export const Text = styled(Typography)`
  margin-left: ${LeftSpacing};
  font-size: 12px;
`

export const Links = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${LeftSpacing};
`

export const Link = styled(LinkTo)`
  font-size: 12px;
  margin-left: 15px;
  color: ${({ theme }) => theme.color.alternative};
`
