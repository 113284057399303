import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from 'antd'

import { Illustration } from './styles'
import { Title } from 'components'
import * as GlobalStyle from 'styles/globalStyle'

import tutoImg1 from 'assets/tutos/web-push-1.png'
import tutoImg2 from 'assets/tutos/web-push-2.png'
import tutoImg3 from 'assets/tutos/web-push-3.png'
import tutoImg4 from 'assets/tutos/web-push-4.png'

type Props = {
  closeModal: () => void
  handleOk: () => void
}

const ActivateNotificationTuto = ({ closeModal, handleOk }: Props): ReactElement => {
  const { t } = useTranslation()

  return (
    <Modal
      visible
      footer={[]}
      title={t('notification.allowNotifications')}
      onOk={handleOk}
      onCancel={closeModal}
    >
      <GlobalStyle.Column>
        <GlobalStyle.Text
          align="center"
          color="mikadoYellow"
          fontWeight={500}
          margin="0 0 30px 0"
          fontSize={25}
        >
          {t('downloadWallet.reactivateNotificationTitle')}
        </GlobalStyle.Text>
        <Title margin="0 0 12px 0" size={18}>
          {t('step', { number: 1 })}
        </Title>
        <Illustration src={tutoImg1} />
        <Title margin="0 0 12px 0" size={18}>
          {t('step', { number: 2 })}
        </Title>
        <Illustration src={tutoImg2} />
        <Title margin="0 0 12px 0" size={18}>
          {t('step', { number: 3 })}
        </Title>
        <Illustration src={tutoImg3} />
        <Title margin="0 0 12px 0" size={18}>
          {t('step', { number: 4 })}
        </Title>
        <Illustration src={tutoImg4} />
        <GlobalStyle.Button.primary margin="20px 0" onClick={handleOk}>
          {t('validate')}
        </GlobalStyle.Button.primary>
      </GlobalStyle.Column>
    </Modal>
  )
}

export default ActivateNotificationTuto
