import { FiMoon, FiSun } from 'react-icons/fi'

import { styled } from 'theme'

export const Button = styled.button`
  position: fixed;
  bottom: 16px;
  left: 18px;
  width: 34px;
  height: 34px;
  border-radius: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${(props) => props.theme.color.alternative};
  background: ${(props) => props.theme.color.shaded};
  cursor: pointer;
  transition: 200ms;
  :hover {
    transform: scale(1.1);
  }
  :focus {
    outline: none;
  }
`

export const Sun = styled(FiSun)`
  font-size: 26px;
  color: ${(props) => props.theme.color.alternative};
`

export const Moon = styled(FiMoon)`
  font-size: 26px;
  color: ${(props) => props.theme.color.alternative};
`
