import React, { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getUrlParams } from 'utils'
import { changeLanguage } from 'lang'
import {
  DesktopLayout,
  LogoSmall,
  LogoWhite,
  CardTitle,
  LogoGooglePlay,
  QrCode,
  StepText,
} from './styles'
import * as GlobalStyle from 'styles/globalStyle'
import NotFoundWallet from './NotFoundWallet'

type UrlParams = {
  companyId: string
  sharedKey: string
  customerId: string
  lang?: 'fr' | 'en'
}

const DesktopDownloadWallet = (): ReactElement => {
  const { t } = useTranslation()
  const [isBadWalletUrl, setIsBadWalletUrl] = useState<boolean>(false)

  const urlParams: UrlParams = getUrlParams()

  const qrImage = `${process.env.REACT_APP_API_ENDPOINT}qrcode/${urlParams.companyId}/${urlParams.sharedKey}`

  useEffect(() => {
    // CHECK IF WALLET URL EXIST
    const image = new Image()
    image.src = qrImage
    image.onerror = function () {
      setIsBadWalletUrl(true)
    }

    // DETECT BROWSER LANGUAGE
    if (navigator.languages?.[0]?.includes('en')) {
      changeLanguage('en')
    } else {
      changeLanguage('fr')
    }
  }, [])

  const Steps = Array(4)
    .fill(null)
    .map((elem, index) => (
      <GlobalStyle.Row alignItems="center" key={index + 2} margin="15px 0 0 0">
        <StepText margin="0 10px 0 0">{index + 2}.</StepText>
        <GlobalStyle.Text fontWeight={500}>
          {t(`downloadWallet.desktop.step${index + 2}`)}
        </GlobalStyle.Text>
      </GlobalStyle.Row>
    ))

  return isBadWalletUrl ? (
    <NotFoundWallet />
  ) : (
    <DesktopLayout>
      <LogoWhite />
      <LogoSmall />
      <GlobalStyle.Card width="800px">
        <CardTitle>{t('downloadWallet.desktop.title')}</CardTitle>

        <GlobalStyle.Row>
          <QrCode src={qrImage} />
          <GlobalStyle.Column margin="0px 5%" width="60%">
            <GlobalStyle.Row>
              <StepText margin="-2px 10px 0 0">1.</StepText>
              <GlobalStyle.Column>
                <GlobalStyle.Text fontWeight={500}>
                  {t('downloadWallet.desktop.step1')}
                </GlobalStyle.Text>
                <GlobalStyle.Text>{t('downloadWallet.desktop.subStep1')}</GlobalStyle.Text>
                <a
                  href="https://play.google.com/store/apps/details?id=com.google.ar.lens&hl=fr&gl=US"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LogoGooglePlay />
                </a>
              </GlobalStyle.Column>
            </GlobalStyle.Row>
            {Steps}
          </GlobalStyle.Column>
        </GlobalStyle.Row>
      </GlobalStyle.Card>
    </DesktopLayout>
  )
}

export default DesktopDownloadWallet
