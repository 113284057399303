import React, { ReactElement } from 'react'
import { Label, Input, Dot } from 'components/Toggle/styles'

type Props = {
  checked?: boolean
}

const Toggle = (props: Props): ReactElement => {
  return (
    <Label>
      <Input type="checkbox" {...props} readOnly />
      <Dot />
    </Label>
  )
}

export default Toggle
