import Gravatar from 'react-gravatar'

import { styled, device } from 'theme'
import { TextEllipsis } from 'styles/globalStyle'
import { colors } from 'theme/colors'
import { IoMdKey } from 'react-icons/io'

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 0;
  margin-right: 74px;
  margin-bottom: 40px;
  border-bottom: 1px solid ${colors.greyish};
  transition: 300ms;
  ${device.sm} {
    margin-left: -74px;
    margin-right: 0;
    padding-right: 20px;
  }
`

export const Lang = styled.div`
  padding: 0 20px;
  display: flex;
  width: 90px;
  justify-content: space-between;
  & p {
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
    }
  }
  ${device.xs} {
    visibility: hidden;
  }
`

export const User = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const Avatar = styled(Gravatar)`
  width: 56px;
  height: 56px;
  border-radius: 56px;
  margin-right: 22px;
  background-color: ${(props) => props.theme.color.background};
  ${device.sm} {
    width: 43px;
    height: 43px;
  }
`

export const Rank = styled.div`
  z-index: 2;
  width: 20px;
  height: 20px;
  background: ${colors.mikadoYellow};
  border-radius: 20px;
  margin-bottom: -34px;
  margin-right: -60px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Info = styled.div`
  & p {
    margin-bottom: 0;
  }
`

export const Role = styled(TextEllipsis)`
  text-transform: capitalize;
  color: ${colors.mikadoYellow};
  padding: 0;
  margin-top: 2px;
  font-size: 10px;
  max-width: 100px;
`

export const Key = styled(IoMdKey)`
  color: ${colors.primaryWhite};
  font-size: 14px;
`
