import React, { ReactElement, Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { Radio, Space } from 'antd'

import { Wrapper, Cross, Overlay } from './styles'
import { Title } from 'components'
import * as GlobalStyle from 'styles/globalStyle'

type Props = {
  closeModal: () => void
  callBackFunction: () => void
  setCsvDelimiter: Dispatch<SetStateAction<string>>
  csvDelimiter: string
}

const DefineDelimiterModal = ({
  closeModal,
  callBackFunction,
  setCsvDelimiter,
  csvDelimiter,
}: Props): ReactElement => {
  const { t } = useTranslation()

  return (
    <Overlay>
      <Wrapper>
        <Cross onClick={closeModal} />
        <GlobalStyle.ColumnCenter>
          <Title margin={'15px'} size={14}>
            {t('customers.chooseDelimiter')}
          </Title>
          <Radio.Group
            onChange={(event) => setCsvDelimiter(event.target.value)}
            value={csvDelimiter}
          >
            <Space direction="horizontal">
              <Radio value={';'}>{`";" (${t('semicolon')})`}</Radio>
              <Radio value={','}>{`"," (${t('comma')})`}</Radio>
            </Space>
          </Radio.Group>
          <GlobalStyle.Button.primary margin="20px 0" onClick={callBackFunction}>
            {t('validate')}
          </GlobalStyle.Button.primary>
        </GlobalStyle.ColumnCenter>
      </Wrapper>
    </Overlay>
  )
}

export default DefineDelimiterModal
