import React, { ReactElement, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueries } from 'react-query'

import {
  company as companyService,
  customer as customerService,
  notification as notificationService,
} from 'services'
import { Counter, Title, Graphic } from 'components'
import { usePermission, useStatistics } from 'hooks'
import ROLES from 'constants/roles'
import { Top } from './styles'
import Layout from 'layouts'
import { StatisticsContext, UserContext } from 'providers'

const Dashboard = (): ReactElement => {
  const { t } = useTranslation()
  const { permission, isCompany } = usePermission()
  const { statistics, setStatistics } = useContext(StatisticsContext)
  const { user } = useContext(UserContext)
  const { statsValues } = useStatistics(setStatistics, statistics)

  const itemsPerPage = 30
  const staleTime = {
    staleTime: 5 * 1000,
    placeholderData: { 'hydra:totalItems': 0 },
  }

  const resultsCounter = useQueries([
    // @ts-ignore
    {
      queryKey: ['customers', 'collection', user.userId],
      queryFn: () => isCompany() && customerService.collection({ page: 1, itemsPerPage }),
      ...staleTime,
    },
    // @ts-ignore
    {
      queryKey: ['companies', 'collection', user.userId],
      queryFn: () => permission.admin && companyService.collection({ page: 1, itemsPerPage }),
      ...staleTime,
    },
    // @ts-ignore
    {
      queryKey: ['notifications', 'collection', user.userId],
      queryFn: () =>
        permission.company && notificationService.collection({ page: 1, itemsPerPage }),
      ...staleTime,
    },
  ])

  const counterType = (): 'company' | 'customer' | 'notification' =>
    permission.type === ROLES.COMPANY_ADMIN ? 'customer' : 'company'

  return (
    <Layout>
      <Top>
        <Counter
          marginRight={40}
          type={counterType()}
          count={resultsCounter[permission.company ? 0 : 1]?.data?.['hydra:totalItems']}
        />
        {permission.company && <Counter type={'notification'} count={statistics.notifications} />}
      </Top>
      <Title margin={20} size={28}>
        {t('dashboard.device')}
      </Title>
      {statsValues?.google && <Graphic value={statsValues.google} type="google" wip />}
      {statsValues?.apple && <Graphic value={statsValues.apple} type="apple" wip />}
    </Layout>
  )
}

export default Dashboard
