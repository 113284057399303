import { RefreshTokenResponse } from 'types/api'
import { cookies } from 'services/index'
import { JWTPayload } from 'types'
import { Dispatch, SetStateAction } from 'react'
import { ROLES } from 'constants/roles'

const authenticate = {
  feedBrowser: (
    data: RefreshTokenResponse | string,
    setAuthenticated: Dispatch<SetStateAction<boolean>>,
  ): void => {
    if (typeof data === 'string') {
      setAuthenticated((prevState) => prevState || true)
    } else if (data.token) {
      cookies.set([
        { key: 'token', value: data.token },
        { key: 'refreshToken', value: data.refreshToken || '' },
      ])
      setAuthenticated((prevState) => prevState || true)
    } else setAuthenticated((prevState) => !prevState || false)
  },
  jwt: (token: string): JWTPayload => {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join(''),
    )
    return JSON.parse(jsonPayload)
  },
  currentRole: (roles: string[]): string => {
    const allowedRoles = Object.values(ROLES)
    const allowedRolesFound = []
    allowedRoles.forEach((ROLE, index) => {
      if (allowedRoles.includes(roles[index])) {
        allowedRolesFound.push(allowedRoles.indexOf(roles[index]))
      }
    })
    return allowedRolesFound.length ? allowedRoles[Math.min(...allowedRolesFound)] : roles[0]
  },
  hasExpired: (expirationDate): boolean =>
    parseInt(Date.now().toString().substring(0, 10), 0) >= expirationDate,
}

export default authenticate
