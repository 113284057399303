import React, { FC, useState } from 'react'

import { ProviderProps, CompanyContextProps, CompanyState } from 'types'
import FIELD from 'constants/fields'

export const CompanyContext = React.createContext<CompanyContextProps | undefined>(undefined)

CompanyContext.displayName = 'Company'

const initialState = {
  id: '',
  name: '',
  email: '',
  logo: '',
  icon: '',
  color: '#e6e6e6',
  head: '',
  welcomeNotification: true,
  sharedKey: '',
  cards: {
    content: {
      cards: [
        { id: 0, title: '', subtitle: '', type: FIELD.TEXT, location: 'back', isOverride: false },
        { id: 1, title: '', subtitle: '', type: FIELD.TEXT, location: 'header', isOverride: false },
        { id: 2, title: '', subtitle: '', type: FIELD.TEXT, location: 'front', isOverride: false },
        { id: 3, title: '', subtitle: '', type: FIELD.TEXT, location: 'front', isOverride: false },
      ],
      id: 'content',
    },
  },
}

const Company: FC<ProviderProps> = ({ children }: ProviderProps) => {
  const [company, setCompany] = useState<CompanyState>(initialState)

  return (
    <CompanyContext.Provider value={{ setCompany, company }}>{children}</CompanyContext.Provider>
  )
}

export default Company
