import React, { ReactElement, Dispatch, SetStateAction } from 'react'
import { useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Wrapper, Cross, Overlay } from 'components/Modals/styles'
import { ModalTitle, CopyIcon, EditIcon } from './styles'
import { Button, Row } from 'styles/globalStyle'
import { notification as notificationServce, wallet as walletService } from 'services'
import Devices from './Devices'
import { routes } from 'router'

type Props = {
  notificationPreviewId: string
  setNotificationPreviewId: Dispatch<SetStateAction<string>>
  parseDate: (date: string | Date) => string
}

const NotificationPreview = ({
  notificationPreviewId,
  setNotificationPreviewId,
  parseDate,
}: Props): ReactElement => {
  const { t } = useTranslation()

  const closeModal = () => setNotificationPreviewId('')

  const { data: notification } = useQuery(
    [`notificationPreview${notificationPreviewId}`],
    () =>
      notificationServce.once({
        id: notificationPreviewId,
      }),
    {
      staleTime: 10,
    },
  )

  const { data: wallets } = useQuery(['wallets'], () => walletService.collection(), {
    staleTime: 10,
  })

  const notificationIsNotSent = new Date(notification?.sentAt) > new Date()

  return (
    <Overlay onClick={closeModal}>
      <Wrapper onClick={(event) => event.stopPropagation()} width={1000}>
        <ModalTitle>
          {notification?.name}
          <br />
          {parseDate(notification?.sentAt)}
          &nbsp;-&nbsp;
          {parseDate(notification?.endAt)}
        </ModalTitle>
        <Cross onClick={closeModal} />
        <Devices company={wallets?.['hydra:member']?.[0]} notification={notification} />
        <Row margin="0 0 30px 0">
          {notificationIsNotSent ? (
            <Link
              to={{
                pathname: routes.editNotification.path,
                search: `?notificationId=${notificationPreviewId}`,
              }}
            >
              <Button.primary margin="0 15px 0 0">
                <EditIcon />
                {t('update')}
              </Button.primary>
            </Link>
          ) : (
            <Button.disabled>
              <EditIcon disabled />
              {t('update')}
            </Button.disabled>
          )}
          <Link
            to={{
              pathname: routes.duplicateNotification.path,
              search: `?duplicateNotificationId=${notificationPreviewId}`,
            }}
          >
            <Button.secondary margin="0 0 0 15px">
              <CopyIcon />
              {t('duplicate')}
            </Button.secondary>
          </Link>
        </Row>
      </Wrapper>
    </Overlay>
  )
}

export default NotificationPreview
