import React, { ReactElement, RefObject, useState } from 'react'

import { Wrapper, Container, Text, Icon } from './styles'

type DropdownProps = {
  options?: boolean
  display: boolean
  selector?: boolean
  reference: RefObject<HTMLDivElement>
  list: {
    text: string
    icon: ReactElement
    action: () => void
  }[]
}

const Dropdown = ({ list, display, reference, options, selector }: DropdownProps): ReactElement => {
  const [selectedItem, setSelectedItem] = useState<string>(list[0].text)
  const isSelected = (item: string): boolean => item === selectedItem

  const handleClick = (item: string, callback: () => void): void => {
    if (selector) setSelectedItem(item)
    return callback()
  }

  return (
    <Wrapper ref={reference} display={display ? 1 : 0} options={options ? 1 : 0}>
      {list.map(({ text, icon, action }) => (
        <Container key={text} onClick={() => handleClick(text, action)} selector={isSelected(text)}>
          <Icon>{icon}</Icon>
          <Text size={14}>{text}</Text>
        </Container>
      ))}
    </Wrapper>
  )
}

export default Dropdown
