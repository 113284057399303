import { useContext } from 'react'
import { useQuery } from 'react-query'
import { RefreshTokenResponse } from 'types/api'
import { authenticate, cookies } from 'services'
import { UserContext } from 'providers'

const checkAdminState = () => {
  const superAdminToken = cookies.get('superAdminToken')
  if (superAdminToken) {
    cookies.set([{ key: 'token', value: superAdminToken }])
  }
  const selectedCompany = cookies.get('selectedCompanyToken')
  if (selectedCompany) {
    cookies.set([{ key: 'token', value: selectedCompany }])
  }
  const token = cookies.get('token')
  if (!token) return { isValid: false }
  const payload = authenticate.jwt(token)
  if (authenticate.hasExpired(payload.exp)) return { isValid: false }
  else return { isValid: true, payload, token }
}

const useAuth = (shouldBeFocused = false): Record<string, unknown> => {
  const { isAuthenticated, setAuthenticated, setUser, user } = useContext(UserContext)

  const setUserAuth = ({ payload, token }) => {
    const selectedCompanyToken = cookies.get('selectedCompanyToken')

    setUser({
      ...user,
      email: payload.username,
      role: authenticate.currentRole(payload.roles),
      exp: payload.exp,
      token,
      userId: authenticate.jwt(token).company || '',
      subscription: selectedCompanyToken ? 'ROLE_COMPANY_ADMIN' : '',
      temp: token,
    })
    authenticate.feedBrowser(token, setAuthenticated)
  }

  const { isLoading, isSuccess, isError, error } = useQuery(
    'auth',
    () => {
      const { isValid, payload, token } = checkAdminState()
      if (!isValid) {
        setAuthenticated(false)
      } else {
        setUserAuth({ payload, token })
      }
    },
    {
      refetchOnWindowFocus: shouldBeFocused,
      onSuccess: (data: RefreshTokenResponse) => {
        if (!data || data.status.toString().charAt(0) === '4') {
          const { isValid, payload, token } = checkAdminState()
          if (isValid) {
            setUserAuth({ payload, token })
            return true
          } else {
            setAuthenticated(false)
          }
        } else if (data?.token) {
          const payload = authenticate.jwt(data.token)
          setUserAuth({ payload, token: data?.token })
          return true
        } else {
          setAuthenticated(false)
        }
      },
      onError: (err) => console.log('React Query Error =>', err),
    },
  )

  return { isLoading, isSuccess, isError, error, isAuthenticated }
}

export default useAuth
