import React, { ReactElement } from 'react'
import { Event, Title, Icon } from 'components/Export/styles'

type Props = {
  title: string
  onClick: () => void
}

const Export = ({ onClick, title }: Props): ReactElement => {
  return (
    <Event onClick={onClick}>
      <Icon />
      <Title>{title}</Title>
    </Event>
  )
}

export default Export
