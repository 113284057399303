import React, { ReactElement } from 'react'
import { AiOutlineLoading } from 'react-icons/ai'
import { Wrapper, Modal, Title, Subtitle, Actions, Button, Prompt } from 'components/Dialog/styles'
import { DialogType } from 'types'

interface Props extends DialogType {
  isLoading?: boolean
}

const Dialog = ({ title, content, action, type, mark, isLoading, input }: Props): ReactElement => {
  return (
    <Wrapper onClick={action?.dismiss.onClick}>
      <Modal onClick={(event) => event.stopPropagation()}>
        <Title>{title}</Title>
        <Subtitle>
          {content}
          <br />
          {mark}
          {input && <Prompt placeholder={input.placeholder} onChange={input.onChange} />}
        </Subtitle>
        {action && (
          <Actions>
            <Button onClick={() => action?.dismiss.onClick()}>{action.dismiss.text}</Button>
            <Button
              onClick={() => action?.active.onClick()}
              isActive
              color={type}
              isLoading={isLoading}
              disabled={isLoading}
            >
              {isLoading && <AiOutlineLoading />}
              {!isLoading && action.active.text}
            </Button>
          </Actions>
        )}
      </Modal>
    </Wrapper>
  )
}

export default Dialog
