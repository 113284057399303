import React, { useState, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useAsyncDebounce } from 'react-table'

import { Wrapper, Container, Icon, Field, Link, Arrow } from './styles'

// React-table v8 will have built-in types, for now it's any
type SearchFilterProps = {
  globalFilter: any
  setGlobalFilter: any
  placeholder: string
  link?: string
}

const SearchFilter = ({
  globalFilter,
  setGlobalFilter,
  placeholder,
  link,
}: SearchFilterProps): ReactElement => {
  const { t } = useTranslation()
  const [value, setValue] = useState(globalFilter)
  const onFetchDataDebounced = useAsyncDebounce((value) => {
    setGlobalFilter(value)
  }, 300)

  return (
    <Wrapper>
      <Container>
        <Icon />
        <Field
          placeholder={placeholder}
          value={value || ''}
          onChange={(event) => {
            setValue(event.target.value)
            onFetchDataDebounced(event.target.value)
          }}
        />
      </Container>
      {link && (
        <Link>
          {t('search.link')}
          <Arrow />
        </Link>
      )}
    </Wrapper>
  )
}

export default SearchFilter
