import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import { styled } from 'theme'
import { colors } from 'theme/colors'

type RowProps = {
  col: number
  isOdd?: boolean
  disabled?: boolean
}

export const CellButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 23px;
  height: 23px;
  border: none;
  border-radius: 20px;
  background: ${({ theme }) => theme.color.background};
  box-shadow: ${({ theme }) => theme.shadow};
  transition: 200ms;
  cursor: pointer;
  :not(:disabled) svg {
    color: ${({ theme }) => theme.color.primary};
  }
  :hover:not(:disabled) {
    transform: scale(1.2);
    & svg {
      transform: scale(1.2);
    }
  }
  :disabled {
    color: ${({ theme }) => theme.color.alternative};
    cursor: default;
  }
  :focus {
    outline: none;
  }
`
export const HeadRow = styled.tr`
  font-weight: ${({ theme }) => theme.font.bold};
  padding: 8px;
  line-height: 2.5;
  text-align: center;
  border-bottom: 1px solid ${({ theme }) => theme.color.alternative}20;
  :first-child {
    text-align: left;
  }
`
export const BodyRow = styled.tr`
  text-align: center;
  background: ${(row: RowProps) =>
    row.isOdd ? `${colors.greyish}90` : (props) => props.theme.color.background};
  filter: ${(row: RowProps) => row.disabled && `opacity(0.3)`};
`
export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  margin-top: 20px;
`
export const Cell = styled.td`
  color: ${colors.marine};
  padding: 4px 20px;
  max-height: 45px;
  min-height: 45px;
  max-width: 200px;
  height: 45px;
  text-align: center;
  font-size: 14px;
  border: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  align-items: center;
  :first-child {
    border: none;
    text-align: left;
    display: flex;
    justify-content: flex-start;
    max-width: 200px;
  }
  & button {
    margin: 0 auto;
  }
`
export const NoDataRow = styled.tr`
  background: ${colors.greyish}90;
  color: ${colors.marine};
  padding: 4px 0;
  border: none;
  & td {
    text-align: center;
  }
`
export const Pagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Prev = styled(FiChevronLeft)`
  cursor: pointer;
  transition: 200ms;
  font-size: 16px;
  color: ${(props) => props.theme.color.alternative};
  padding: 10px;
`

export const Next = styled(FiChevronRight)`
  cursor: pointer;
  transition: 200ms;
  font-size: 16px;
  color: ${(props) => props.theme.color.alternative};
  padding: 10px;
`
