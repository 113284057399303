import React, { Dispatch, ReactElement, SetStateAction, useState } from 'react'
import { useRouteMatch, Switch, Route } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Wrapper } from './styles'
import Layout from 'layouts'
import Content from 'layouts/CreateEditCard/Content'
import { CardPreview } from 'components'
import { Company } from 'providers'
import { routes } from 'router'

export type Step = { id: number; title: string; disabled: boolean; active: boolean }

type SwitchStepProps = {
  setSteps: Dispatch<SetStateAction<{ customer: Step[]; company: Step[] }>>
  steps: { customer: Step[]; company: Step[] }
}

export type StepsState = {
  company: Step[]
  customer: Step[]
}

const CreateEditCard = (): ReactElement => {
  const { t } = useTranslation()
  const { path } = useRouteMatch()
  const [currentStep, setCurrentStep] = useState<number>(0)
  const [showVerso, setShowVerso] = useState(false)
  const [steps, setSteps] = useState<StepsState>({
    company: [
      { id: 0, title: t('edit.steps.0'), disabled: false, active: true },
      { id: 1, title: t('edit.steps.1'), disabled: false, active: false },
      { id: 2, title: t('edit.steps.2'), disabled: false, active: false },
    ],
    customer: [
      { id: 0, title: t('edit.steps.0'), disabled: false, active: true },
      { id: 1, title: t('edit.steps.1'), disabled: false, active: false },
      { id: 2, title: t('edit.steps.2'), disabled: false, active: false },
    ],
  })

  return (
    <Layout>
      <Company>
        <Wrapper>
          <Switch>
            <Route exact path={`${path}/new`}>
              <Content
                edit={false}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                steps={steps}
                setSteps={setSteps}
                setShowVerso={setShowVerso}
              />
            </Route>
            <Route path={`${path}/:id`}>
              <Content
                edit
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                steps={steps}
                setSteps={setSteps}
                setShowVerso={setShowVerso}
              />
            </Route>
            <Route exact path={path}>
              <Content
                edit
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                steps={steps}
                setSteps={setSteps}
                setShowVerso={setShowVerso}
              />
            </Route>
          </Switch>
          <CardPreview showVerso={showVerso} setShowVerso={setShowVerso} />
        </Wrapper>
      </Company>
    </Layout>
  )
}

const move = (data, direction, setSteps, selectedSteps, setCurrentStep) => {
  data[direction].active = true
  setSteps((prevSteps) => ({ ...prevSteps, [selectedSteps]: data }))
  setCurrentStep(direction)
}

export const switchStep = (
  direction: number,
  { currentStep, setCurrentStep },
  { steps, setSteps }: SwitchStepProps,
  selectedSteps: string,
  push: (location: string) => void,
): void => {
  const data = [...steps[selectedSteps]]
  if (direction >= 0) move(data, direction, setSteps, selectedSteps, setCurrentStep)
  else push(routes.company.path)
}

export default CreateEditCard
