import { FaGoogle, FaApple, FaRocket } from 'react-icons/fa'

import { styled, css } from 'theme'
import { colors } from 'theme/colors'
import { Text } from 'components/Typography'

export const Wrapper = styled.div`
  width: 460px;
  height: 198px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  box-shadow: ${(props) => props.theme.shadow};
  background: ${(card: { color: string }) => card.color || ((props) => props.theme.color.shaded)};
`

export const Left = styled.div`
  width: calc(50% - 26px);
  margin-left: 26px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`

export const Right = styled.div`
  width: calc(50% - 26px);
  margin-right: 26px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
`

export const Link = styled.a`
  color: ${colors.primaryWhite};
  text-transform: uppercase;
  font-weight: bold;
  font-size: 15px;
  margin: 20px 0;
`

export const Info = styled(Text)`
  opacity: 0.68;
`

export const Value = styled(Text)`
  margin-bottom: 20px;
`

export const DefaultIcon = css`
  width: 120px;
  height: 120px;
  margin-bottom: 20px;
  opacity: 0.3;
  color: ${colors.primaryWhite};
  object-fit: contain;
`

const Android = styled(FaGoogle)`
  ${DefaultIcon}
`

const Ios = styled(FaApple)`
  ${DefaultIcon}
`

const Society = styled(FaRocket)`
  ${DefaultIcon}
`

export const Icon = {
  Android,
  Ios,
  Society,
}
