import { device, styled } from 'theme'

export const Top = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  margin-right: 74px;
  ${device.md} {
    flex-direction: column;
    align-items: flex-start;
    margin-left: -37px;
  }
`
