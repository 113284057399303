import { styled } from 'theme'

export const Label = styled.label`
  position: relative;
  display: inline-block;
  width: 30px;
  height: 14px;
`

export const Input = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
  :checked + span {
    background-color: ${({ theme }) => theme.color.primary};
    :before {
      transform: translateX(16px);
    }
  }
  :focus + span {
    box-shadow: 0 0 1px ${({ theme }) => theme.color.background};
  }
`

export const Dot = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.color.alternative}50;
  border-radius: 34px;
  transition: 0.4s;
  :before {
    position: absolute;
    content: '';
    height: 10px;
    width: 10px;
    left: 2px;
    bottom: 2px;
    background-color: ${({ theme }) => theme.color.background};
    transition: 0.4s;
    border-radius: 50%;
  }
`
