import React, { ReactElement, useContext } from 'react'

import GlobalStyle, { ThemeProvider } from 'theme'
import { ThemeContext, Notification } from 'providers'
import { initializeLanguage } from 'lang'
import Router from 'router'

const Main = (): ReactElement => {
  const { currentTheme } = useContext(ThemeContext)

  initializeLanguage().then(() => true)

  return (
    <ThemeProvider theme={currentTheme}>
      <GlobalStyle />
      <Notification>
        <Router />
      </Notification>
    </ThemeProvider>
  )
}

export default Main
