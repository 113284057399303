import React, { SetStateAction, Dispatch } from 'react'
import {
  company as companyService,
  login as loginService,
  wallet as walletService,
  login,
} from 'services'
import { EMAIL_REGEX } from 'constants/regex'
import { CompanyState, Notify } from 'types'
import { routes } from 'router'

type Props = {
  company: CompanyState
  setCompany: Dispatch<SetStateAction<CompanyState>>
  setRedirect: Dispatch<SetStateAction<string>>
  setIsLoad: Dispatch<SetStateAction<boolean>>
  t: (path: string) => string
  notify: ({ title, message, type }: Notify) => void
}

function isValid(data, validation, t, notify, setIsLoad): boolean {
  const errors = []
  validation.forEach((type) => {
    switch (type) {
      case 'name':
        if (data.name.length < 1) errors.push(t('edit.form.validation.name'))
        break
      case 'administrator':
        if (data.administrator.length < 6 || !data.administrator.toLowerCase().match(EMAIL_REGEX))
          errors.push(t('edit.form.validation.email'))
        break
      case 'fields':
        // CHECK IF A FIELD HAVE AN EMPTY VALUE
        if (
          data.fields.some(
            (field) =>
              field.location === 'back' && Object.keys(field).find((fieldKey) => !field[fieldKey]),
          )
        )
          errors.push(t(`edit.form.validation.${type}`))
        break
      case 'illustration':
        if (!data.head) errors.push(t(`edit.form.validation.illustration`))
        break
      default:
        if (!data[type]) errors.push(t(`edit.form.validation.${type}`))
    }
  })
  if (errors.length >= 1) {
    setIsLoad(false)
    notify({ title: t('edit.form.error.company'), message: errors.join(' '), type: 'error' })
    return false
  }
  return true
}

function getFormData(object) {
  const formData = new FormData()
  Object.keys(object).forEach((key) => {
    if (key === 'fields') {
      object.fields.forEach((field, index) =>
        Object.keys(field).forEach((fieldKey) =>
          formData.append(`fields[${index}][${fieldKey}]`, field[fieldKey]),
        ),
      )
    } else if (key !== 'id') {
      formData.append(key, object[key])
    }
  })
  return formData
}

const sendEmailAdminCreated = (email, t) =>
  login.reset({
    replyTo: process.env.REACT_APP_EMAIL_REPLY,
    fromName: process.env.REACT_APP_EMAIL_NAME,
    fromEmail: process.env.REACT_APP_EMAIL_FROM,
    email,
    subject: t(`login.request.subject.new`),
    templateUrl: `${process.env.REACT_APP_URL}/email_template/creation_compte.html`,
    redirectUrl: `${process.env.REACT_APP_URL}${routes.login.path}#new`,
  })

export async function createCompany({
  company,
  setCompany,
  setRedirect,
  t,
  notify,
  setIsLoad,
}: Props): Promise<void> {
  const fields = []
  company.cards.content.cards.forEach((item) => {
    if (item.location === 'back') {
      fields.push({
        label: item.title,
        value: item.subtitle,
        type: item.type,
        location: item.location || 'back',
      })
    }
  })

  const updatedCompany = {
    name: company.name,
    administrator: company.email,
    color: company.color,
    logo: company.logo,
    icon: company.icon,
    head: company.head,
    fields,
  }
  if (
    isValid(
      updatedCompany,
      ['name', 'administrator', 'icon', 'logo', 'head', 'color', 'fields'],
      t,
      notify,
      setIsLoad,
    )
  ) {
    let companyId: string = company.id
    if (!companyId) {
      const resCreateCompany = await companyService.create({
        name: company.name,
        administrator: company.email,
      })
      if (resCreateCompany.status === 409) {
        // CHECK IF COMPANY EMAIL ALREADY EXIST
        notify({ title: t('edit.form.error.already_exist'), message: '', type: 'error' })
        setIsLoad(false)
      } else {
        companyId = resCreateCompany.id
        setCompany({ ...company, id: resCreateCompany.id })
      }
    }
    if (companyId) {
      loginService.switch({ company: companyId }).then((res) => {
        const newCompanyToken = res.token
        companyService.settings
          .update({
            id: companyId,
            body: { welcomeNotification: company.welcomeNotification },
            customToken: newCompanyToken,
          })
          .then(() => {
            const body = getFormData({ ...updatedCompany, fields, id: companyId })
            walletService.create(body, newCompanyToken).then((res) => {
              setIsLoad(false)
              if (res.status === 201) {
                notify({
                  title: t('edit.form.success'),
                  message: '',
                  type: 'success',
                })
                sendEmailAdminCreated(updatedCompany.administrator, t)
                setTimeout(() => setRedirect('/companies'), 1500)
              } else
                notify({
                  title: t('edit.form.error.errorOccured.title'),
                  message: t('edit.form.error.errorOccured.message'),
                  type: 'error',
                })
            })
          })
      })
    }
  }
}
