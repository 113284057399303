import { LoginServices } from 'types'
import API, { LOGIN, headers } from 'api'
import { errors, api } from 'services'

const response = async (promise, key: string = null) => {
  const res = await promise.json()
  if (key) {
    return {
      ...res,
      status: promise.status,
      response: errors.notify(key[promise.status], promise.status),
    }
  }
  return { ...res, status: promise.status, response: null }
}

const login: LoginServices = {
  user: async (body) => {
    const promise = await fetch(`${API(LOGIN.DEFAULT)}`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: headers('default', false),
    })
    return await response(promise, api.login.user)
  },

  switch: async (body) => {
    const promise = await fetch(`${API(LOGIN.SWITCH)}`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: headers(),
    })
    return await response(promise, api.login.switch)
  },

  refresh: async (body) => {
    const promise = await fetch(`${API(LOGIN.REFRESH)}`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: headers('default', false),
    })
    return await response(promise, api.login.refresh)
  },

  reset: async (body) => {
    const promise = await fetch(`${API(LOGIN.RESET)}`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: headers('default', false),
    })
    return await response(promise, api.login.reset)
  },

  save: async (body) => {
    const promise = await fetch(`${API(LOGIN.SAVE)}`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: headers('default', false),
    })
    return await response(promise, api.login.save)
  },
}

export default login
