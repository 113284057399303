import { styled } from 'theme'
import { colors, globalColors } from 'theme/colors'

import logoWhite from 'assets/logo-white.svg'
import logoBlack from 'assets/logo-black.svg'
import logoSmall from 'assets/logo-small.svg'
import logoGooglePlay from 'assets/google-play.svg'
import logoGooglePayButton from 'assets/google-pay-button.svg'
import notFoundIllustration from 'assets/Error404.svg'

export const DesktopLayout = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: ${colors.marine};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const MobileLayout = styled(DesktopLayout)`
  background-color: white;
  flex-direction: column;
  justify-content: flex-start;
  padding: 30px 0 50px 0;
`

export const LogoWhite = styled.img.attrs({
  src: logoWhite,
})`
  width: 23vw;
  height: auto;
  position: absolute;
  top: 30px;
`

export const LogoSmall = styled.img.attrs({
  src: logoSmall,
})`
  height: 35vh;
  width: auto;
  position: absolute;
  left: -9vh;
`

export const LogoGooglePlay = styled.img.attrs({
  src: logoGooglePlay,
})`
  width: 150px;
  height: auto;
`

export const CardTitle = styled.p`
  font-size: 30px;
  color: ${colors.mikadoYellow};
  width: 100%;
  text-align: start;
  border-bottom: 3px solid ${colors.mikadoYellow};
  font-weight: bold;
  margin-bottom: 20px;
`

export const QrCode = styled.img`
  width: 270px;
  height: 270px;
`

export const WalletCard = styled.div`
  border-radius: 8px;
  box-shadow: 1px 1px 4px #c7c7c7;
  padding: 20px;
  width: 95%;
  max-width: 450px;
  margin-bottom: 40px;
`

export const GooglePayButton = styled.img.attrs({ src: logoGooglePayButton })`
  width: 100%;
  height: auto;
  max-width: 450px;
`
export const DisableNotifications = styled.a`
  font-size: 13px;
  color: ${globalColors.default};
  text-decoration: underline;
  cursor: pointer;
  position: absolute;
  bottom: 30px;
`

export const Card = {
  Icon: styled.img`
    width: 70px;
    height: auto;
    max-height: 50px;
    border-radius: 6px;
  `,
  Illustration: styled.img`
    max-width: 100%;
    height: auto;
    max-height: 150px;
    margin-top: 35px;
  `,
}

export const NotFoundIllustration = styled.img.attrs({
  src: notFoundIllustration,
})`
  width: 80vw;
  max-width: 400px;
  margin-top: 70px;
`

export const NotFoundSkoopLogo = styled.img.attrs({
  src: logoBlack,
})`
  width: 80vw;
  max-width: 400px;
  margin-bottom: 50px;
`
export const StepText = styled.p`
  color: ${colors.mikadoYellow};
  font-weight: 600;
  font-size: 22px;
  margin: ${({ margin }: { margin: string }) => margin || ''};
`
