import { FiCheckCircle, FiCircle, MdOutlineClose } from 'react-icons/all'
import { Text } from 'components/Typography'
import { keyframes, styled, css } from 'theme'
import { colors, globalColors } from 'theme/colors'
import Illustration404 from 'assets/Error404.svg'

type ButtonProps = {
  disabled?: boolean
}

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

export const Overlay = styled.div`
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${colors.marine}90;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${fadeIn} 1s ease;
`

export const Image404 = styled.img.attrs({
  src: Illustration404,
})`
  width: 65%;
  margin-bottom: 30px;
`

export const Wrapper = styled.div`
  position: relative;
  width: ${({ width }: { width?: number | string }) =>
    typeof width === 'number' ? `${width}px` : width || '700px'};
  background: ${({ theme }) => theme.color.background};
  border-radius: 13px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
`

export const Cross = styled(MdOutlineClose)`
  position: absolute;
  font-size: 30px;
  top: 13px;
  right: 13px;
  cursor: pointer;
`

export const Container = styled.div`
  width: 440px;
  margin-bottom: 120px;
`

export const Logo = styled.img`
  width: 120px;
  height: 50px;
  object-fit: contain;
  margin-bottom: 20px;
`

export const Title = styled(Text)`
  font-size: 22px;
  color: ${({ color = 'default' }: { color?: string }) => globalColors[color]};
  font-weight: 600;
  margin: 0;
  text-align: ${({ align }: { align?: string }) => align || 'start'};
`

export const Guard = styled(Text)`
  font-size: 12px;
  margin: 0;
  line-height: 20px;
  margin-bottom: 15px;
`

export const Info = styled(Text)`
  font-size: 12px;
  font-weight: 600;
  margin: 0;
  line-height: 20px;
`

export const Rules = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`
export const Gulp = styled(Text)`
  display: flex;
  align-items: center;
  font-size: 12px;
  margin: 0;
  line-height: 22px;
`
export const Checked = styled(FiCheckCircle)`
  margin-right: 5px;
  color: ${colors.success};
`

export const Dot = styled(FiCircle)`
  margin-right: 5px;
  color: ${colors.error};
`

export const Illustration = styled.img`
  position: absolute;
  height: 150px;
  bottom: 0;
  left: 50px;
`

const DisabledButton = css`
  background: ${({ theme }) => theme.color.alternative}50;
  cursor: not-allowed;
`

export const Validate = styled.button`
  margin-top: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.color.primary};
  border: none;
  border-radius: 40px;
  width: 120px;
  height: 28px;
  transition: 200ms;
  float: right;
  font-size: 14px;
  color: ${({ theme }) => theme.color.background};
  text-align: center;
  ${({ disabled }: ButtonProps) => disabled && DisabledButton}
`

export const Loading = styled.div`
  border-radius: 50%;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid transparent;
  border-top: 1px solid ${colors.primaryWhite};
  width: 12px;
  height: 12px;
  -webkit-animation: ${spin} 700ms linear infinite;
  animation: ${spin} 700ms linear infinite;
  margin-left: 10px;
`
