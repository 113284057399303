import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'

import { admin } from 'services'

type Add = { email: string; password: string }
type Remove = { id: string }
type Edit = { email: string; id: string }

type Queries = {
  add: (params) => Promise<unknown>
  edit: (params) => Promise<unknown>
  remove: (params) => Promise<unknown>
}

const useAdminQuery = (): Queries => {
  const [addParams, setAddParams] = useState<Add>({ email: '', password: '' })
  const [editParams, setEditParams] = useState<Edit>({ id: '', email: '' })
  const [removeParams, setRemoveParams] = useState<Remove>({ id: '' })

  const initialState = { addParams, editParams, removeParams }

  const removeQuery = useQuery(['admin', 'removed'], () => admin.remove({ id: removeParams.id }), {
    onSuccess: () => setRemoveParams(initialState.removeParams),
    enabled: false,
  })

  const editQuery = useQuery(
    ['admin', 'updated'],
    () => admin.update({ id: editParams.id }, { email: editParams.email }),
    {
      onSuccess: () => setEditParams(initialState.editParams),
      enabled: false,
    },
  )

  const addQuery = useQuery(['admin', 'created'], () => admin.create({ email: addParams.email }), {
    onSuccess: () => setAddParams(initialState.addParams),
    enabled: false,
  })
  useEffect(() => {
    if (addParams !== initialState.addParams) addQuery.refetch().then()
    if (editParams !== initialState.editParams) editQuery.refetch().then()
    if (removeParams !== initialState.removeParams) removeQuery.refetch().then()
  }, [addParams, removeParams, editParams])

  return {
    add: async ({ email, password }: Add) => setAddParams({ email, password }),
    remove: async ({ id }: Remove) => setRemoveParams({ id }),
    edit: async ({ email, id }: Edit) => setEditParams({ email, id }),
  }
}

export default useAdminQuery
