import React from 'react'
import Skeleton from '@mui/material/Skeleton'
import { Row } from './styles'

const skeletons = [
  // à déplacer hors du composant
  { id: 1, height: 40, width: '80%' },
  { id: 2, height: 40, width: '80%' },
  { id: 3, height: 35, width: '90%' },
  { id: 3, height: 35, width: '90%' },
]

export const TableSkeleton = (): React.ReactElement => {
  return (
    <Row>
      {skeletons.map((item) => (
        <Skeleton
          key={item.id}
          animation="wave"
          height={item.height}
          width={item.width}
          sx={{
            mt: '10px',
          }}
        />
      ))}
    </Row>
  )
}
