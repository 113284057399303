import React, { ReactElement } from 'react'
import { CgSortAz, CgSortZa } from 'react-icons/all'
import { TableInstance } from 'react-table'

import { Cell, HeadRow, BodyRow, Table, NoDataRow } from 'components/ReactTable/styles'
import { TFunction, useTranslation } from 'react-i18next'
import { Pagination, Prev, Next } from 'components/Table/styles'
import { Text } from 'components/Typography'

const noDataTable = (t: TFunction<'translation'>, colspan: number) => (
  <NoDataRow>
    <td colSpan={colspan}>{t('admin.noData')}</td>
  </NoDataRow>
)

type Props = {
  tableInstance: TableInstance
}

const ReactTable = ({ tableInstance }: Props): ReactElement => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    columns,
    previousPage,
    nextPage,
    pageOptions,
    state: { pageIndex },
  } = tableInstance
  const { t } = useTranslation()

  return (
    <>
      <Table {...getTableProps()}>
        <thead>
          {
            // Loop over the header rows
            // Until react-table v8 type is any
            headerGroups.map((headerGroup: any) => (
              // Apply the header row props
              <HeadRow key={headerGroup.getHeaderGroupProps().key}>
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map((column) =>
                    column.sortable ? (
                      <Cell
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                        key={column.id}
                      >
                        {column.render('Header')}
                        {column.isSorted && !column.isSortedDesc ? (
                          <CgSortZa size={16} />
                        ) : (
                          <CgSortAz size={16} />
                        )}
                      </Cell>
                    ) : (
                      <Cell {...column.getHeaderProps()} key={column.id}>
                        {column.render('Header')}
                      </Cell>
                    ),
                  )
                }
              </HeadRow>
            ))
          }
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.length === 0 && noDataTable(t, columns.length)}
          {
            // Loop over the table rows
            // Until react-table v8 type is any
            rows.map((row: any, index: number) => {
              // Prepare the row for display
              prepareRow(row)
              return (
                // Apply the row props
                <BodyRow
                  {...row.getRowProps()}
                  isOdd={index % 2 === 0}
                  disabled={row.original.isDeleted}
                  col={row.cells.length}
                  key={row.id}
                >
                  {
                    // Loop over the rows cells
                    row.cells.map((cell) => {
                      // Apply the cell props
                      return (
                        <Cell {...cell.getCellProps()} key={`${cell.column.id}-${cell.row.id}`}>
                          {
                            // Render the cell contents
                            cell.render('Cell')
                          }
                        </Cell>
                      )
                    })
                  }
                </BodyRow>
              )
            })
          }
        </tbody>
      </Table>
      {pageOptions && (
        <Pagination>
          {pageIndex !== 0 && <Prev onClick={previousPage} />}
          <Text accent>
            {t('table.pagination', { current: pageIndex + 1, total: pageOptions.length })}
          </Text>
          {pageOptions.length > pageIndex + 1 && <Next onClick={nextPage} />}
        </Pagination>
      )}
    </>
  )
}

export default ReactTable
