import React, { ReactElement, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { Top } from 'layouts/Notification/styles'
import { Counter, Export, NotificationTable, SearchFilter } from 'components'
import Layout from 'layouts'
import { notification as notificationService } from 'services'
import { NotificationContext } from 'providers'

const Notification = (): ReactElement => {
  const { t } = useTranslation()
  const { notify } = useContext(NotificationContext)
  const [notificationCount, setNotificationCount] = useState(0)
  const [searchingWord, setSearchingWord] = useState<string>('')

  const downloadCsv = () => {
    notificationService.export().then((res) => {
      notify({
        title: res.status === 404 ? res.message : t(`notification.exportCsv.${res.status}.title`),
        message: res.status !== 404 && t(`notification.exportCsv.${res.status}.message`),
        type: res.status === 202 ? 'success' : 'warning',
      })
    })
  }

  return (
    <Layout>
      <Top>
        <Counter type="notification" isDefault count={notificationCount} create />

        <SearchFilter
          placeholder={t('notification.searchPlaceholder')}
          globalFilter={searchingWord}
          setGlobalFilter={setSearchingWord}
        />
      </Top>
      <Export title={t('notification.export')} onClick={downloadCsv} />
      <NotificationTable
        searchingWord={searchingWord}
        setNotificationCount={setNotificationCount}
      />
    </Layout>
  )
}

export default Notification
