import { TabsType } from 'types'

const TABS: TabsType = {
  ADMIN: 'admin',
  CUSTOMER: 'customer',
  COMPANY: 'company',
  NOTIFICATION: 'notification',
}

export default TABS
