import { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useQuery, UseQueryResult } from 'react-query'

import { NotificationContext, UserContext, StatisticsContext } from 'providers'
import { authenticate as authService } from 'services'
import { login, cookies } from 'services'
import { LoginResponse } from 'types/api'
import routes from 'router/routes'

type ReturnType = {
  auth: UseQueryResult<LoginResponse, unknown>
  refetch: () => void
  loading: boolean
  logout: () => void
}

// Add tokens to browser cookies
const feedBrowser = ({ token, refreshToken }: LoginResponse, callback: () => void): void => {
  cookies.set([
    { key: 'expires', value: '29' },
    { key: 'token', value: token },
    { key: 'refreshToken', value: refreshToken || '' },
  ])
  callback()
}

const useLogin = (credentials?: { email: string; pwd: string }): ReturnType => {
  const { isAuthenticated, setAuthenticated, setUser, user, resetUser } = useContext(UserContext)
  const [loading, setLoading] = useState<boolean>(true)
  const { notify } = useContext(NotificationContext)
  const { clearStatsContext } = useContext(StatisticsContext)
  const { push } = useHistory()
  const { pathname } = useLocation()

  // Authenticate the user with token
  const authenticate = (data: LoginResponse) => {
    if (data.token)
      feedBrowser(data, () => {
        const payload = authService.jwt(data.token)
        const role = authService.currentRole(payload.roles)
        if (role === 'ROLE_SUPER_ADMIN') {
          cookies.set([{ key: 'superAdminToken', value: data.token }])
        }

        setUser({
          ...user,
          token: data.token,
          email: payload.username,
          userId: payload.company,
          role,
          exp: payload.exp,
        })
        notify(data.response)
        setAuthenticated((prevState) => !prevState && true)
      })
  }

  // Get a new token if the current one expires
  const { refetch, status } = useQuery(
    'refresh',
    () =>
      cookies.get('refreshToken') && login.refresh({ refreshToken: cookies.get('refreshToken') }),
    { onSuccess: (data: LoginResponse) => authenticate(data), enabled: false },
  )

  // Login with credentials given by login form
  const auth = useQuery(
    'credentials',
    () => login.user({ username: credentials.email, password: credentials.pwd }),
    { onSuccess: (data: LoginResponse) => authenticate(data), enabled: false },
  )

  // Logout by clearing cookies and context
  const logout = () => {
    clearStatsContext()
    cookies.clear('expires')
    cookies.clear('token')
    cookies.clear('refreshToken')
    cookies.clear('superAdminToken')
    cookies.clear('selectedCompanyToken')
    setAuthenticated(false)
    resetUser()
  }

  useEffect(() => {
    if (auth.status === 'loading' || status === 'loading') setLoading(true)
    else setLoading(false)
  }, [status, auth.status])

  // On Auth state effect
  useEffect(() => {
    if (isAuthenticated && pathname === routes.login.path) push(routes.dashboard.path)
    if (!isAuthenticated && location?.pathname !== routes.login.path) push(routes.login.path)
  }, [isAuthenticated])

  return { auth, refetch, loading, logout }
}

export default useLogin
