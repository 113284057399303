import React, { Dispatch, ReactElement, SetStateAction, useContext, useState } from 'react'
import { useHistory, Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
  Container,
  NextStep,
  PrevStep,
  Next,
  Prev,
  Content,
  RowCenter,
  Button,
  Submit,
  ValidCheck,
} from './styles'
import { Title, FormCreateEditCard, Loader } from 'components'
import { StepsState, switchStep } from 'layouts/CreateEditCard'
import TABS from 'constants/tabs'
import { createCompany } from 'components/FormCreateEditCard/functions/createCompanyFunction'
import { updateWallet } from 'components/FormCreateEditCard/functions/updateWalletFunction'
import { CompanyState } from 'types'

import { CompanyContext, NotificationContext } from 'providers'

type Props = {
  steps: StepsState
  setSteps: Dispatch<SetStateAction<StepsState>>
  currentStep: number
  setCurrentStep: Dispatch<SetStateAction<number>>
  edit: boolean
  setShowVerso: Dispatch<SetStateAction<boolean>>
}

const ContentCreateEditCard = ({
  steps,
  setSteps,
  currentStep,
  setCurrentStep,
  edit,
  setShowVerso,
}: Props): ReactElement => {
  const { t } = useTranslation()
  const { push, location } = useHistory()
  const { setCompany, company } = useContext(CompanyContext)
  const [redirect, setRedirect] = useState<string>('')
  const [isLoad, setIsLoad] = useState<boolean>(false)
  const [defaultCompanyStock, setDefaultCompanyStock] = useState<CompanyState>()
  const [customerId, setCustomerId] = useState('')
  const [companyWalletId, setCompanyWalletId] = useState('')
  const [companyToken, setCompanyToken] = useState('')
  const [customerSharedKey, setCustomerSharedKey] = useState<string>('')

  const selectedSteps: string = location.pathname.includes(TABS.CUSTOMER)
    ? TABS.CUSTOMER
    : TABS.COMPANY

  const { notify } = useContext(NotificationContext)

  const getCompanyId = (): string => {
    const splitUrl = location.pathname.split('/')
    return splitUrl[splitUrl.length - 1]
  }

  function submit() {
    setIsLoad(true)
    if (edit) {
      const companyId: string = getCompanyId()
      updateWallet({
        setIsLoad,
        company,
        companyId,
        companyToken,
        companyWalletId,
        defaultCompanyStock,
        walletId: company.id,
        setRedirect,
        t,
        notify,
        customerId,
        customerSharedKey,
      })
    } else {
      createCompany({ company, setCompany, setRedirect, t, notify, setIsLoad })
    }
  }

  function changeStep(stepIndex: number) {
    switchStep(stepIndex, { currentStep, setCurrentStep }, { steps, setSteps }, selectedSteps, push)
    setShowVerso(stepIndex === 2)
  }

  const getTitle = () => {
    if (selectedSteps === 'customer' && edit) {
      return t('customers.editTitle')
    } else if (selectedSteps === 'customer') {
      return t('customers.createTitle')
    } else if (edit) {
      return t('edit.edit')
    } else {
      return t('edit.create')
    }
  }

  return (
    <>
      {redirect && <Redirect to={redirect} />}
      <Loader active={isLoad} />
      <Container>
        <Content>
          <Title margin={20} size={28}>
            {getTitle()}
          </Title>
          <FormCreateEditCard
            setCompanyToken={setCompanyToken}
            setCustomerId={setCustomerId}
            setCompanyWalletId={setCompanyWalletId}
            edit={edit}
            currentStep={currentStep}
            changeStep={changeStep}
            steps={steps[selectedSteps]}
            setDefaultCompanyStock={setDefaultCompanyStock}
            setCustomerSharedKey={setCustomerSharedKey}
          />
        </Content>
        <RowCenter>
          <PrevStep onClick={() => changeStep(currentStep - 1)} color={currentStep === 0 && 'grey'}>
            <Prev color={currentStep === 0 && 'grey'} />
            {t('edit.prev')}
          </PrevStep>
          {currentStep === 2 ? (
            <Submit>
              {submit && (
                <Button onClick={() => submit()}>
                  {t('edit.content.validate')}
                  <ValidCheck />
                </Button>
              )}
            </Submit>
          ) : (
            <NextStep onClick={() => currentStep !== 2 && changeStep(currentStep + 1)}>
              {t('edit.next')}
              <Next />
            </NextStep>
          )}
        </RowCenter>
      </Container>
    </>
  )
}

export default ContentCreateEditCard
