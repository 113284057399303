import React, { ReactElement, useContext, useState } from 'react'

import { Wrapper, User, Avatar, Lang, Rank, Info, Role, Key } from './styles'
import { Text } from 'components'
import { changeLanguage } from 'lang'
import { cookies } from 'services'
import { UserContext, MyInformationsContext } from 'providers'
import { ROLES } from 'constants/roles'

const Header = (): ReactElement => {
  const [accent, setAccent] = useState<string | undefined>(cookies.get('lang'))
  const { user } = useContext(UserContext)
  const { myInformations } = useContext(MyInformationsContext)

  const setLanguage = async (lang: 'fr' | 'en'): Promise<void> =>
    await changeLanguage(lang).then(() => setAccent(lang))
  const prettifyUsername = (username: string): string => {
    let name = username
    const emailRegex = /\S+@\S+\.\S+/
    if (emailRegex.test(name)) name = user.email?.substring(0, user.email?.lastIndexOf('@'))
    return name?.replace('.', ' ')
  }

  return (
    <Wrapper>
      <Lang>
        <Text onClick={() => setLanguage('fr')} accent={accent !== 'en'} weight="bold">
          FR
        </Text>
        <Text onClick={() => setLanguage('en')} accent={accent === 'en'} weight="bold">
          EN
        </Text>
      </Lang>
      <User>
        {user.role === ROLES.SUPER_ADMIN && (
          <Rank>
            <Key />
          </Rank>
        )}
        <Avatar email={user.email} default="mp" protocol="https://" />
        <Info>
          <Text transform="capitalize" weight="bold" accent>
            {prettifyUsername(user.email)}
          </Text>
          <Role>{myInformations.company?.name}</Role>
          <Role>{user.role.substring(5).split('_').join(' ').toLowerCase()}</Role>
        </Info>
      </User>
    </Wrapper>
  )
}

export default Header
