import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { Text } from 'components'
import { W, LittleWalletStyle, UploadIcon } from './styles'
import { CompanyState, FieldType } from 'types'
import { parseApiPicture } from 'utils'
import FIELD_LOCATIONS from 'constants/fieldLocations'

type CardHeader = {
  id?: number
  title?: string
  subtitle?: string
  type?: FieldType
  location?: string
  isOverride?: boolean
  label?: string
  value?: string
}

type LittleFrontPreviewProps = {
  company: CompanyState
  os: 'ios' | 'android'
  fontColor: (color: string | null) => string
  cardHeader: CardHeader
  isLink: (type: string) => boolean
  logoRef: React.MutableRefObject<any>
  illustrationRef: React.MutableRefObject<any>
}

const LittleFrontPreview = ({
  company,
  os,
  fontColor,
  cardHeader,
  isLink,
  logoRef,
  illustrationRef,
}: LittleFrontPreviewProps): ReactElement => {
  const { t } = useTranslation()

  return (
    <LittleWalletStyle.wrapper color={company.color}>
      <W.top>
        <W.logo
          src={parseApiPicture({ img: company.logo, defaultImg: UploadIcon })}
          ref={logoRef}
          hide={!company.logo}
        />
        <LittleWalletStyle.user>
          <Text
            wrap
            color={fontColor(company.color)}
            transform="uppercase"
            align="right"
            size={8}
            margin={0}
          >
            {cardHeader.title || t('edit.content.card.header.text')}
          </Text>
          <Text
            margin={0}
            wrap
            color={fontColor(company.color)}
            size={9}
            weight="bold"
            align="right"
          >
            {cardHeader.subtitle || t('edit.content.card.header.text')}
          </Text>
        </LittleWalletStyle.user>
      </W.top>
      <LittleWalletStyle.illustration
        src={parseApiPicture({ img: company.head, defaultImg: UploadIcon })}
        ref={illustrationRef}
        hide={!company.head}
      />
      <W.row type={os}>
        {company.cards?.content?.cards?.map((card) => {
          if (card.location === FIELD_LOCATIONS.FRONT) {
            return (
              <LittleWalletStyle.block key={card.id?.toString()}>
                <Text
                  wrap
                  color={fontColor(company.color)}
                  transform="uppercase"
                  size={7}
                  weight="bold"
                >
                  {card.title || t('preview.wallet.title')}
                </Text>
                <Text
                  wrap
                  underline={isLink(card.type)}
                  color={isLink(card.type) ? '#007bff' : fontColor(company.color)}
                  size={8}
                >
                  {card.subtitle || t('preview.wallet.subtitle')}
                </Text>
              </LittleWalletStyle.block>
            )
          }
          return <></>
        })}
      </W.row>
    </LittleWalletStyle.wrapper>
  )
}

export default LittleFrontPreview
