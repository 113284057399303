import React, { ReactElement, ReactNode } from 'react'

import { useAuth, useRouteProtector, useSessionTracker } from 'hooks'
import { Menu, Header, Footer } from 'components'
import { device, styled } from 'theme'
import { routes } from 'router'

type Props = {
  children: ReactNode | undefined
}

export const Wrapper = styled.div`
  width: 100%;
  background: ${(props) => props.theme.color.accent};
  display: flex;
  ${device.sm} {
  }
`

export const Container = styled.div`
  margin: 16px 16px 16px 0px;
  background: white;
  border-radius: 13px;
  width: calc(100vw - 292px);
  height: calc(100vh - (2 * 16px) - 26px);
  padding-left: 74px;
  padding-bottom: 85px;
  overflow-x: hidden;
  ${device.sm} {
    width: calc(100vw - 184px);
  }
`

function receivePushNotification(event) {
  const { image, tag, url, title, text } = event.data.json()
  const options = {
    data: url,
    body: text,
    icon: image,
    vibrate: [200, 100, 200],
    tag,
    image,
    badge: '/favicon.ico',
    actions: [
      { action: 'Detail', title: 'View', icon: `${process.env.REACT_APP_URL}/logo512.png` },
    ],
  }
  navigator.serviceWorker.ready.then((registration) => {
    registration.showNotification(title, options)
  })
}

const list = [routes.dashboard, routes.company, routes.customer, routes.notification, routes.admin]

const Layout = ({ children }: Props): ReactElement => {
  useAuth(true)
  useRouteProtector()
  useSessionTracker()

  // Listen web push
  self.addEventListener('push', receivePushNotification)

  return (
    <Wrapper>
      <Menu nav={list} />
      <Container>
        <Header />
        {children}
        <Footer />
      </Container>
    </Wrapper>
  )
}

export default Layout
export { default as Notification } from 'layouts/Notification'
export { default as Dashboard } from 'layouts/Dashboard'
export { default as Customer } from 'layouts/Customer'
export { default as Company } from 'layouts/Company'
export { default as Admin } from 'layouts/Admin'
export { default as Login } from 'layouts/Login'
export { default as CreateEditCard } from 'layouts/CreateEditCard'
export { default as Main } from 'layouts/Main'
export { default as CreateEditNotification } from 'layouts/CreateEditNotification'
export { default as DesktopDownloadWallet } from 'layouts/DownloadWallet/DesktopDownloadWallet'
export { default as MobileDownloadWallet } from 'layouts/DownloadWallet/MobileDownloadWallet'
