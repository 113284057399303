import { device, styled } from 'theme'
import { colors } from 'theme/colors'
import { FiArrowUpCircle } from 'react-icons/fi'
import { Text } from 'components/Typography'

export const Top = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  margin-right: 74px;
  ${device.md} {
    flex-direction: column;
    align-items: flex-start;
    margin-left: -37px;
  }
`

export const WalletTitle = styled.p`
  font-weight: 450;
  font-size: 14px;
  margin-bottom: 5px;
  margin-top: 10px;
`

export const ImportButton = styled.button.attrs((props: any) => ({
  innerRef: props.innerRef,
}))`
  background-color: ${colors.primaryWhite};
  color: ${colors.mikadoYellow};
  padding: 8px 16px;
  height: 35px;
  width: 250;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 80px;
  border: 1px solid ${colors.mikadoYellow};
  cursor: pointer;
  transition: 300ms;
  &:hover {
    transform: scale(1.02);
  }
  &:focus {
    outline: none;
  }
`

export const ExportButton = styled.div`
  margin: 20px 0;
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
  pointer-events: visible;
  &:hover {
    & svg {
      transform: rotate(180deg);
    }
  }
  &:focus {
    outline: none;
  }
`

export const Icon = styled(FiArrowUpCircle)`
  transition: 200ms;
  color: ${({ theme }) => theme.color.primary};
  transform: rotate(45deg);
  margin-right: 4px;
  font-size: 20px;
`

export const Title = styled(Text)`
  pointer-events: none;
  color: ${({ theme }) => theme.color.primary};
  text-decoration: underline;
  font-size: 12px;
  font-weight: ${({ theme }) => theme.font.bold};
  margin: 0;
`
