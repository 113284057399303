import { styled } from 'theme'
import loaderSvg from '../../assets/loader.svg'

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: rgb(0, 0, 0, 0.4);
  z-index: 1000;
`

export const Spinner = styled.img`
  border: 2px solid transparent;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  margin: 0 18px;
`

Spinner.defaultProps = {
  src: loaderSvg,
}
