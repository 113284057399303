import React, { ReactElement } from 'react'
import { Wrapper, Spinner } from './styles'

export type LoaderProps = {
  active: boolean
}

const Loader = ({ active }: LoaderProps): ReactElement => {
  return active ? (
    <Wrapper data-testid="loader-wrapper">
      <Spinner alt="skoop-loading" />
    </Wrapper>
  ) : (
    <></>
  )
}

export default Loader
