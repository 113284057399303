import React, { ReactElement, useContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Loader, Text, Input, ModalLogin } from 'components'
import { NotificationContext, MyInformationsContext } from 'providers'
import { useLocation } from 'react-router-dom'
import { useLogin } from 'hooks'
import {
  Wrapper,
  Form,
  Title,
  Container,
  Illustration,
  Brand,
  Lang,
  Logo,
  Language,
} from './styles'
import LOGO from 'assets/logo-black.svg'
import LOGO_LIGHT from 'assets/logo-white.svg'
import { cookies } from 'services'
import { changeLanguage } from 'lang'

type HandleResetPassword = {
  success: boolean
  email: boolean
  message: string
}

const Login = (): ReactElement => {
  const { t } = useTranslation()
  const location = useLocation()
  const { refetchMyInformations } = useContext(MyInformationsContext)
  const [toggle, setToggle] = useState<boolean>(false)
  const [accent, setAccent] = useState<string | undefined>(cookies.get('lang'))
  const { notify } = useContext(NotificationContext)
  const [credentials, setCredentials] = useState<{ email: string; pwd: string }>({
    email: '',
    pwd: '',
  })
  const { auth, loading, refetch } = useLogin(credentials)
  const modalType: string = location.hash.replace('#', '') || 'forgot'

  function interceptHash() {
    const currentHash = location.hash.replace('#', '')
    if (
      currentHash.includes('new') ||
      currentHash.includes('forgot') ||
      currentHash.includes('reset')
    ) {
      setToggle(true)
      return true
    }
  }

  function handleLogin(event) {
    event.preventDefault()
    auth.refetch().then((res: any) => {
      const status = res.data?.status === 200 ? 'success' : 'error'
      notify({
        title: t(`login.${status}.title`),
        message: t(`login.${status}.describe`),
        type: status,
      })
      if (status === 'success') {
        refetchMyInformations()
      }
    })
  }

  const handleChange = (field: string) => (event) => {
    if (field === 'email') {
      setCredentials((pevState) => ({
        ...pevState,
        email: event.target.value,
      }))
    }
    if (field === 'pwd') {
      setCredentials((prevState) => ({
        ...prevState,
        pwd: event.target.value,
      }))
    }
  }

  useEffect(() => {
    interceptHash()
    refetch()
  }, [])

  function handleResetPassword({ success, message, email }: HandleResetPassword) {
    notify({
      title: t(`login.reset.${email ? 'email.' : ''}${success ? 'success' : 'error'}.title`),
      message: message,
      type: success ? 'success' : 'error',
    })
    if (success) {
      setToggle(false)
    }
  }

  if (auth.isIdle && loading) {
    return <Loader active={loading} />
  }

  const setLanguage = async (lang: 'fr' | 'en'): Promise<void> =>
    await changeLanguage(lang).then(() => setAccent(lang))

  return (
    <Wrapper>
      <Illustration>
        <Brand src={LOGO} alt="Skoop Logo" />
      </Illustration>
      <Container>
        <Logo src={LOGO_LIGHT} alt="Skoop Logo" />
        <Form>
          <Lang>
            <Language onClick={() => setLanguage('fr')} accent={accent !== 'en'} weight="bold">
              FR
            </Language>
            <Language onClick={() => setLanguage('en')} accent={accent === 'en'} weight="bold">
              EN
            </Language>
          </Lang>
          <Title h1>{t('login.title')}</Title>
          <form onSubmit={handleLogin}>
            <Input
              invert
              margin={[0, 0, 16, 0]}
              value={credentials.email}
              onChange={handleChange('email')}
              placeholder={t('login.email')}
              type="email"
              required
              icon
            />
            <Input
              invert
              margin={[0, 0, 16, 0]}
              value={credentials.pwd}
              onChange={handleChange('pwd')}
              placeholder={t('login.password')}
              type="password"
              required
              icon
            />
            <Button type="submit" margin={[60, 0, 0, 0]} loading={loading}>
              {t('login.button')}
            </Button>
          </form>
          <Text
            underline
            margin="10px"
            cursor={'pointer'}
            onClick={() => setToggle((prevToggle) => !prevToggle)}
          >
            {t('login.forgot.link')}
          </Text>
        </Form>
      </Container>
      {toggle && (
        <ModalLogin
          handleResetPassword={handleResetPassword}
          modalType={modalType}
          setCloseModal={setToggle}
        />
      )}
    </Wrapper>
  )
}

export default Login
