import React, { ReactElement } from 'react'

import { H1, H2, H3, Paragraph } from './styles'

export type TextProps = {
  size?: number
  align?: 'left' | 'center' | 'right'
  transform?: 'uppercase' | 'capitalize' | 'lowercase'
  weight?: 'bold' | 'normal'
  children: React.ReactText | React.ReactNode
  underline?: boolean
  color?: string
  accent?: boolean
  line?: 'default' | 'paragraph'
  margin?: number | string
  onClick?: () => void
  cursor?: 'pointer' | 'default'
  wrap?: boolean
  zIndex?: number
}

interface TitleProps extends TextProps {
  h1?: boolean
  h2?: boolean
  h3?: boolean
}

export const Title = (props: TitleProps): ReactElement => {
  if (props.h3) return <H3 {...props}>{props.children}</H3>
  if (props.h2) return <H2 {...props}>{props.children}</H2>
  return <H1 {...props}>{props.children}</H1>
}

export const Text = (props: TextProps): ReactElement => (
  <Paragraph {...props}>{props.children}</Paragraph>
)
