import React, { ReactElement, useContext, useEffect, SetStateAction, Dispatch } from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { CompanyContext, UserContext } from 'providers'
import FIELD_LOCATIONS from 'constants/fieldLocations'
import Information from 'components/FormCreateEditCard/Information'
import Formatting from 'components/FormCreateEditCard/Formatting'
import Content from 'components/FormCreateEditCard/Content'
import {
  company as companyService,
  customer as customerService,
  login as loginService,
  wallet as walletService,
  cookies,
} from 'services'
import TABS from 'constants/tabs'
import { CompanyState } from 'types'
import { Wrapper, Step, Text, Title, Header } from './styles'
import { routes } from 'router'
import { getUrlParams } from 'utils'
import { usePermission } from 'hooks'

type Props = {
  changeStep: (stepIndex: number) => void
  currentStep: number
  edit: boolean
  setDefaultCompanyStock: Dispatch<SetStateAction<CompanyState>>
  setCustomerId: Dispatch<SetStateAction<string>>
  setCompanyWalletId: Dispatch<SetStateAction<string>>
  setCompanyToken: Dispatch<SetStateAction<string>>
  steps: {
    active: boolean
    disabled: boolean
    id: number
    title: string
  }[]
  setCustomerSharedKey: Dispatch<SetStateAction<string>>
}

type CompanySetting = {
  welcomeNotification: boolean
}

const Form = ({
  steps,
  currentStep,
  changeStep,
  edit,
  setDefaultCompanyStock,
  setCustomerId,
  setCompanyWalletId,
  setCompanyToken,
  setCustomerSharedKey,
}: Props): ReactElement => {
  const { t } = useTranslation()
  const location = useLocation()
  const { isCompany } = usePermission()
  const { setCompany, company } = useContext(CompanyContext)
  const { user } = useContext(UserContext)
  const selectedSteps = location.pathname.includes(TABS.CUSTOMER) ? TABS.CUSTOMER : TABS.COMPANY

  function setContent(id: string, updatedCards): void {
    const cards = { ...company.cards, [id]: { cards: updatedCards, id } }
    setCompany({ ...company, cards })
  }

  const factoWalletData = ({
    wallet,
    settingRes,
    isCustomerCard,
  }: {
    wallet: any
    settingRes: CompanySetting
    isCustomerCard: boolean
  }) => {
    const cards = []
    wallet?.fields?.forEach((field, index) => {
      if (
        !isCustomerCard ||
        field.isOverride ||
        !field.location ||
        field.location === FIELD_LOCATIONS.BACK
      ) {
        cards.push({
          title: field.label,
          subtitle: field.value,
          type: field.type,
          location: field.location || FIELD_LOCATIONS.BACK,
          id: index + 1,
          isOverride: field.isOverride,
        })
      }
    })

    // Check if wallet have 1 header and 2 front fields if it's a cutomer wallet (and add them)
    if (isCustomerCard) {
      if (!cards.find((card) => card?.location === 'header')) {
        cards.push({
          id: cards[cards.length - 1].id + 1,
          title: '',
          subtitle: '',
          type: 'text',
          location: 'header',
        })
      }
      if (!cards.find((card) => card.location === 'front')) {
        cards.push(
          { id: cards.length + 1, title: '', subtitle: '', type: 'text', location: 'front' },
          { id: cards.length + 2, title: '', subtitle: '', type: 'text', location: 'front' },
        )
      }
      if (cards.filter((card) => card.location === 'front')?.length < 2) {
        cards.push({
          id: cards.length + 1,
          title: '',
          subtitle: '',
          type: 'text',
          location: 'front',
        })
      }
    }

    return {
      ...wallet,
      head: wallet.head,
      welcomeNotification: settingRes?.welcomeNotification || false,
      cards: { content: { cards, id: 'content' } },
    }
  }

  const setDefaultCompany = async () => {
    // EDIT OR CREATE COMPANY WALLET
    if (location.pathname.includes(routes.edit.path) && !location.pathname.includes('new')) {
      const companyId: string = isCompany() ? user.userId : location.pathname.split('edit/')[1]
      const token: string = isCompany()
        ? cookies.get('token')
        : await (await loginService.switch({ company: companyId })).token.toString()

      setCompanyToken(token)

      if (await token) {
        walletService.collection(token).then((res) => {
          if (res.status === 200) {
            companyService.settings
              .once({ id: companyId, customToken: token })
              .then((settingRes) => {
                if (settingRes.status === 200) {
                  // GET all wallet with company id and sort by createdDate to conserve the first wallet (the company wallet)
                  const companyDatas = res['hydra:member'].sort(
                    (a, b) => a.createdAt - b.createdAt,
                  )[0]
                  if (companyDatas) {
                    const defaultCompany = factoWalletData({
                      wallet: companyDatas,
                      settingRes,
                      isCustomerCard: false,
                    })

                    setDefaultCompanyStock(defaultCompany)
                    setCompany(defaultCompany)
                  }
                }
              })
          }
        })
      }
    } else if (location.pathname.includes(routes.customersCardEdit.path)) {
      // EDIT CUSTOMER WALLET
      const customerId = getUrlParams().customerId
      customerService.once({ id: customerId }).then((resCustomer) => {
        if (resCustomer.status === 200) {
          const defaultCompany = factoWalletData({
            wallet: resCustomer.wallet,
            settingRes: { welcomeNotification: false },
            isCustomerCard: true,
          })

          setDefaultCompanyStock(defaultCompany)
          setCompany({ ...defaultCompany, sharedKey: resCustomer.sharedKey })
          setCustomerId(customerId)
          setCustomerSharedKey(resCustomer.sharedKey)
          setCompanyWalletId(resCustomer.wallet.id)
        }
      })
    }
  }

  useEffect(() => {
    setDefaultCompany()
  }, [])

  function renderForm(): ReactElement {
    switch (currentStep) {
      case 0:
        return selectedSteps === TABS.CUSTOMER ? (
          <>
            <Content
              cardIndex={1}
              selectedSteps={selectedSteps}
              location="header"
              title={t('customers.create.information.heading.title')}
              cards={company.cards.content}
              setCards={setContent}
              setCompany={setCompany}
              company={company}
              names={[
                {
                  title: t('customers.create.information.heading.label'),
                  subtitle: t('customers.create.information.heading.value'),
                },
              ]}
            />
            <Content
              cardIndex={2}
              location="front"
              title={t('customers.create.information.block.title')}
              cards={company.cards.content}
              setCards={setContent}
              selectedSteps={selectedSteps}
              names={[
                {
                  title: t('customers.create.information.block.label.0', { num: 1 }),
                  subtitle: t('customers.create.information.block.value.0', { num: 1 }),
                },
                {
                  title: t('customers.create.information.block.label.1', { num: 2 }),
                  subtitle: t('customers.create.information.block.value.1', { num: 2 }),
                },
              ]}
            />
          </>
        ) : (
          <Information edit={edit} />
        )
      case 1:
        return <Formatting selectedSteps={selectedSteps} />
      case 2:
        return (
          <Content
            selectedSteps={selectedSteps}
            location="back"
            cards={company.cards.content}
            setCards={setContent}
            modular
          />
        )
      default:
        return <div>{t('form.error.resolve')}</div>
    }
  }

  return (
    <Wrapper>
      <Header>
        {steps.map(({ title, id }, index) => (
          <Step
            onClick={() => changeStep(index)}
            active={index === currentStep}
            key={id.toString()}
          >
            <Text>
              {t('edit.form.step')} {id + 1}
            </Text>
            <Title>{title}</Title>
          </Step>
        ))}
      </Header>
      {renderForm()}
    </Wrapper>
  )
}

export default Form
