import React from 'react'
import { ImportCustomerCard } from './style'
import { Title, Text } from 'components/Typography'

type Props = {
  t: (string) => string
}

const NoCustomerCard = ({ t }: Props): React.ReactElement => {
  return (
    <ImportCustomerCard.card>
      <ImportCustomerCard.icon />
      <Title margin={0} size={18}>
        {t('customers.noResult.title')}
      </Title>
      <Text margin="10px" size={18}>
        {t('customers.noResult.text')}
      </Text>
    </ImportCustomerCard.card>
  )
}

export default NoCustomerCard
