import React, { ReactElement, useEffect, useRef, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { customer, me as meService } from 'services'
import { usePermission } from 'hooks'
import { NotificationContext } from 'providers'

import { TableSkeleton } from 'components/Skeletons'
import { CustomersTable, NoCustomerCard, Counter, ImportExportCustomer, Text } from 'components'
import { Pagination, Prev, Next } from 'components/Table/styles'
import { Top, ExportButton, Icon, Title, WalletTitle } from './styles'
import * as GlobalStyle from 'styles/globalStyle'
import Layout from 'layouts'

type OrderSort = {
  type: string
  value: 'asc' | 'desc'
}

const Customer = (): ReactElement => {
  const [pageIndex, setPageIndex] = useState<number>(1)
  const [isLoad, setIsLoad] = useState<boolean>(true)
  const [orderSort, setOrderSort] = useState<OrderSort>({
    type: '',
    value: 'desc',
  })
  const { permission } = usePermission()

  const { t } = useTranslation()
  const { notify } = useContext(NotificationContext)

  const { data: customers } = useQuery(
    [`customers${orderSort.type}${orderSort.value}`, orderSort, pageIndex],
    () => customer.collection({ page: pageIndex, itemsPerPage: 30, order: orderSort }),
    {
      staleTime: 10,
    },
  )

  const { data: me } = useQuery('me', () => permission.company && meService.once(), {
    staleTime: 10,
  })

  useEffect(() => {
    if (customers) {
      if (customers.status !== 200) {
        notify(customers.response)
      }
    }
  }, [customers])

  const csvInstance = useRef<any | null>(null)

  useEffect(() => {
    if (customers) {
      setIsLoad(false)
    }
  }, [customers])

  const downloadCsv = () => {
    customer.export().then((res) => {
      notify({
        title: res.status === 404 ? res.message : t(`customers.export.${res.status}.title`),
        message: res.status !== 404 ? t(`customers.export.${res.status}.message`) : '',
        type: res.status === 202 ? 'success' : 'warning',
      })
    })
  }

  const handleChangePage = (type: 'next' | 'prev') => {
    if (type === 'prev') {
      if (pageIndex !== 1) {
        setPageIndex(pageIndex - 1)
        setIsLoad(true)
      }
    }
    if (type === 'next') {
      if (Math.ceil(customers?.['hydra:totalItems'] / 30) > pageIndex) {
        setPageIndex(pageIndex + 1)
        setIsLoad(true)
      }
    }
  }

  return (
    <Layout>
      <Top>
        <Counter
          ImportExportCsv={
            <ImportExportCustomer
              count={customers?.['hydra:totalItems'] || 0}
              importButtonRef={csvInstance}
              downloadCsv={downloadCsv}
            />
          }
          create
          type={'customer'}
          count={customers?.['hydra:totalItems'] || 0}
        />
      </Top>
      {customers?.['hydra:totalItems'] > 0 && (
        <GlobalStyle.Row width="180px">
          <ExportButton onClick={downloadCsv}>
            <Icon />
            <Title>
              {t(
                customers?.['hydra:totalItems'] > 0
                  ? 'customers.exportCustomerCards'
                  : 'customers.exportCustomerTemplate',
              )}
            </Title>
          </ExportButton>
        </GlobalStyle.Row>
      )}
      <WalletTitle>
        {t('customers.walletId.title', { walletId: me?.company?.wallets?.[0] || 'not found' })}
      </WalletTitle>
      <Text size={13} margin={0}>
        {t('customers.walletId.text')}
      </Text>
      {isLoad ? (
        <TableSkeleton />
      ) : customers?.['hydra:member']?.length ? (
        <>
          <CustomersTable
            t={t}
            customers={customers}
            orderSort={orderSort}
            setOrderSort={setOrderSort}
            setIsLoad={setIsLoad}
          />
          <Pagination>
            {pageIndex !== 1 && <Prev onClick={() => handleChangePage('prev')} />}
            <Text accent>
              {t('table.pagination', {
                current: pageIndex,
                total: Math.ceil(customers?.['hydra:totalItems'] / 30),
              })}
            </Text>
            {Math.ceil(customers?.['hydra:totalItems'] / 30) > pageIndex && (
              <Next onClick={() => handleChangePage('next')} />
            )}
          </Pagination>
        </>
      ) : (
        <NoCustomerCard t={t} />
      )}
    </Layout>
  )
}

export default Customer
