import { PwaService } from 'types'
import API, { PWA, headers } from 'api'
import { errors, api } from 'services/index'

const response = async (promise, key: string = null, methodName: 'DELETE' | null = null) => {
  const res = methodName !== 'DELETE' ? await promise.json() : {}

  return {
    ...res,
    status: promise.status,
    response: key ? errors.notify(key[promise.status], promise.status) : null,
  }
}

const pwa: PwaService = {
  once: async ({ companyId, sharedKey }) => {
    const promise = await fetch(`${API(PWA.ANDROID)}/${companyId}/${sharedKey}`, {
      method: 'GET',
      headers: headers('default', false),
    })
    return response(promise, api.admin.once)
  },
  androidPublicKey: async () => {
    const promise = await fetch(API(PWA.PUBLIC_KEY), {
      method: 'GET',
      headers: headers('default', false),
    })
    return response(promise, api.wallet.remove)
  },
}

export default pwa
