import React, { ReactElement, useContext, useRef, useState, SetStateAction, Dispatch } from 'react'
import { FiLink, FiNavigation, FiPhone, FiType } from 'react-icons/all'
import { useTranslation } from 'react-i18next'

import {
  ArrowDown,
  Button,
  Card,
  ColForm,
  Container,
  Flatlist,
  Input,
  Label,
  Requirement,
  Select,
  Top,
  Add,
  CloseIcon,
  SubTitle,
} from 'components/FormCreateEditCard/styles'
import { FieldType, CompanyState, Cards as CardsType } from 'types'
import FIELD from 'constants/fields'
import FIELD_LOCATIONS from 'constants/fieldLocations'
import { Dropdown, Title } from 'components'
import { NotificationContext } from 'providers'

type Cards = { cards: CardsType; id?: string }

type Props = {
  cards: Cards
  setCards: (id: string, updatedCards: CardsType) => void
  names?: { title: string; subtitle: string }[]
  title?: string
  modular?: boolean
  location?: string
  cardIndex?: number
  selectedSteps?: string
  setCompany?: Dispatch<SetStateAction<CompanyState>>
  company?: CompanyState
}

const Content = ({
  cards,
  setCards,
  names,
  title,
  modular,
  location,
  cardIndex,
  selectedSteps,
  company,
  setCompany,
}: Props): ReactElement => {
  const [dropdownActive, setDropdownActive] = useState<number>(null)
  const dropdownRef = useRef(null)
  const { t } = useTranslation()
  const { notify } = useContext(NotificationContext)

  // Max string characters API
  const limit = location === FIELD_LOCATIONS.BACK || !location ? 255 : 22
  const titleLimit = 22

  const maxBlockAdd = (): boolean => {
    // To manage the content of the header and the front of the cards, if no card location define is a back card
    // If we edit an customer card, we must display only the override content at back location (isOverride = customer card content)
    const maxContentBlock = 3
    const currentCards = cards?.cards?.filter(
      (card) =>
        card.location !== FIELD_LOCATIONS.FRONT &&
        card.location !== FIELD_LOCATIONS.HEADER &&
        (selectedSteps !== 'customer' ||
          card.isOverride === undefined ||
          ('isOverride' in card && card.isOverride !== false)),
    )
    return currentCards?.length >= maxContentBlock
  }

  const addBlock = (): void => {
    if (maxBlockAdd())
      return notify({
        title: t('edit.form.addBlock.title'),
        message: t('edit.form.addBlock.message'),
        type: 'info',
      })
    if (cards.cards.length) {
      setCards(cards.id, [
        ...cards.cards,
        {
          id: cards.cards[cards.cards.length - 1].id + 1,
          title: '',
          subtitle: '',
          type: 'text',
          location: FIELD_LOCATIONS.BACK,
        },
      ])
    }
  }

  const removeCard = (id) => {
    const sorted = cards.cards.filter((card) => card.id !== id)
    sorted.forEach((card, index) => {
      card.id = index
    })
    setCards(cards.id, sorted)
  }

  const handleChange = (id, key, value, hasLimit = true) => {
    if (hasLimit && value.length > limit) return false
    const mute = [...cards.cards]
    const cardIndex = mute.findIndex((obj) => obj.id == id)
    mute[cardIndex][key] = value
    mute[cardIndex].location = location || FIELD_LOCATIONS.BACK
    setCards(cards.id, mute)
  }

  const handleChangeType = (id: number, key: string, value: FieldType): void => {
    handleChange(id, key, value, false)
    setDropdownActive(null)
  }

  const setIcon = (type) => {
    switch (type) {
      case FIELD.TEXT:
        return <FiType />
      case FIELD.LINK:
        return <FiLink />
      case FIELD.LOCATION:
        return <FiNavigation />
      case FIELD.PHONE:
        return <FiPhone />
    }
  }

  const setDropdownList = (
    id: number,
  ): {
    text: string
    icon: ReactElement
    action: () => void
  }[] => {
    return Object.values(FIELD).map((type) => ({
      icon: setIcon(type),
      text: t(`edit.content.card.type.${type}`),
      action: () => handleChangeType(id, 'type', type),
    }))
  }

  return (
    <>
      <Top>
        <Title margin={0} size={16}>
          {title || t('edit.content.title')}
          <br />
          {modular && cards.id === 'content' && <SubTitle>{t('edit.content.maxBlocks')}</SubTitle>}
        </Title>
        {modular && cards.id === 'content' && (
          <Button onClick={addBlock} outlined disabled={maxBlockAdd()}>
            <Add />
            {t('edit.content.addBlock')}
          </Button>
        )}
      </Top>
      <Flatlist>
        {cardIndex === 1 && selectedSteps === 'customer' && (
          <>
            <Label>{t(`edit.sharedKey.label`)}</Label>
            <Input
              value={company.sharedKey || ''}
              maxLength={limit}
              placeholder={t('edit.sharedKey.placeholder')}
              onChange={(event) =>
                setCompany((prevState) => ({ ...prevState, sharedKey: event.target.value }))
              }
            />
          </>
        )}
        {cards?.cards?.map((card, index) => {
          if (
            (card.location === location || (location === FIELD_LOCATIONS.BACK && !card.location)) &&
            (location !== FIELD_LOCATIONS.BACK ||
              selectedSteps !== 'customer' ||
              card.isOverride !== false)
          )
            return (
              <Card
                marginBottom={index === cards.cards.length - 1 ? '0px' : '20px'}
                key={card.id?.toString() || index + 1}
              >
                {index > 0 && (location === FIELD_LOCATIONS.BACK || !card.location) && (
                  <CloseIcon onClick={() => removeCard(card.id)} />
                )}
                {location !== FIELD_LOCATIONS.HEADER && (
                  <Top>
                    <Select
                      onClick={() => setDropdownActive(dropdownActive === null ? card.id : null)}
                    >
                      {setIcon(card.type)}
                      {t(`edit.content.card.type.${card.type}`)}
                      <ArrowDown />
                    </Select>
                    <Dropdown
                      display={dropdownActive === card.id}
                      reference={dropdownRef}
                      selector
                      list={setDropdownList(card.id)}
                    />
                  </Top>
                )}
                <Container>
                  <ColForm>
                    <Label>
                      {names?.[index]?.title ||
                        t(
                          `edit.content.card.${
                            location === FIELD_LOCATIONS.HEADER ? 'header.' : ''
                          }title`,
                        )}
                    </Label>
                    <Requirement>
                      {t('edit.content.card.requirement', { limit: titleLimit })}
                    </Requirement>
                    <Input
                      value={card?.title}
                      maxLength={titleLimit}
                      placeholder={t('edit.content.card.placeholder')}
                      onChange={(event) => handleChange(card.id, 'title', event.target.value)}
                    />
                  </ColForm>
                  <ColForm>
                    <Label>
                      {names?.[index]?.subtitle ||
                        t(
                          `edit.content.card.${
                            location === FIELD_LOCATIONS.HEADER ? 'header.' : ''
                          }${card.type}`,
                        )}
                    </Label>
                    <Requirement>{t('edit.content.card.requirement', { limit })}</Requirement>
                    <Input
                      value={cards.cards[index]?.subtitle}
                      maxLength={limit}
                      placeholder={t(`edit.content.card.${card.type}`)}
                      onChange={(event) => handleChange(card.id, 'subtitle', event.target.value)}
                    />
                  </ColForm>
                </Container>
              </Card>
            )
        })}
      </Flatlist>
    </>
  )
}

export default Content
