import { CompanyServices } from 'types'
import API, { COMPANY, headers } from 'api'
import { errors, api } from 'services/index'
import cookies from 'services/cookies'

const response = async (promise, key: string = null, methodName: 'DELETE' | null = null) => {
  const res = methodName === 'DELETE' ? {} : await promise.json()

  if (key) {
    return {
      ...res,
      status: promise.status,
      response: errors.notify(key[promise.status], promise.status),
    }
  }
  return { ...res, status: promise.status, response: null }
}

const company: CompanyServices = {
  collection: async ({ page, itemsPerPage, sortBy, searchWord }) => {
    const url = new URL(API(COMPANY.DEFAULT))
    const params = [
      ['page', page.toString()],
      ['itemsPerPage', itemsPerPage.toString()],
      ['order[name]', sortBy || ''],
      ['name', searchWord || ''],
    ]
    url.search = new URLSearchParams(params).toString()
    const promise = await fetch(url.href, {
      method: 'GET',
      headers: headers(),
    })
    return response(promise, api.company.collection)
  },
  once: async ({ id }) => {
    const promise = await fetch(`${API(COMPANY.DEFAULT)}/${id}`, {
      method: 'GET',
      headers: headers(),
    })
    return response(promise, api.company.once)
  },
  create: async (body: { name; administrator }) => {
    const promise = await fetch(`${API(COMPANY.DEFAULT)}`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: headers(),
    })
    return response(promise, api.company.create)
  },
  update: async ({ id }, body: { name }) => {
    const promise = await fetch(`${API(COMPANY.DEFAULT)}/${id}`, {
      method: 'PATCH',
      body: JSON.stringify(body),
      headers: headers(),
    })
    return response(promise, api.company.update)
  },
  remove: async ({ id }) => {
    const promise = await fetch(`${API(COMPANY.DEFAULT)}/${id}`, {
      method: 'DELETE',
      headers: headers(),
    })
    return response(promise, api.company.remove)
  },
  settings: {
    once: async ({ id, customToken }: { id: string; customToken: string }) => {
      const promise = await fetch(`${API(COMPANY.DEFAULT)}/${id}/settings`, {
        method: 'GET',
        headers: headers('default', true, customToken || cookies.get('token')),
      })
      return response(promise, api.company.settings.once)
    },
    update: async ({ id, body, customToken }) => {
      const promise = await fetch(`${API(COMPANY.DEFAULT)}/${id}/settings`, {
        method: 'PATCH',
        body: JSON.stringify(body),
        headers: headers('default', true, customToken || cookies.get('token')),
      })
      return response(promise, api.company.settings.update)
    },
  },
}

export default company
