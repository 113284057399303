import { FiClock } from 'react-icons/fi'

import { device, styled } from 'theme'
import { colors } from 'theme/colors'

const googleColor = 'rgba(123, 133, 249, 0.36)'
const appleColor = '#f8d5aa'

type Props = {
  type: 'google' | 'apple'
}

type TooltipProps = {
  value: number | string
  color: string
}

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-right: 74px;
  margin-top: 40px;
  margin-bottom: 25px;
`

export const Clock = styled(FiClock)`
  margin-right: 5px;
  margin-bottom: -2px;
`

export const Wip = styled.div`
  z-index: 2;
  position: absolute;
  width: calc(100% - 400px);
  background: rgba(255, 255, 255, 0.9);
  height: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${device.sm} {
    width: calc(100% - 185px);
  }
`

export const Square = styled.div`
  width: 278px;
  height: 220px;
  border-radius: 36px 0 36px 36px;
  background: ${(square: Props) => (square.type === 'google' ? googleColor : appleColor)};
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`

export const Type = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  & p {
    color: ${colors.marine}99;
  }
`

export const Total = styled.div`
  display: flex;
  flex-direction: column;
`

export const Icon = styled.img`
  width: 74px;
  height: 74px;
  object-fit: contain;
`

export const Container = styled.div`
  height: 220px;
  width: calc(100% - 320px);

  svg {
    overflow: visible;
  }
`

export const Tooltip = styled.div`
  z-index: 10;
  box-shadow: 1px 0 5px 0 rgba(0, 0, 0, 0.16);
  background: white;
  border-radius: 3px;
  padding: 5px 12px;
  font-size: 12px;
  font-weight: bold;
  height: 17px;
  min-width: 12px;
  width: 27px;
  &:before {
    content: '';
    display: block;
    width: 17px;
    height: 19px;
    background: white;
    position: absolute;
    -webkit-transform: rotate(45deg) !important;
    -ms-transform: rotate(45deg) !important;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    box-shadow: 2px 2px 5px 0 rgb(0 0 0 / 16%);
    margin: 0 auto;
    left: 6px;
    top: 8px;
  }
  &:after {
    content: '${(tooltip: TooltipProps) => tooltip.value}';
    z-index: 4;
    height: 10px;
    border-radius: 10px;
    padding: 5px 0;
    font-size: 12px;
    font-weight: bold;
    position: absolute;
    opacity: 1;
    width: 100%;
    color: ${(tooltip: TooltipProps) => tooltip.color};
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background: white;
    left: 0;
    top: 0;
  }
`
