import React, { Dispatch, SetStateAction, ReactElement } from 'react'
import frLocale from 'date-fns/locale/fr'
import enLocale from 'date-fns/locale/en-US'
import Stack from '@mui/material/Stack'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import moment from 'moment'

import { Text } from 'components'
import { WrapperCalendar, CalendarStyle, Title } from './styles'
import { Notification } from 'types'
import { defaultSentAt } from 'layouts/CreateEditNotification'
import { cookies } from 'services'

type Props = {
  notification: Notification
  setNotification: Dispatch<SetStateAction<Notification>>
  isNotificationSent: boolean
  t: (string, params?) => string
}

const tomorrow = new Date()
tomorrow.setDate(tomorrow.getDate() + 1)
tomorrow.setHours(tomorrow.getHours() + 1)

const Calendar = ({
  notification,
  setNotification,
  isNotificationSent,
  t,
}: Props): ReactElement => {
  const updateDate = ({ type, date }) => {
    let newDate = date
    if (date && date !== 'Invalid Date' && date instanceof Date) {
      newDate = moment(date).format()
    }
    setNotification({ ...notification, [type]: newDate })
  }

  const getDatePlusOneDay = (date: Date) => {
    const newDate = new Date(date)
    newDate.setDate(newDate.getDate() + 1)
    newDate.setHours(newDate.getHours() + 1)
    return newDate
  }

  const getDateMinusOneDay = (date: Date) => {
    const newDate = new Date(date)
    newDate.setDate(newDate.getDate() - 1)
    return newDate
  }

  return (
    <WrapperCalendar>
      <CalendarStyle.head>
        <CalendarStyle.sendIcon />
        <Title size={20}>{t('notification.form.calendar.title')}</Title>
      </CalendarStyle.head>
      <LocalizationProvider
        locale={cookies.get('lang') === 'en' ? enLocale : frLocale}
        dateAdapter={AdapterDateFns}
      >
        <Stack direction="column" spacing={2}>
          <Stack
            onClick={() =>
              !notification.definedSending &&
              isNotificationSent === false &&
              setNotification({ ...notification, definedSending: true })
            }
            sx={{
              width: 300,
              marginRight: '30px',
            }}
            spacing={3}
          >
            <Title style={{ marginTop: '20px', height: '25px' }} size={15}>
              {t('notification.form.calendar.start.title')}
            </Title>
            <Text margin={'3px'} size={15}>
              {t('notification.form.calendar.start.text')}
            </Text>
            <DateTimePicker
              disabled={!notification?.definedSending || isNotificationSent}
              minDateTime={new Date(defaultSentAt)}
              maxDateTime={getDateMinusOneDay(notification?.endAt)}
              label={t('sendingDate')}
              value={new Date(notification?.sentAt)}
              onChange={(sentAt) => {
                updateDate({ type: 'sentAt', date: sentAt })
              }}
            />
          </Stack>
          <Stack
            sx={{
              width: 300,
            }}
            spacing={3}
          >
            <Title style={{ marginTop: '20px', height: '25px' }} size={15}>
              {t('notification.form.calendar.end.title')}
            </Title>
            <Text margin={'3px'} size={15}>
              {t('notification.form.calendar.end.text')}
            </Text>

            <DateTimePicker
              minDateTime={notification?.sentAt ? getDatePlusOneDay(notification.sentAt) : tomorrow}
              label={t('endDate')}
              value={new Date(notification?.endAt)}
              onChange={(endAt) => {
                updateDate({ type: 'endAt', date: endAt })
              }}
            />
          </Stack>
        </Stack>
      </LocalizationProvider>
    </WrapperCalendar>
  )
}

export default Calendar
