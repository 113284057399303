import { colors } from 'theme/colors'

export const darkTheme = {
  color: {
    accent: colors.primaryWhite,
    alternative: colors.spanishGrey.dark,
    background: colors.marine,
    primary: colors.mikadoYellow,
    shaded: colors.jet,
  },
  shadow: '0px 3px 16px 0px rgba(0,0,0,0.16)',
  font: {
    bold: 600,
    regular: 400,
  },
}
