import { useContext } from 'react'
import { UserContext } from 'providers'
import ROLES from 'constants/roles'

type PFunction = () => boolean

type ReturnType = {
  permission: {
    name: string
    type: string
    print: () => string
    admin: boolean
    company: boolean
    user: boolean
  }
  isAdmin: PFunction
  isCompany: PFunction
  isUser: PFunction
}

const usePermission = (): ReturnType => {
  const { user } = useContext(UserContext)

  // return permission API constant
  const getPermission = (): string => {
    if (user.subscription) return Object.values(ROLES).find((role) => role === user.subscription)
    return Object.values(ROLES).find((role) => role === user.role)
  }

  // return permission boolean
  const isAdmin: PFunction = () => getPermission() === ROLES.SUPER_ADMIN
  const isCompany: PFunction = () => getPermission() === ROLES.COMPANY_ADMIN
  const isUser: PFunction = () => true

  // return permission formatted name
  const getName = () => {
    if (getPermission() === ROLES.SUPER_ADMIN) return 'admin'
    if (getPermission() === ROLES.COMPANY_ADMIN) return 'company'
    return 'user'
  }

  const permission = {
    name: getName(),
    type: getPermission(),
    print: (): string => {
      if (isAdmin())
        return `\x1b[34m${
          user.email
        } \x1b[30m is a super admin with the following permission: \x1B[31m${getPermission()}`
      if (isCompany())
        return `\x1b[34m${
          user.email
        } \x1b[30m is a company admin with the following permission: \x1B[31m${getPermission()}`
      if (isUser())
        return `\x1b[34m${
          user.email
        } \x1b[30m is an user with following the permission: \x1B[31m${getPermission()}`
    },
    admin: isAdmin(),
    company: isCompany(),
    user: isUser(),
  }

  return {
    permission,
    isAdmin,
    isCompany,
    isUser,
  }
}

export default usePermission
