import React, { ReactElement, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Text } from 'components'
import LittleFrontPreview from './LittleFrontPreview'
import { W, UploadIcon } from './styles'
import { CompanyState } from 'types'
import { parseApiPicture } from 'utils'
import * as GlobalStyled from 'styles/globalStyle'

type WalletProps = {
  company: CompanyState
  os: 'ios' | 'android'
  showVerso: boolean
}

const Wallet = ({ company, os, showVerso }: WalletProps): ReactElement => {
  const logoRef = useRef(null)
  const location = useLocation()
  const illustrationRef = useRef(null)
  const customerWallet = location.pathname.includes('/customers')
  const { t } = useTranslation()

  const cardHeader = company.cards?.content?.cards?.find((card) => card.location === 'header') || {
    title: '',
    subtitle: '',
  }

  const isLink = (type: string): boolean => {
    const linkTypes = ['website', 'location', 'phone_number']
    return linkTypes.includes(type)
  }

  // Select font color as white or black depend on background color selected by user
  // If background is dark return white else black
  // IF (Red + Green + Blue) > MediumValue THEN use #000 ELSE use #FFF
  const fontColor = (color: string | null): string => {
    if (!color || color.length < 7) return '#000000'
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color)
    if (
      parseInt(result[1], 16) * 0.299 +
        parseInt(result[2], 16) * 0.587 +
        parseInt(result[3], 16) * 0.114 >
      186
    )
      return '#000000'
    else return '#FFFFFF'
  }

  const showCard = (card): boolean =>
    (customerWallet && !showVerso && card.location === 'front') ||
    (showVerso && (card.location === 'back' || !card.location))

  return (
    <W.wrapper color={company.color}>
      {showVerso ? (
        <W.verso.top>
          <LittleFrontPreview
            os={os}
            company={company}
            fontColor={fontColor}
            cardHeader={cardHeader}
            isLink={isLink}
            logoRef={logoRef}
            illustrationRef={illustrationRef}
          />
          <W.verso.title color={fontColor(company.color)}>
            {company.name || t('preview.wallet.name')}
          </W.verso.title>
        </W.verso.top>
      ) : (
        <>
          <W.top>
            <W.logo
              src={parseApiPicture({ img: company.logo, defaultImg: UploadIcon })}
              ref={logoRef}
              hide={!company.logo}
            />
            <W.user>
              {customerWallet && (
                <Text
                  wrap
                  color={fontColor(company.color)}
                  transform="uppercase"
                  align="right"
                  size={10}
                >
                  {cardHeader.title || t('edit.content.card.header.text')}
                </Text>
              )}
              {customerWallet && os === 'ios' && (
                <Text wrap color={fontColor(company.color)} size={12} weight="bold" align="right">
                  {cardHeader.subtitle || t('edit.content.card.header.text')}
                </Text>
              )}
            </W.user>
          </W.top>
          {os === 'ios' && (
            <W.illustration
              src={parseApiPicture({ img: company.head, defaultImg: UploadIcon })}
              ref={illustrationRef}
              hide={!company.head}
            />
          )}
          {customerWallet && os === 'android' && (
            <GlobalStyled.Row width="60%" margin="10px">
              <Text wrap color={fontColor(company.color)} size={12} weight="bold">
                {cardHeader.subtitle || t('edit.content.card.header.text')}
              </Text>
            </GlobalStyled.Row>
          )}
        </>
      )}
      <W.row type={os}>
        {company.cards?.content?.cards?.map((card) => {
          if (showCard(card)) {
            return (
              <W.block verso={showVerso} key={card.id?.toString()}>
                <Text
                  wrap
                  color={fontColor(company.color)}
                  transform="uppercase"
                  size={10}
                  weight="bold"
                >
                  {card.title || t('preview.wallet.title')}
                </Text>
                <Text
                  wrap
                  underline={isLink(card.type)}
                  color={isLink(card.type) ? '#007bff' : fontColor(company.color)}
                  size={11}
                >
                  {card.subtitle || t('preview.wallet.subtitle')}
                </Text>
              </W.block>
            )
          }
          return <></>
        })}
      </W.row>
      {os === 'android' && !showVerso && (
        <W.illustration
          src={parseApiPicture({ img: company.head, defaultImg: UploadIcon })}
          ref={illustrationRef}
          hide={!company.head}
        />
      )}
    </W.wrapper>
  )
}

export default Wallet
