import { styled, css } from 'theme'
import { TextProps } from './index'

export const textAlign = css`
  text-align: ${(text: TextProps) =>
    !text.align || text.align === 'right' ? 'right' : text.align};
`

export const textTransform = css`
  text-transform: ${(text: TextProps) => text.transform && text.transform};
`

export const lineHeight = css`
  line-height: ${(text: TextProps) => text.line === 'paragraph' && 38}px;
`

export const fontSize = css`
  font-size: ${(text: TextProps) => text.size && text.size}px;
`

export const fontWeight = css`
  font-weight: ${(text: TextProps) => text.weight === 'bold' && 600};
`

export const color = css`
  color: ${(text: TextProps) => (text.accent ? (props) => props.theme.color.accent : text.color)};
`

export const cursor = css`
  cursor: ${(text: TextProps) => text.cursor};
`

export const underline = css`
  text-decoration: underline;
`

export const H1 = styled.h1`
  font-size: ${(props: TextProps) => props.size || 52}px;
  font-weight: 600;
  line-height: ${(props: TextProps) => props.size || 52}px;
  z-index: ${(props: TextProps) => props.zIndex || 1};
  color: ${(props) => props.theme.color.accent};
  ${(props: TextProps) => props.align && textAlign};
  margin: ${(props: TextProps) => props.margin && props.margin};
  ${() => color};
`

export const H2 = styled.h2`
  font-size: 52px;
  font-weight: 600;
  line-height: 78px;
  color: ${(props) => props.theme.color.accent};
  ${(props: TextProps) => props.align && textAlign};
  margin: ${(props: TextProps) => props.margin && props.margin};
  ${() => color};
  z-index: ${(props: TextProps) => props.zIndex || 1};
`

export const H3 = styled.h3`
  font-size: 52px;
  font-weight: 600;
  line-height: 78px;
  color: ${(props) => props.theme.color.accent};
  ${(props: TextProps) => props.align && textAlign};
  margin: ${(props: TextProps) => props.margin && props.margin};
  ${() => color};
  z-index: ${(props: TextProps) => props.zIndex || 1};
`

export const Paragraph = styled.p`
  font-size: 14px;
  font-weight: 400;
  overflow-wrap: ${(props) => (props.wrap ? 'anywhere' : 'initial')};
  color: ${(props) => props.color || props.theme.color.alternative};
  ${() => color};
  ${(props: TextProps) => props.cursor && cursor};
  ${(props: TextProps) => props.underline && underline};
  ${(props: TextProps) => props.size && fontSize};
  ${(props: TextProps) => props.align && textAlign};
  ${(props: TextProps) => props.transform && color};
  ${(props: TextProps) => props.line && lineHeight};
  ${(props: TextProps) => props.transform && textTransform};
  ${(props: TextProps) => props.weight && fontWeight};
  margin: ${(props: TextProps) => props.margin && props.margin};
  ${(props) =>
    props.wrap &&
    `
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    `}
`
