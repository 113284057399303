import React, { FC, useState } from 'react'

import { ProviderProps, UserContextProps, UserState } from 'types'
import { cookies } from 'services'

export const UserContext = React.createContext<UserContextProps | undefined>(undefined)

UserContext.displayName = 'User'

const userModel = {
  email: null,
  token: cookies.get('token') || null,
  userId: null,
  role: null,
  exp: null,
  subscription: null,
  temp: null,
}

const User: FC<ProviderProps> = ({ children }: ProviderProps) => {
  const [isAuthenticated, setAuthenticated] = useState<boolean>(false)
  const [user, setUser] = useState<UserState>(userModel)

  const resetUser = () => {
    setUser(userModel)
  }

  const context = {
    isAuthenticated,
    setAuthenticated,
    resetUser,
    setUser,
    user,
  }

  return <UserContext.Provider value={context}>{children}</UserContext.Provider>
}

export default User
