import { device, styled } from 'theme'
import { colors } from 'theme/colors'
import { Link } from 'react-router-dom'
import { FiLogOut, FiChevronsLeft } from 'react-icons/fi'

type ItemProps = {
  active?: boolean
}

type ButtonProps = {
  outlined?: boolean
}

export const Wrapper = styled.div`
  width: 230px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${colors.marine};
  transition: 100ms;
  ${device.sm} {
    width: 90px;
  }
`

export const Brand = styled.img`
  margin-top: 50px;
  width: 100%;
  object-fit: contain;
  height: 54px;
  ${device.sm} {
    height: 34px;
  }
`

export const Nav = styled.ul`
  background: ${(props) => props.theme.color.accent};
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

export const Item = styled.li`
  color: ${(item: ItemProps) => (item.active ? 'black' : 'white')};
  width: 198px;
  text-align: left;
  cursor: pointer;
  padding-left: 18px;
  margin-top: 10px;
  border-radius: 20px 0px 0px 20px;
  display: flex;
  align-items: center;
  background: ${(item: ItemProps) =>
    item.active ? (props) => props.theme.color.background : (props) => props.theme.color.accent};
  height: 40px;
  & a {
    transition: 200ms;
    color: ${(item: ItemProps) =>
      item.active ? colors.marine : (props) => props.theme.color.alternative};
    &:hover {
      color: ${(item: ItemProps) => !item.active && colors.primaryWhite};
    }
  }
  &::before {
    display: ${(item: ItemProps) => (item.active ? 'block' : 'none')};
    content: '';
    z-index: 4;
    position: absolute;
    background-color: ${colors.marine};
    height: 20px;
    width: 180px;
    margin-top: -60px;
    border-bottom-right-radius: 20px;
  }
  &::after {
    display: ${(item: ItemProps) => (item.active ? 'block' : 'none')};
    content: '';
    position: absolute;
    background-color: ${colors.marine};
    height: 20px;
    width: 180px;
    margin-top: 60px;
    border-top-right-radius: 20px;
  }
  ${device.sm} {
    width: 60px;
    & a {
      display: none;
    }
    &::before {
      width: 60px;
    }
    &::after {
      height: 20px;
      width: 60px;
    }
  }
`

export const Icon = styled.div`
  width: 26px;
  height: 26px;
  object-fit: contain;
  margin-right: 10px;
`

export const Active = styled.div`
  z-index: 0;
  position: absolute;
  height: 80px;
  width: 24px;
  margin-left: 164px;
  background: ${(props) => props.theme.color.background};
  ${device.sm} {
    margin-left: 36px;
  }
`

export const Location = styled(Link)`
  text-decoration: none;
  z-index: 2;
`

export const Top = styled.div``
export const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  padding: 18px 36px;
`

export const Button = styled.button`
  cursor: pointer;
  font-size: 14px;
  margin-top: 18px;
  height: 54px;
  border-radius: 54px;
  border: none;
  color: ${(button: ButtonProps) => (button.outlined ? colors.mikadoYellow : colors.primaryWhite)};
  background: ${(button: ButtonProps) =>
    button.outlined
      ? (props) => props.theme.color.accent
      : (props) => `${props.theme.color.alternative}20`};
  border: ${(button: ButtonProps) =>
    button.outlined ? `1px solid ${colors.mikadoYellow}` : 'none'};
  transition: 100ms;
  &:hover {
    transform: scale(1.05);
  }
  ${device.sm} {
    margin-left: -18px;
    margin-right: -18px;
  }
`

export const Back = styled(FiChevronsLeft)`
  font-size: 22px;
`

export const Exit = styled(FiLogOut)`
  font-size: 22px;
`
export const Version = styled.p`
  position: absolute;
  top: 10px;
  left: 10px;
  color: ${colors.spanishGrey.light};
  font-size: 12px;
`
