import React, { ReactElement } from 'react'
import { ResponsiveLine } from '@nivo/line'
import { useTranslation } from 'react-i18next'

import { Wrapper, Square, Icon, Total, Container, Type, Tooltip } from './styles'
import { Title, Text } from 'components/Typography'
import APPLE_ROUNDED from 'assets/apple-rounded.svg'
import GOOGLE_ROUNDED from 'assets/google-rounded.svg'

type monthStats = {
  x: string
  y: number
}

type Props = {
  type: 'google' | 'apple'
  value: {
    total: number
    months: Array<monthStats>
  }
  wip?: boolean
}

const chartProperties = {
  google: {
    colors: ['#7b85f9', 'transparent'],
    gradient: [
      { offset: 0, color: '#7b85f9' },
      { offset: 100, color: '#7b85f900' },
    ],
  },
  apple: {
    colors: ['#f7931e', 'transparent'],
    gradient: [
      { offset: 0, color: '#f8d5aa' },
      { offset: 100, color: '#f8d5aa00' },
    ],
  },
}

const data = (type, value) => [
  {
    id: 'y',
    color: getColor(type, 0),
    data: value,
  },
  {
    id: 'y-1',
    color: getColor(type, 1),
    data: value,
  },
]

const getColor = (type: 'google' | 'apple', id: number): string =>
  type === 'google' ? chartProperties.google.colors[id] : chartProperties.apple.colors[id]

const formatter = new Intl.DateTimeFormat('en', { month: 'short', year: '2-digit' })
const month = (date: Date) => formatter.format(new Date(date))

const Chart = ({ type, value }: Props): ReactElement => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <Square type={type}>
        <Type>
          <Icon src={type === 'google' ? GOOGLE_ROUNDED : APPLE_ROUNDED} />
          <Text weight="bold">{type === 'apple' ? 'Apple' : 'Android'}</Text>
        </Type>
        <Total>
          <Text weight="bold">{t('dashboard.charts.total')}</Text>
          <Title margin={0} size={45} weight="normal">
            {value.total}
          </Title>
        </Total>
      </Square>
      <Container>
        <ResponsiveLine
          data={data(type, value.months)}
          curve="basis"
          lineWidth={4}
          enableArea
          areaOpacity={0.5}
          areaBlendMode="multiply"
          colors={(d) => d.color}
          margin={{ top: 10, right: 10, bottom: 20, left: 50 }}
          axisLeft={null}
          axisBottom={{
            tickSize: 0,
            format: (value) => month(value),
          }}
          tooltip={(slice) => (
            <Tooltip
              color={
                slice.point.color !== 'transparent'
                  ? slice.point.color
                  : `${chartProperties[type].colors[0]}80`
              }
              value={slice.point.data.yFormatted}
            />
          )}
          enablePoints={false}
          enableGridY={false}
          enableGridX={false}
          isInteractive
          enableCrosshair={false}
          useMesh={true}
          pointLabel="y"
          theme={{
            markers: { textColor: '#000', fontSize: '74' },
            axis: { legend: { text: { fontSize: '14' } } },
          }}
          defs={[
            {
              id: 'androidGradient',
              type: 'linearGradient',
              colors: chartProperties.google.gradient,
            },
            {
              id: 'appleGradient',
              type: 'linearGradient',
              colors: chartProperties.apple.gradient,
            },
            {
              id: 'defaultGradient',
              type: 'linearGradient',
              colors: [
                { offset: 0, color: 'transparent' },
                { offset: 100, color: 'transparent' },
              ],
            },
          ]}
          fill={[
            { match: { id: 'y-1' }, id: type === 'google' ? 'androidGradient' : 'appleGradient' },
            { match: { id: 'y' }, id: 'defaultGradient' },
          ]}
        />
      </Container>
    </Wrapper>
  )
}

export default Chart
