import { styled } from 'theme'
import { colors } from 'theme/colors'

export const Illustration = styled.img`
  width: 100%;
  height: auto;
  margin-bottom: 40px;
  border: 1px solid ${colors.greyish};
  border-radius: 8px;
`
