import React, { ReactElement, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { MobileLayout, NotFoundIllustration, NotFoundSkoopLogo } from './styles'
import * as GlobalStyle from 'styles/globalStyle'
import { changeLanguage } from 'lang'

const NotFoundWallet = (): ReactElement => {
  const { t } = useTranslation()

  useEffect(() => {
    // DETECT BROWSER LANGUAGE
    if (navigator.languages?.[0]?.includes('en')) {
      changeLanguage('en')
    } else {
      changeLanguage('fr')
    }
  }, [])

  return (
    <MobileLayout>
      <NotFoundSkoopLogo />
      <GlobalStyle.Text
        width="90%"
        align="center"
        color="mikadoYellow"
        fontWeight={600}
        fontSize={25}
      >
        {t('downloadWallet.notFound')}
      </GlobalStyle.Text>
      <NotFoundIllustration />
    </MobileLayout>
  )
}

export default NotFoundWallet
