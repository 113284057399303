import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'

import { Main } from 'layouts'
import { Theme, User, Client, Statistics, MyInformations } from 'providers'
import 'lang'
import { Loader } from 'components'

import './styles/globalStyle.css'
import './theme/materialUi.css'

ReactDOM.render(
  <React.StrictMode>
    <Theme>
      <Client>
        <User>
          <Statistics>
            <MyInformations>
              <Suspense fallback={<Loader active />}>
                <Main />
              </Suspense>
            </MyInformations>
          </Statistics>
        </User>
      </Client>
    </Theme>
  </React.StrictMode>,
  document.getElementById('root'),
)
