import React, { Dispatch, SetStateAction, ReactElement, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { NotificationContext } from 'providers'
import { DialogType } from 'types'
import { notification as notificationService } from 'services'
import { Dialog } from 'components'

type Props = {
  removeNotificationId: string
  setRemoveNotificationId: Dispatch<SetStateAction<string>>
  refreshNotifications: (options?: any) => Promise<any>
}

const RemoveNotification = ({
  removeNotificationId,
  setRemoveNotificationId,
  refreshNotifications,
}: Props): ReactElement => {
  const { t } = useTranslation()
  const { notify } = useContext(NotificationContext)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const submitRemove = () => {
    setIsLoading(true)
    notificationService.remove({ id: removeNotificationId }).then((result) => {
      notify(result.response)
      setRemoveNotificationId('')
      if (result.status === 204) {
        refreshNotifications()
      }
    })
  }

  const dialogProps: DialogType = {
    title: t('notification.dialog.remove.title'),
    content: t('notification.dialog.remove.content'),
    type: 'error',
    action: {
      dismiss: {
        onClick: () => setRemoveNotificationId(''),
        text: t('admin.dialog.add.action.dismiss'),
      },
      active: {
        onClick: submitRemove,
        text: t('admin.dialog.remove.action.confirm'),
      },
    },
  }

  return <Dialog {...dialogProps} isLoading={isLoading} />
}

export default RemoveNotification
