import React, { ReactElement, useState, Dispatch, SetStateAction } from 'react'

import { Container, Wrapper600 } from './styles'
import { Notification, CompanyState } from 'types'
import { CardPreview } from 'components'
import Content from 'components/FormCreateEditCard/Content'
import { BottomStepsProps } from './BottomSteps'
import BottomSteps from './BottomSteps'

type Props = {
  notification: Notification
  company: CompanyState
  setNotification: Dispatch<SetStateAction<Notification>>
  bottomStepsProps: BottomStepsProps
}

const Contents = ({
  notification,
  company,
  setNotification,
  bottomStepsProps,
}: Props): ReactElement => {
  const [showVerso, setShowVerso] = useState(true)

  const handleUpdateCards = (id: string, updatedCards): void => {
    setNotification({ ...notification, fields: updatedCards })
  }

  return (
    <Wrapper600>
      <Container>
        <Content
          modular
          location="back"
          cards={{ cards: notification.fields, id: 'content' }}
          setCards={handleUpdateCards}
        />
        <BottomSteps {...bottomStepsProps} />
      </Container>
      <CardPreview
        setShowVerso={setShowVerso}
        showVerso={showVerso}
        companyProps={{
          ...company,
          cards: { ['content']: { cards: notification.fields, id: 'content' } },
        }}
      />
    </Wrapper600>
  )
}

export default Contents
