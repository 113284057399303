import { Dispatch, SetStateAction } from 'react'
import moment from 'moment'

import { Notification, Notify } from 'types'
import { notification as notificationService } from 'services'
import { routes } from 'router'

export type Props = {
  notification: Notification
  setIsLoad: Dispatch<SetStateAction<boolean>>
  t: (string: string) => string
  notify: (params: Notify) => void
  push: any
  action: string
  notificationId?: string
}

type AreNotificationFieldsValid = {
  sendingNotification: Notification
  notification: Notification
  t: (string: string, object?: any) => string
  notify: (params: Notify) => void
}

const areNotificationFieldsValid = ({
  sendingNotification,
  notification,
  t,
  notify,
}: AreNotificationFieldsValid): boolean => {
  const { sentAt, endAt } = sendingNotification
  const datesAreValid =
    ((notification.definedSending &&
      sentAt &&
      new Date(sentAt) > new Date() &&
      sentAt < endAt &&
      sentAt != 'Invalid Date') ||
      !notification.definedSending) &&
    endAt &&
    endAt != 'Invalid Date'

  for (const key in sendingNotification) {
    if (datesAreValid === false) {
      notify({
        title: t('notification.form.create.timePickerError.title'),
        type: 'error',
        message: t('notification.form.create.timePickerError.message'),
      })
      return false
    } else if (key !== 'sentAt' && !sendingNotification[key]) {
      notify({
        title: t('notification.form.create.missingInfos', {
          field: t(`notification.form.create.informations.${key}`),
        }),
        type: 'error',
        message: '',
      })
      return false
    }
  }
  if (
    sendingNotification?.fields?.some((field) =>
      Object.keys(field).find((fieldKey) => !field[fieldKey]),
    )
  ) {
    notify({
      title: t('notification.form.create.missingFieldInfos'),
      type: 'error',
      message: '',
    })
    return false
  }
  return true
}

function getFormData(object) {
  const formData = new FormData()
  for (const key in object) {
    if (key === 'fields') {
      object.fields.forEach((field, index) =>
        Object.keys(field).forEach((fieldKey) =>
          formData.append(`fields[${index}][${fieldKey}]`, field[fieldKey]),
        ),
      )
    } else if (key === 'customers') {
      object.customers.forEach((customer, index) => {
        formData.append(`customers[${index}]`, customer)
      })
    } else {
      formData.append(key, object[key])
    }
  }
  return formData
}

// TO DO NEXT FEATURE
export const sendNotification = async ({
  action,
  notificationId,
  notification,
  setIsLoad,
  t,
  notify,
  push,
}: Props) => {
  setIsLoad(true)

  // facto fields
  const fields = notification.fields.map((item) => {
    if (item.location === 'back') {
      return {
        label: item.title,
        value: item.subtitle,
        type: item.type,
      }
    }
  })
  const sendingNotification: Notification = {
    name: notification.name,
    text: notification.text,
    customers: notification.targetSending ? notification.customers : [],
    endAt: '',
    sentAt: '',
    fields,
  }

  if (notification.definedSending && notification.sentAt !== 'Invalid date') {
    let sentAt = new Date(notification.sentAt)
    // if the start date is less than 3 minutes apart from now, we put him back to now +5 minutes, otherwise it won't go to the backend
    const minutesBetweenNowAndSentAt = Math.round(
      (new Date().getTime() - new Date(notification.sentAt).getTime()) / 60000,
    )
    if (minutesBetweenNowAndSentAt >= 3) {
      sentAt = new Date()
      sentAt.setMinutes(sentAt.getMinutes() + 5)
    }
    sendingNotification.sentAt = moment(sentAt).format()
  }

  if (notification.endAt !== 'Invalid date') {
    sendingNotification.endAt = moment(new Date(notification.endAt)).format()
  }

  // IF HEAD IMAGE UPLOAD ADD IMAGE, ELSE API GET DEFAULT HEAD IMAGE PRIMARY WALLET
  if (notification.head && typeof notification.head !== 'string') {
    sendingNotification.head = notification.head
  }

  if (areNotificationFieldsValid({ sendingNotification, notification, t, notify }) === true) {
    const bodyFormData = getFormData(sendingNotification)

    let res = undefined

    // EXECUTE GOOD REQUEST
    if (action === 'create' || action === 'duplicate') {
      res = await notificationService.create(bodyFormData)
    } else if (action === 'update') {
      res = await notificationService.update({ id: notificationId }, bodyFormData)
    }

    if (res) {
      notify({
        title: t(res.response.message),
        type: res.response.type,
        message: '',
      })
      if (res.status === 201 || res.status === 200) {
        push(routes.notification.path)
      }
      setIsLoad(false)
    }
  } else {
    setIsLoad(false)
  }
}
