import { useEffect, useLayoutEffect, useRef, useState } from 'react'

import { authenticate, cookies } from 'services'
import { useLogin } from 'hooks/index'

function useInterval(callback: () => void, delay: number | null) {
  const savedCallback = useRef(callback)

  useLayoutEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    if (!delay && delay !== 0) return
    const id = setInterval(() => savedCallback.current(), delay)
    return () => clearInterval(id)
  }, [delay])
}

export default function useSessionTracker(time?: number | null): void | boolean {
  const [delay] = useState<number>(time || 5000)
  const token = cookies.get('token')
  const refreshToken = cookies.get('refreshToken')
  const { refetch, logout } = useLogin()

  if (!token) return false

  const expTime = authenticate.jwt(token).exp
  useInterval(() => {
    if (expTime && authenticate.hasExpired(expTime)) {
      if (refreshToken) {
        refetch()
      } else {
        logout()
      }
    }
  }, delay)
}
