import { CustomerServices } from 'types'
import API, { CUSTOMERS, IMPORT, headers, EXPORT } from 'api'
import { errors, api } from 'services/index'

const response = async (promise, key: string = null) => {
  const res = await promise.json()
  if (key) {
    return {
      ...res,
      status: promise.status,
      response: errors.notify(key[promise.status], promise.status),
    }
  }
  return { ...res, status: promise.status, response: null }
}

const transformCSV = async (promise, key) => {
  const csv = await promise.text()
  return {
    csv: csv.replace(/"/g, ''), //remove "" to parse in correct csv
    status: promise.status,
    response: errors.notify(key[promise.status], promise.status),
  }
}

const customer: CustomerServices = {
  collection: async ({ page, itemsPerPage, order } = { page: 1, itemsPerPage: 30 }) => {
    const url = new URL(API(CUSTOMERS.DEFAULT))
    const params = [
      ['page', page?.toString() || '1'],
      ['itemsPerPage', itemsPerPage?.toString() || '30'],
      [order?.type, order?.value],
    ]
    url.search = new URLSearchParams(params).toString()
    const promise = await fetch(url.href, {
      method: 'GET',
      headers: headers(),
    })
    return response(promise, api.customer.collection)
  },
  once: async ({ id }) => {
    const promise = await fetch(`${API(CUSTOMERS.DEFAULT)}/${id}`, {
      method: 'GET',
      headers: headers(),
    })
    return response(promise, api.customer.once)
  },
  create: async (body) => {
    const promise = await fetch(
      `${API(Array.isArray(body) ? CUSTOMERS.BATCH : CUSTOMERS.DEFAULT)}`,
      {
        method: 'POST',
        body: JSON.stringify(body),
        headers: headers(),
      },
    )
    return response(promise, api.customer.create)
  },
  update: async ({ id }, body = null) => {
    const promise = await fetch(`${API(CUSTOMERS.DEFAULT)}/${id}`, {
      method: 'PATCH',
      body: (body && JSON.stringify(body)) || null,
      headers: headers(),
    })
    return promise
  },
  remove: async ({ id }, body = null) => {
    const queryParams = id ? `?id=${id}` : ''
    const promise = await fetch(
      `${API(body && !id ? CUSTOMERS.BATCH : CUSTOMERS.DEFAULT)}${queryParams}`,
      {
        method: 'DELETE',
        body: (body && JSON.stringify(body)) || null,
        headers: headers(),
      },
    )
    return response(promise, api.customer.remove)
  },
  wallet: {
    once: async ({ id }) => {
      const promise = await fetch(`${API(CUSTOMERS.DEFAULT)}/${id}/wallet`, {
        method: 'GET',
        headers: headers(),
      })
      return response(promise, api.customer.wallet)
    },
    update: async ({ id }, body) => {
      const promise = await fetch(`${API(CUSTOMERS.DEFAULT)}/${id}/wallet`, {
        method: 'POST',
        body,
        headers: headers('file', true),
      })
      return response(promise, api.customer.update)
    },
    remove: async ({ id }) => {
      const promise = await fetch(`${API(CUSTOMERS.DEFAULT)}/${id}/wallet`, {
        method: 'DELETE',
        headers: headers(),
      })
      return response(promise, api.customer.remove)
    },
  },
  import: async (body) => {
    const promise = await fetch(`${API(IMPORT.CUSTOMERS)}`, {
      method: 'POST',
      body,
      headers: headers('file', true),
    })
    return response(promise, api.customer.import)
  },
  export: async () => {
    const promise = await fetch(`${API(EXPORT.CUSTOMERS)}`, {
      method: 'GET',
      headers: headers('file', true),
    })

    return transformCSV(promise, api.customer.export)
  },
}

export default customer
