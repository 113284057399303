import { colors } from 'theme/colors'

export const lightTheme = {
  color: {
    accent: colors.marine,
    alternative: colors.spanishGrey.light,
    background: colors.primaryWhite,
    primary: colors.mikadoYellow,
    shaded: colors.cultured,
  },
  shadow: '0px 3px 16px 0px rgba(0,0,0,0.16)',
  font: {
    bold: 600,
    regular: 400,
  },
}
