import React, { ReactElement, useState, useRef, Dispatch, SetStateAction } from 'react'

import {
  Requirement,
  Upload,
  Preview,
  UploadIcon,
  Media,
} from 'components/FormCreateEditCard/styles'
import { Title, Warning, TextArea, Wrapper600, Container } from './styles'
import { Notification, CompanyState } from 'types'
import { parseApiPicture } from 'utils'
import { Text, CardPreview } from 'components'
import { BottomStepsProps } from './BottomSteps'
import BottomSteps from './BottomSteps'

type Props = {
  notification: Notification
  company: CompanyState
  setNotification: Dispatch<SetStateAction<Notification>>
  t: (string, params?) => string
  bottomStepsProps: BottomStepsProps
}

const Formatting = ({
  notification,
  company,
  setNotification,
  t,
  bottomStepsProps,
}: Props): ReactElement => {
  const mediaInput = useRef(null)
  const [showVerso, setShowVerso] = useState(false)

  return (
    <Wrapper600>
      <Container>
        <Title style={{ marginBottom: '5px' }}>{t('notification.form.message.title')}</Title>
        <Requirement>{t('notification.form.message.requirements.0')}</Requirement>
        <Requirement>
          <Warning />
          {t('notification.form.message.requirements.1')}
        </Requirement>
        <TextArea
          value={notification.text}
          onChange={(e) => setNotification({ ...notification, text: e.target.value })}
        />

        <Title style={{ marginBottom: '5px' }}>{t('edit.formatting.image.label')}</Title>
        <Requirement>{t('edit.formatting.image.size', { size: '750px x 246px' })}</Requirement>
        <Requirement>{t('edit.formatting.image.format')}</Requirement>
        <Upload
          onClick={() => mediaInput.current.click()}
          style={{ width: 444, padding: 0, height: 226 }}
        >
          {notification.head ? (
            <Preview
              src={parseApiPicture({ img: notification.head, defaultImg: UploadIcon })}
              style={{ objectFit: 'cover', height: '100%', width: '100%', borderRadius: 7 }}
            />
          ) : (
            <>
              <UploadIcon />
              <Text>{t('edit.formatting.image.load')}</Text>
            </>
          )}
          <Media
            name="logo"
            type="file"
            accept="image/png, image/jpeg"
            ref={mediaInput}
            onChange={(head) => {
              setNotification({
                ...notification,
                head: head.target.files[0],
              })
            }}
          />
        </Upload>
        <BottomSteps {...bottomStepsProps} />
      </Container>
      <CardPreview
        setShowVerso={setShowVerso}
        showVerso={showVerso}
        companyProps={{
          ...company,
          cards: { ['content']: { cards: notification.fields, id: 'content' } },
        }}
      />
    </Wrapper600>
  )
}

export default Formatting
