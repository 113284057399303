import React, { ReactElement, useState } from 'react'

import {
  Wrapper,
  Content,
  Title,
  Message,
  Type,
  Container,
  Error,
  Success,
  Info,
  Warning,
} from './styles'

type ToastProps = {
  title: string
  type: string // 'success' | 'warning' | 'error' | 'info'
  time: number
}

interface NotificationProps extends ToastProps {
  message: string
  type: string
}

const Notification = ({ title, message, type, time }: NotificationProps): ReactElement => {
  const [animate, setAnimate] = useState<boolean>(true)

  return (
    <Wrapper type={type} play={animate ? 1 : 0} onClick={() => setAnimate(false)} duration={time}>
      <Content>
        <Title>{title}</Title>
        <Message>{message}</Message>
      </Content>
      <Type>{type}</Type>
    </Wrapper>
  )
}

export const Toast = ({ title, type, time }: ToastProps): ReactElement => {
  const [animate, setAnimate] = useState<boolean>(true)

  function renderIcon(): ReactElement {
    if (type === 'error') return <Error />
    if (type === 'success') return <Success />
    if (type === 'warning') return <Warning />
    return <Info />
  }

  return (
    <Container type={type} play={animate ? 1 : 0} duration={time} onClick={() => setAnimate(false)}>
      {renderIcon()}
      <Title>{title}</Title>
    </Container>
  )
}

export default Notification
