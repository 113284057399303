import React, { ReactElement, useEffect, useState, useContext } from 'react'
import { useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'
import { Divider } from 'antd'

import { pwa as pwaService, wallet as walletService } from 'services'
import { changeLanguage } from 'lang'
import { getUrlParams } from 'utils'
import { MobileLayout, WalletCard, GooglePayButton, DisableNotifications, Card } from './styles'
import * as GlobalStyle from 'styles/globalStyle'
import { NotificationContext } from 'providers'
import ActivateNotificationTuto from 'components/Modals/DownloadWallet/ActivateNotificationTuto'
import DisableNotification from 'components/Modals/DownloadWallet/DisableNotification'
import NotFoundWallet from './NotFoundWallet'
import { Loader } from 'components'

type UrlParams = {
  companyId: string
  sharedKey: string
  customerId: string
  lang?: 'fr' | 'en'
}

type WebPushInformations = {
  keys?: {
    auth?: string
    p256dh?: string
  }
  endpoint?: string
}

const MobileDownloadWallet = (): ReactElement => {
  const { t } = useTranslation()
  const { notify } = useContext(NotificationContext)
  const [isBadWalletUrl, setIsBadWalletUrl] = useState<boolean>(false)
  const [isNotificationTutoDisplayed, setIsNotificationTutoDisplayed] = useState<boolean>(false)
  const [isGoogleWalletClicked, setIsGoogleWalletClicked] = useState<boolean>(false)
  const [isNotificationDisabled, setIsNotificationDisabled] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const urlParams: UrlParams = getUrlParams()

  const isPushNotificationSupported: boolean =
    'serviceWorker' in navigator && 'PushManager' in window

  const defaultNotificationPermission: string = Notification.permission

  const { data: pwaInformations } = useQuery(
    `pwaInformations${urlParams.companyId}${urlParams.customerId}`,
    () => pwaService.once({ companyId: urlParams.companyId, sharedKey: urlParams.sharedKey }),
  )

  const { data: androidPublicKey } = useQuery(`androidPublicKey`, () =>
    pwaService.androidPublicKey(),
  )

  // CHECK IF WALLET URL EXIST
  useEffect(() => {
    if (pwaInformations && pwaInformations.status !== 200) {
      setIsBadWalletUrl(true)
    }
  }, [pwaInformations])

  useEffect(() => {
    // CHECK IF NOTIFICATIONS ARE ALREADY DENIED
    if (defaultNotificationPermission === 'denied') {
      setIsNotificationTutoDisplayed(true)
    } else if (defaultNotificationPermission === 'default') {
      askUserPermission()
    }
    // DETECT BROWSER LANGUAGE
    if (navigator.languages?.[0]?.includes('en')) {
      changeLanguage('en')
    } else {
      changeLanguage('fr')
    }
  }, [])

  const installWallet = (webPushInformations: WebPushInformations) => {
    walletService
      .registration({
        company: `${process.env.REACT_APP_API_ENDPOINT}companies/${urlParams.companyId}`,
        customer: `${process.env.REACT_APP_API_ENDPOINT}customers/${urlParams.customerId}`,
        deviceIdentifier: webPushInformations?.keys?.auth || null,
        endpoint: webPushInformations?.endpoint || null,
        p256dh: webPushInformations?.keys?.p256dh || null,
      })
      .then((res) => {
        if (res.status === 201) {
          window.location.href = `https://pay.google.com/gp/v/save/${pwaInformations.jwt}`
        } else {
          notify({
            title: t('error'),
            message: '',
            type: 'error',
          })
        }
      })
  }

  const registrationServiceWorker = async ({ isRegistration }) => {
    try {
      const registration = await navigator.serviceWorker.register('/serviceWorker.js')
      let subscription = await registration.pushManager.getSubscription()
      // if the user is not already subscribed, we subscribe to push notifications
      if (!subscription) {
        if (androidPublicKey.vapidPublicKey) {
          subscription = await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: androidPublicKey.vapidPublicKey,
          })
        } else {
          throw Error('android public key not found')
        }
      }
      if (isRegistration) {
        installWallet(subscription.toJSON())
      } else {
        return subscription.toJSON()
      }
    } catch (err) {
      notify({
        title: t('error'),
        message: '',
        type: 'error',
      })
    }
  }

  const disableNotification = async () => {
    if ((await Notification.requestPermission()) === 'granted') {
      const deviceInformations = await registrationServiceWorker({ isRegistration: false })
      walletService.deleteRegistration({
        deviceIdentifier: deviceInformations?.keys?.auth || '',
        companyId: urlParams.companyId,
        customerId: urlParams.customerId,
      })
    }
    notify({
      title: t('downloadWallet.notificationSuccessfullyDisabled'),
      message: '',
      type: 'success',
    })
    setIsNotificationDisabled(false)
  }

  const askUserPermission = async () => {
    setIsLoading(true)
    const webPushStatus = await Notification.requestPermission()
    if (webPushStatus === 'granted') {
      registrationServiceWorker({ isRegistration: true })
    } else if (isGoogleWalletClicked === true) {
      installWallet({})
    } else {
      setIsNotificationTutoDisplayed(true)
      setIsLoading(false)
    }
    setIsGoogleWalletClicked(true)
  }

  const handleClickGooglePay = () => {
    if (isPushNotificationSupported) {
      askUserPermission()
    } else {
      alert('device not supported')
    }
  }

  const handleValidNotificationReactivation = () => {
    // PERMISSION MAY HAVE CHANGED, DON'T GET DEFAULT PERMISSION
    if (Notification.permission !== 'granted') {
      notify({
        title: t('downloadWallet.notReactived'),
        message: '',
        type: 'error',
      })
    } else {
      registrationServiceWorker({ isRegistration: true })
    }
  }

  return isBadWalletUrl ? (
    <NotFoundWallet />
  ) : (
    <MobileLayout>
      <Loader active={!pwaInformations?.jwt || isLoading} />
      {isNotificationTutoDisplayed && (
        <ActivateNotificationTuto
          handleOk={handleValidNotificationReactivation}
          closeModal={() => setIsNotificationTutoDisplayed(false)}
        />
      )}
      {isNotificationDisabled && (
        <DisableNotification
          closeModal={() => setIsNotificationDisabled(false)}
          handleOk={disableNotification}
        />
      )}
      <WalletCard>
        <GlobalStyle.Row>
          <Card.Icon src={pwaInformations?.walletLogo} />
          <GlobalStyle.TextEllipsis align="end" width="calc(100% - 100px)">
            {pwaInformations?.walletName}
          </GlobalStyle.TextEllipsis>
        </GlobalStyle.Row>
        <Divider />
        <GlobalStyle.TextEllipsis fontSize="18px" color="default" width="100%">
          {pwaInformations?.companyName}
        </GlobalStyle.TextEllipsis>
        <GlobalStyle.ColumnCenter>
          <Card.Illustration src={pwaInformations?.walletHead} />
        </GlobalStyle.ColumnCenter>
      </WalletCard>
      <GlobalStyle.Column width="85vw">
        <GlobalStyle.Text fontSize="18px" margin="0 0 6px 0" color="black" fontWeight={500}>
          {t('downloadWallet.mobile.title', { companyName: pwaInformations?.companyName })}
        </GlobalStyle.Text>
        <ul>
          <li>
            <GlobalStyle.Text>{t('downloadWallet.mobile.step1')}</GlobalStyle.Text>
          </li>
          <li>
            <GlobalStyle.Text>{t('downloadWallet.mobile.step2')}</GlobalStyle.Text>
          </li>
        </ul>
        <GooglePayButton onClick={handleClickGooglePay} />
      </GlobalStyle.Column>
      <DisableNotifications onClick={() => setIsNotificationDisabled(true)}>
        {t('disableNotifications')}
      </DisableNotifications>
    </MobileLayout>
  )
}

export default MobileDownloadWallet
