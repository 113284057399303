import { FiChevronDown, VscChromeClose, FiPlus, FiUploadCloud, FiX } from 'react-icons/all'

import { css, styled } from 'theme'
import { colors } from 'theme/colors'

type Props = {
  active: boolean
}

type Card = {
  marginBottom: string
}

type ButtonProps = {
  outlined?: boolean
  disabled?: boolean
}

type SelectProps = {
  disabled?: boolean
}

type UploadProps = {
  size?: { width: number; height: number }
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`

export const Step = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin-right: 25px;
  margin-bottom: 20px;
  & span {
    color: ${(step: Props) => !step.active && colors.greyish};
    border-color: ${(step: Props) => !step.active && colors.greyish};
  }
`
export const Text = styled.span`
  font-style: italic;
  font-size: 12px;
  color: ${(props) => props.theme.color.accent};
`
export const Title = styled.span`
  color: ${(props) => props.theme.color.primary};
  font-size: 14px;
  font-weight: 600;
  padding: 8px 2px;
  border-bottom: 1px solid ${(props) => props.theme.color.accent};
`

export const Label = styled.label`
  font-weight: 500;
  font-size: 13px;
  color: ${(props) => props.theme.color.accent};
`

export const Requirement = styled.p`
  font-weight: 500;
  font-size: 10px;
  font-style: italic;
  color: ${(props) => props.theme.color.alternative};
  margin: 2px 0;
`

export const Input = styled.input`
  width: 444px;
  border: none;
  border-bottom: 1px solid ${(props) => props.theme.color.accent};
  color: ${(props) => props.theme.color.accent};
  padding: 7px 0;
  font-size: 14px;
  margin-bottom: 30px;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: ${(props) => props.theme.color.accent}50;
  }
`
export const Media = styled.input`
  display: none;
`

export const Preview = styled.img`
  width: 100%;
  object-fit: contain;
`
export const Upload = styled.button`
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${({ size }: UploadProps) => size?.width || 184}px;
  height: ${({ size }: UploadProps) => size?.height || 100}px;
  border: 1px solid ${(props) => props.theme.color.accent};
  border-radius: 7px;
  background: ${(props) => props.theme.color.background};
  overflow: hidden;
  &:focus {
    outline: none;
  }
`
export const UploadIcon = styled(FiUploadCloud)`
  font-size: 24px;
  color: ${(props) => props.theme.color.accent};
`

export const CloseIcon = styled(VscChromeClose)`
  font-size: 17px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`

export const SubTitle = styled.p`
  color: #85859d;
  font-size: 13px;
  font-weight: 300;
  margin-top: 5px;
`

export const Color = styled.div`
  max-width: 444px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.color.accent};
  margin-bottom: 30px;
  padding-bottom: 10px;
  & input {
    -webkit-appearance: none;
    padding: 0;
    border: none;
    border-radius: 10px;
    width: 100px;
    height: 26px;
    margin-bottom: 0;
    &::-webkit-color-swatch {
      border: none;
      border-radius: 5px;
      padding: 0;
    }
    &::-webkit-color-swatch-wrapper {
      border: none;
      border-radius: 10px;
      padding: 0;
    }
  }
`

export const Hexa = styled(Text)`
  font-size: 14px;
  color: ${(props) => props.theme.color.accent};
  font-style: normal;
  margin-bottom: -10px;
`

export const Card = styled.div`
  width: 422px;
  background: ${(props) => props.theme.color.background};
  padding: 16px;
  box-shadow: ${(props) => props.theme.shadow};
  margin-bottom: ${({ marginBottom }: Card) => marginBottom || '20px'};
  position: relative;
`
export const Close = styled(FiX)`
  cursor: pointer;
  color: ${(props) => props.theme.color.alternative};
  transition: 200ms;
  &:hover {
    transform: rotate(90deg);
  }
`
export const More = styled.button`
  cursor: pointer;
  color: ${(props) => props.theme.color.primary};
  transition: 200ms;
  &:hover {
    transform: rotate(90deg);
  }
`
export const Top = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
`
export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`
export const ColForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 10px;
  & input {
    margin-bottom: 10px;
    width: 198px;
    width: 100%;
  }
`
export const Flatlist = styled.div`
  margin-left: -30px;
  padding: 16px 25px 16px 33px;
  width: 470px;
`

const disabledButton = css`
  color: ${colors.primaryWhite};
  background: ${colors.spanishGrey.light}50;
  border: none;
  cursor: not-allowed;
  & svg {
    color: ${colors.primaryWhite};
  }
  &:hover {
    transform: scale(1);
  }
`

const outlinedButton = css`
  color: ${colors.mikadoYellow};
  background: ${({ theme }) => theme.color.background};
  border: 1px solid ${colors.mikadoYellow};
`

export const Button = styled.button`
  cursor: pointer;
  font-size: 14px;
  margin: 0px 18px;
  height: 35px;
  border-radius: 54px;
  width: 174px;
  color: ${colors.primaryWhite};
  background: ${({ theme }) => theme.color.primary};
  border: 1px solid ${colors.mikadoYellow};
  transition: 100ms;
  &:hover {
    transform: scale(1.05);
  }
  &:focus {
    outline: none;
  }
  ${({ outlined }: ButtonProps) => outlined && outlinedButton}
  ${({ disabled }: ButtonProps) => disabled && disabledButton}
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Add = styled(FiPlus)`
  cursor: pointer;
  font-size: 20px;
  margin-bottom: -5px;
  margin-right: 6px;
  color: ${(props) => props.theme.color.primary};
`

export const Select = styled.button`
  cursor: ${({ disabled }: SelectProps) => (disabled ? 'not-allowed' : 'pointer')};
  background: ${({ theme }) => theme.color.alternative}30;
  color: ${({ theme }) => theme.color.accent}70;
  justify-content: flex-start;
  text-transform: capitalize;
  border-radius: 20px;
  align-items: center;
  position: relative;
  padding: 4px 14px;
  min-width: 150px;
  font-size: 14px;
  display: flex;
  border: none;
  & svg {
    margin-top: -2px;
    margin-right: 6px;
  }
`

export const ArrowDown = styled(FiChevronDown)`
  position: absolute;
  font-size: 18px;
  right: 4px;
`

export const Switch = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  & label:last-of-type {
    margin-left: 10px;
  }
`
