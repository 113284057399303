import React, { ReactElement } from 'react'
import { Redirect, Route } from 'react-router-dom'

import { Loader } from 'components'
import routes from 'router/routes'
import { useAuth } from 'hooks'

type PrivateRouteProps = {
  children: ReactElement
  path: string
  exact?: boolean
}

const PrivateRoute = ({ children, ...rest }: PrivateRouteProps): ReactElement => {
  const { isLoading, isAuthenticated } = useAuth()

  if (isLoading) return <Loader active={true} />

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: routes.login.path,
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

export default PrivateRoute
