import React, { ReactElement } from 'react'
import { TFunctionResult } from 'i18next'

import { Wrapper, Text, Arrow, Add, Loading } from './styles'

export type ButtonType = {
  margin?: [number, number, number, number]
  onClick?: () => void
  icon?: 'arrow' | 'add'
  type?: 'submit' | 'button'
} & React.HTMLProps<HTMLButtonElement>

export type TextType = {
  centered?: boolean
}

interface ButtonProps extends ButtonType {
  children: React.ReactText | TFunctionResult
  loading: boolean
}

const Button = ({ margin, icon, onClick, children, loading, type }: ButtonProps): ReactElement => {
  function renderIcon() {
    if (loading) return <Loading />
    if (icon === 'arrow') return <Arrow />
    if (icon === 'add') return <Add />
    return icon
  }

  return (
    <Wrapper type={type} margin={margin} onClick={() => !loading && onClick?.()}>
      <Text>{children}</Text>
      {renderIcon()}
    </Wrapper>
  )
}

export default Button
