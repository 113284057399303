import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from 'antd'

import { Title } from 'components'

type Props = {
  closeModal: () => void
  handleOk: () => void
}

const DisableNotification = ({ closeModal, handleOk }: Props): ReactElement => {
  const { t } = useTranslation()

  return (
    <Modal
      visible
      title={t('downloadWallet.disableNotification.title')}
      onOk={handleOk}
      onCancel={closeModal}
    >
      <Title margin="15px" size={18}>
        {t('downloadWallet.disableNotification.message1')}
      </Title>
      <Title margin="15px" size={18}>
        {t('downloadWallet.disableNotification.message2')}
      </Title>
    </Modal>
  )
}

export default DisableNotification
