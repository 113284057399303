import React, { ReactElement, useContext, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Input,
  Label,
  Media,
  Preview,
  Requirement,
  Text,
  Upload,
  UploadIcon,
  Switch,
} from 'components/FormCreateEditCard/styles'
import { CompanyContext } from 'providers'
import { Toggle } from 'components/index'
import { parseApiPicture } from 'utils'

type Props = {
  edit: boolean
}

const Information = ({ edit }: Props): ReactElement => {
  const { setCompany, company } = useContext(CompanyContext)
  const mediaInput = useRef(null)
  const iconInput = useRef(null)
  const { t } = useTranslation()

  return (
    <>
      <Label>{t('edit.information.name.label')}</Label>
      <Requirement>{t('edit.information.name.requirement', { number: 255 })}</Requirement>
      <Input
        placeholder={t('edit.information.name.label')}
        value={company.name}
        onChange={(name) => setCompany((prevState) => ({ ...prevState, name: name.target.value }))}
      />
      {!edit && (
        <>
          <Label>{t('edit.information.email.label')}</Label>
          <Input
            type="email"
            placeholder={t('edit.information.email.placeholder')}
            value={company.email}
            onChange={(email) =>
              setCompany((prevState) => ({
                ...prevState,
                email: email.target.value.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
              }))
            }
          />
        </>
      )}

      <Label>{t('edit.information.logo.label')}</Label>
      <Requirement>{t('edit.information.logo.size', { size: '320x100px' })}</Requirement>
      <Requirement>{t('edit.information.logo.format')}</Requirement>
      <Upload onClick={() => mediaInput.current.click()}>
        {company.logo ? (
          <Preview src={parseApiPicture({ img: company.logo, defaultImg: UploadIcon })} />
        ) : (
          <>
            <UploadIcon />
            <Text>{t('edit.information.logo.load')}</Text>
          </>
        )}
        <Media
          name="logo"
          type="file"
          accept="image/png, image/jpeg"
          ref={mediaInput}
          onChange={(logo) => setCompany({ ...company, logo: logo.target.files[0] })}
        />
      </Upload>
      <Label>{t('edit.information.icon.label')}</Label>
      <Requirement>{t('edit.information.logo.size', { size: '100x100px' })}</Requirement>
      <Requirement>{t('edit.information.logo.format')}</Requirement>
      <Upload onClick={() => iconInput.current.click()} size={{ width: 64, height: 64 }}>
        {company.icon ? (
          <Preview src={parseApiPicture({ img: company.icon, defaultImg: UploadIcon })} />
        ) : (
          <>
            <UploadIcon />
            <Text>{t('edit.information.icon.load')}</Text>
          </>
        )}
        <Media
          name="icon"
          type="file"
          accept="image/png, image/jpeg"
          ref={iconInput}
          onChange={(icon) => setCompany({ ...company, icon: icon.target.files[0] })}
        />
      </Upload>
      <Switch
        key={company.welcomeNotification?.toString()}
        onClick={() =>
          setCompany({
            ...company,
            welcomeNotification: !company.welcomeNotification,
          })
        }
      >
        <Toggle checked={company.welcomeNotification} />
        <Label>{t('edit.information.notif')}</Label>
      </Switch>
    </>
  )
}

export default Information
