import { createGlobalStyle } from 'styled-components'
import { normalize } from 'styled-normalize'

import { Theme } from 'types'

const GlobalStyle = createGlobalStyle<{ theme: Theme }>`
  ${normalize}
  * {
    font-family: 'Poppins', sans-serif;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      background: ${({ theme }) => theme.color.background};
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.color.accent};
      border-radius: 5px;
      border: none;
    }
  }
  body {
    background:  ${({ theme }) => theme.color.background};
    transition: all 0.20s linear;
  }
`
export default GlobalStyle
