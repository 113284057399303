import { styled } from 'theme'
import { Text } from 'components/Typography'
import { FiArrowUpCircle } from 'react-icons/all'

export const Event = styled.button`
  margin: 20px 0;
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
  :hover {
    & svg {
      transform: rotate(180deg);
    }
  }
  :focus {
    outline: none;
  }
`

export const Icon = styled(FiArrowUpCircle)`
  transition: 200ms;
  color: ${({ theme }) => theme.color.primary};
  transform: rotate(45deg);
  margin-right: 4px;
  font-size: 20px;
`

export const Title = styled(Text)`
  color: ${({ theme }) => theme.color.primary};
  text-decoration: underline;
  font-size: 12px;
  font-weight: ${({ theme }) => theme.font.bold};
  margin: 0;
`
