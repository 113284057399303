export const colors = {
  eerieBlack: '#1e1e1e',
  jet: '#222222',
  marine: '#22223a',
  spanishGrey: { dark: '#9a9fa1', light: '#9095a2' },
  greyish: '#e6e6ef',
  cultured: '#f3f4f5',
  primaryWhite: '#ffffff',
  greenPigment: '#2c9f53',
  mikadoYellow: '#f7931e',
  winterSky: '#ff006e',
  blueViolet: '#8338ec',
  azure: '#3a86ff',
  success: '#27be3c',
  error: '#e93131',
  warning: '#f1b726',
  info: '#a25bd8',
  grey: '#595959',
  translucentGray: '#efefef',
}

export const globalColors = {
  primary: colors.mikadoYellow,
  default: colors.grey,
}
