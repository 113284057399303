import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { Wrapper, Text, Links, Link } from './styles'

const Footer = (): ReactElement => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <Text>{t('footer.copyright', { year: new Date().getFullYear() })}</Text>
      <Links>
        {/* Link ref unset still we have no data */}
        <Link to={''}>{t('footer.terms')}</Link>
        <Link to={''}>{t('footer.privacy')}</Link>
      </Links>
    </Wrapper>
  )
}

export default Footer
