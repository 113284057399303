import { Roles } from 'types'

//Sorted by power
export const ROLES: Roles = {
  SUPER_ADMIN: 'ROLE_SUPER_ADMIN',
  COMPANY_ADMIN: 'ROLE_COMPANY_ADMIN',
  USER: 'ROLE_USER',
}

export default ROLES
