import React, { ReactElement, useContext } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import LOGO from 'assets/logo-white.svg'
import LOGO_SMALL from 'assets/logo-small.svg'
import {
  Wrapper,
  Brand,
  Nav,
  Item,
  Active,
  Location,
  Bottom,
  Button,
  Top,
  Exit,
  Back,
  Version,
} from './styles'
import { Route } from 'types'
import { useLogin, useWindowDimensions } from 'hooks'
import { FiBriefcase, FiBell, FiUsers, FiActivity, FiKey } from 'react-icons/fi'
import { size } from 'theme'
import { colors } from 'theme/colors'
import { UserContext, MyInformationsContext } from 'providers'
import routes from 'router/routes'
import { cookies } from 'services'

type MenuProps = {
  nav: Route[]
}

const Menu = ({ nav }: MenuProps): ReactElement => {
  const { user, setUser } = useContext(UserContext)
  const { refetchMyInformations, myInformations } = useContext(MyInformationsContext)
  const { width } = useWindowDimensions()
  const { t } = useTranslation()
  const location = useLocation()
  const { logout } = useLogin()
  const { push } = useHistory()

  const isActive = (path: string): boolean => {
    if (path.length > 1) return location.pathname.substring(1).includes(path.substring(1))
    return location.pathname === path
  }

  const setIcon = (active: boolean, name: string) => {
    const { mikadoYellow, spanishGrey } = colors
    if (name === 'dashboard') {
      return (
        <FiActivity
          color={active ? mikadoYellow : spanishGrey.light}
          size={20}
          style={{ marginRight: 6 }}
        />
      )
    }
    if (name === 'company') {
      return (
        <FiBriefcase
          color={active ? mikadoYellow : spanishGrey.light}
          size={20}
          style={{ marginRight: 6 }}
        />
      )
    }
    if (name === 'customer') {
      return (
        <FiUsers
          color={active ? mikadoYellow : spanishGrey.light}
          size={20}
          style={{ marginRight: 6 }}
        />
      )
    }
    if (name === 'notification') {
      return (
        <FiBell
          color={active ? mikadoYellow : spanishGrey.light}
          size={20}
          style={{ marginRight: 6 }}
        />
      )
    }
    if (name === 'admin') {
      return (
        <FiKey
          color={active ? mikadoYellow : spanishGrey.light}
          size={20}
          style={{ marginRight: 6 }}
        />
      )
    }
  }

  function locationName(name: string): string {
    if (
      user.role === 'ROLE_SUPER_ADMIN' &&
      user.subscription !== 'ROLE_COMPANY_ADMIN' &&
      name === routes.company.name
    )
      return t(`navigation.${name}_sa`)
    else if (name === routes.company.name) return t(`navigation.${name}_ac`)
    else return t(`navigation.${name}`)
  }

  function backToSuperAdmin() {
    const token = cookies.get('superAdminToken')
    cookies.clear('selectedCompanyToken')
    cookies.set([{ key: 'token', value: token }])
    setUser({ ...user, token, subscription: null, temp: null })
    refetchMyInformations()
    push(routes.company.path)
  }

  return (
    <Wrapper>
      <Version>{`${process.env.REACT_APP_VERSION} - ${myInformations?.api?.version}`}</Version>
      <Top>
        {width <= size.sm ? (
          <Brand src={LOGO_SMALL} alt="Skoop Logo" />
        ) : (
          <Brand src={LOGO} alt="Skoop Logo" />
        )}
        <Nav>
          {nav.map(
            ({ name, path, access }, index) =>
              (access.includes(user.role) || access.includes(user.subscription)) && (
                <Item active={isActive(path)} key={index.toString()}>
                  {setIcon(isActive(path), name)}
                  <Location to={path}>{t(locationName(name))}</Location>
                  {isActive(path) && <Active />}
                </Item>
              ),
          )}
        </Nav>
      </Top>
      <Bottom>
        {user.subscription && (
          <Button outlined onClick={backToSuperAdmin}>
            {width <= size.sm && <Back />}
            {width > size.sm && t('navigation.back')}
          </Button>
        )}
        <Button onClick={() => logout()}>
          {width <= size.sm && <Exit />}
          {width > size.sm && t('navigation.logout')}
        </Button>
      </Bottom>
    </Wrapper>
  )
}

export default Menu
