import React, { ReactElement, useContext, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Input,
  Label,
  Color,
  Hexa,
  Requirement,
  Upload,
  Preview,
  UploadIcon,
  Text,
  Media,
} from 'components/FormCreateEditCard/styles'
import { colors } from 'theme/colors'
import { CompanyContext } from 'providers'
import { parseApiPicture } from 'utils'

const Formatting = ({ selectedSteps }: { selectedSteps: string }): ReactElement => {
  const { t } = useTranslation()
  const mediaInput = useRef(null)
  const { setCompany, company } = useContext(CompanyContext)

  return (
    <>
      {selectedSteps !== 'customer' && (
        <>
          <Label htmlFor="color">{t('edit.formatting.color')}</Label>
          <Color>
            <Hexa>{company.color || colors.mikadoYellow.toUpperCase()}</Hexa>
            <Input
              id="color"
              type="color"
              value={company.color || colors.mikadoYellow}
              onChange={(color) =>
                setCompany({ ...company, color: color.target.value.toUpperCase() })
              }
            />
          </Color>
        </>
      )}
      <Label>{t('edit.formatting.image.label')}</Label>
      <Requirement>{t('edit.formatting.image.size', { size: '750px x 246px' })}</Requirement>
      <Requirement>{t('edit.formatting.image.format')}</Requirement>
      <Upload
        onClick={() => mediaInput.current.click()}
        style={{ width: 444, padding: 0, height: 226 }}
      >
        {company.head ? (
          <Preview
            src={parseApiPicture({ img: company.head, defaultImg: UploadIcon })}
            style={{ objectFit: 'cover', height: '100%', width: '100%', borderRadius: 7 }}
          />
        ) : (
          <>
            <UploadIcon />
            <Text>{t('edit.formatting.image.load')}</Text>
          </>
        )}
        <Media
          name="logo"
          type="file"
          accept="image/png, image/jpeg"
          ref={mediaInput}
          onChange={(head) => {
            setCompany({
              ...company,
              head: head.target.files[0],
            })
          }}
        />
      </Upload>
    </>
  )
}

export default Formatting
