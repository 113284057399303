import React, { FC, ReactElement, useEffect, useState } from 'react'

import { ProviderProps, NotificationContextProps, NotificationProps } from 'types'
import { Notification as Notif, Toast } from 'components'
import { useWindowDimensions } from 'hooks'

export const NotificationContext = React.createContext<NotificationContextProps | undefined>(
  undefined,
)

NotificationContext.displayName = 'User'

const Notification: FC<ProviderProps> = ({ children }: ProviderProps) => {
  const [state, setState] = useState<NotificationProps | null>(null)
  const [display, setDisplay] = useState<boolean>(false)
  const window = useWindowDimensions()
  const duration = 10000

  // Listen to state to ensure a notification display
  // Then clear the state
  useEffect(() => {
    if (state) {
      setDisplay(true)
      setTimeout(() => {
        setDisplay(false)
        setState(null)
      }, duration)
    }
  }, [state])

  // Push data set by user in any children components in state
  function notify(newData) {
    if ((newData?.title || newData?.message) && newData?.type) {
      setState(newData)
    }
  }

  // Determinate if should use a Toast or a Notification
  // (Toast disable message content)
  function renderInstance(): ReactElement {
    if (window.width <= 768) {
      return <Toast type={state.type} title={state.title} time={duration} />
    } else if (state) {
      return <Notif type={state.type} title={state.title} message={state.message} time={duration} />
    }
  }

  const context = { notify, state, setState }

  return (
    <NotificationContext.Provider value={context}>
      {display && renderInstance()}
      {children}
    </NotificationContext.Provider>
  )
}

export default Notification
