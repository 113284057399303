import { styled, css, keyframes } from 'theme'
import { colors } from 'theme/colors'
import { Text } from 'components/Typography'

type ButtonProps = {
  isActive?: boolean
  color?: string
  isLoading?: boolean
}

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const fadeInDown = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-200px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

const spin = keyframes`
  0% {
    
    transform: rotate(0deg);
  }
  100% {
    opacity: 1;
    transform: rotate(360deg);
  }
`

export const Wrapper = styled.div`
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${colors.marine}90;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${fadeIn} 1s ease;
`

export const Modal = styled.div`
  opacity: 0;
  background: ${({ theme }) => theme.color.background};
  border-radius: 14px;
  max-width: 320px;
  padding: 20px;
  box-shadow: ${({ theme }) => theme.shadow};
  animation: ${fadeInDown} 500ms ease;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
`

export const Title = styled(Text)`
  color: ${({ theme }) => theme.color.accent};
  font-weight: ${({ theme }) => theme.font.bold};
  font-size: 18px;
  margin: 0;
  padding-bottom: 15px;
  text-align: center;
  //border-bottom: 1px solid ${({ theme }) => theme.color.alternative}50;
`
export const Subtitle = styled(Text)`
  margin-top: 10px;
  text-align: center;
  line-height: 22px;
`
export const Actions = styled.div`
  padding-top: 20px;
  //border-top: 1px solid ${({ theme }) => theme.color.alternative}50;
  display: flex;
  justify-content: space-evenly;
`

const active = css`
  border: 1px solid
    ${({ color }: ButtonProps) => (color ? colors[color] : ({ theme }) => theme.color.primary)};
  background: ${({ color }: ButtonProps) =>
    color ? colors[color] : ({ theme }) => theme.color.primary};
  color: ${colors.primaryWhite};
  transition: 200ms;
  :hover {
    opacity: 1;
    box-shadow: ${({ theme }) => theme.shadow};
  }
`

const loading = css`
  margin: 0 12%;
  width: 11%;
  cursor: progress;
  & svg {
    animation: ${spin} 1s infinite linear;
  }
`

export const Button = styled.button`
  border: 1px solid ${({ theme }) => theme.color.alternative};
  background: transparent;
  border-radius: 20px;
  color: ${({ theme }) => theme.color.alternative};
  text-transform: lowercase;
  width: 35%;
  padding: 5px 0;
  transition: 200ms;
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
  :focus {
    outline: none;
  }
  ${({ isActive }: ButtonProps) => isActive && active}
  ${({ isLoading }: ButtonProps) => isLoading && loading}
`

export const Prompt = styled.input`
  text-align: center;
  color: ${({ theme }) => theme.color.alternative};
  border: 1px solid ${({ theme }) => theme.color.alternative}90;
  margin-top: 12px;
  padding: 8px 12px;
  width: 100%;
  border-radius: 4px;
  :focus {
    outline: none;
  }
  ::placeholder {
    color: ${({ theme }) => theme.color.alternative};
  }
`
