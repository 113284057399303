import { device, styled, css } from 'theme'
import { Text as Typography } from 'components'
import { colors } from 'theme/colors'

type WrapperProps = { display: number; options: number }
type ContainerProps = { selector?: boolean }

export const optionsStyle = css`
  margin: 0;
  bottom: 62px;
  right: 18px;
`

export const Wrapper = styled.div`
  z-index: 1;
  margin-top: 30px;
  margin-right: -20px;
  position: absolute;
  width: calc(200px - 18px);
  padding: 10px 9px;
  border-radius: 8px;
  background: ${(props) => props.theme.color.shaded};
  box-shadow: ${(props) => props.theme.shadow};
  ${(wrapper: WrapperProps) => wrapper.options && optionsStyle};
  display: ${(wrapper: WrapperProps) => (wrapper.display ? 'block' : 'none')};
  ${device.sm} {
    margin: 0;
    right: 0;
    bottom: 74px;
  }
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding-left: 14px;
  padding-top: 2px;
  cursor: pointer;
  transition: 200ms;
  & p {
    color: ${({ selector }: ContainerProps) => selector && colors.mikadoYellow};
  }
  &:hover {
    background: ${(props) => props.theme.color.background};
  }
`

export const Text = styled(Typography)`
  margin: 0 0 4px 0;
  padding: 10px 0;
`

export const Icon = styled.div`
  & svg {
    margin-right: 12px;
    font-size: 18px;
    color: ${(props) => props.theme.color.alternative};
    cursor: pointer;
  }
`
