import { HTMLAttributes } from 'react'
import { styled } from 'theme'
import { colors, globalColors } from 'theme/colors'
import { CSVLink } from 'react-csv'

interface Props extends HTMLAttributes<HTMLHeadingElement> {
  margin?: string
  width?: string
  fontSize?: string | number
  color?: string
  fontWeight?: number
  align?: string
  alignItems?: string
}

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin: ${(props: Props) => props.margin || '0'};
  width: ${(props: Props) => props.width || 'auto'};
  align-items: ${(props: Props) => props.alignItems || 'flex-start'};
`

export const RowEnd = styled(Row)`
  justify-content: flex-end;
`

export const RowCenter = styled(Row)`
  text-align: center;
  justify-content: center;
  align-items: center;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${(props: Props) => props.margin || '0'};
  width: ${(props: Props) => props.width || 'auto'};
`

export const ColumnCenter = styled(Column)`
  text-align: center;
  justify-content: center;
  align-items: center;
`

export const Text = styled.p<Props>`
  color: ${({ color }) => (color ? colors[color] : globalColors.default)};
  margin: ${({ margin }) => (typeof margin === 'number' ? `${margin}px` : margin || '0px')};
  font-size: ${({ fontSize }) =>
    typeof fontSize === 'number' ? `${fontSize}px` : fontSize || '14px'};
  font-weight: ${({ fontWeight }) => fontWeight || 400};
  width: ${({ width }) => width || 'auto'};
  text-align: ${({ align }) => align || 'start'};
`

export const TextEllipsis = styled(Text)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

const DefaultButton = styled.button`
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 12px 20.9px 12px 20px;
  border-radius: 20px;
  margin: ${(props: Props) => props.margin || '0'};
`

export const Download = styled(CSVLink)`
  display: none;
`

export const Button = {
  primary: styled(DefaultButton)`
    box-shadow: 1px 1px 8px #d6d6d6;
    color: white;
    background: ${globalColors.primary};
  `,
  secondary: styled(DefaultButton)`
    box-shadow: 1px 1px 8px #d6d6d6;
    color: ${globalColors.primary};
    background: white;
  `,
  disabled: styled(DefaultButton)`
    box-shadow: 1px 1px 8px #d6d6d6;
    color: ${colors.spanishGrey.light};
    background: ${colors.translucentGray};
    opacity: 0.7;
    cursor: initial;
  `,
}

export const Card = styled.div<Props>`
  background-color: white;
  border-radius: 12px;
  padding: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${({ width }) => width || 'auto'};
`
