import { RoutesType } from 'types'
import { ROLES } from 'constants/roles'

const routes: RoutesType = {
  dashboard: {
    path: '/',
    name: 'dashboard',
    access: [ROLES.USER, ROLES.COMPANY_ADMIN, ROLES.SUPER_ADMIN],
  },
  company: {
    path: '/companies',
    name: 'company',
    access: [ROLES.USER, ROLES.COMPANY_ADMIN, ROLES.SUPER_ADMIN],
  },
  edit: {
    path: '/companies/edit',
    name: 'edit',
    access: [ROLES.USER, ROLES.COMPANY_ADMIN, ROLES.SUPER_ADMIN],
  },
  customer: {
    path: '/customers',
    name: 'customer',
    access: [ROLES.USER, ROLES.COMPANY_ADMIN],
  },
  customersCardEdit: {
    path: '/customers/edit',
    name: 'customer',
    access: [ROLES.USER, ROLES.COMPANY_ADMIN],
  },
  create: {
    path: '/customers/create',
    name: 'create',
    access: [ROLES.USER, ROLES.COMPANY_ADMIN],
  },
  notification: {
    path: '/notifications',
    name: 'notification',
    access: [ROLES.USER, ROLES.COMPANY_ADMIN],
  },
  createNotification: {
    path: '/notifications/new',
    name: 'notification',
    access: [ROLES.USER, ROLES.COMPANY_ADMIN],
  },
  duplicateNotification: {
    path: '/notifications/duplicate',
    name: 'notification',
    access: [ROLES.USER, ROLES.COMPANY_ADMIN],
  },
  editNotification: {
    path: '/notifications/edit',
    name: 'notification',
    access: [ROLES.USER, ROLES.COMPANY_ADMIN],
  },
  admin: {
    path: '/admin',
    name: 'admin',
    access: [ROLES.USER, ROLES.COMPANY_ADMIN],
  },
  login: {
    path: '/login',
    name: 'login',
  },
  downloadWallet: {
    path: '/download-wallet',
    name: 'download-wallet',
  },
}

export default routes
