import { styled, device } from 'theme'
import { Title as Typography, Text } from 'components'
import { TextProps } from 'components/Typography'
import STRIPE from 'assets/logo-small.svg'
import { colors } from 'theme/colors'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 720px;
  height: 100vh;
  margin: 0 auto;
  ${device.sm} {
    width: auto;
  }
`

export const Container = styled.div`
  z-index: 3;
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100vh;
  margin: 0 auto;
  background: #22223a;
  ${device.sm} {
    width: 100%;
  }
`

export const Form = styled.div`
  margin-top: 90px;
  width: 300px;
  ${device.xs} {
    margin-top: 60px;
    width: calc(100vw - (16px * 2));
  }
`

export const Title = styled(Typography)`
  color: ${colors.primaryWhite};
  margin-top: 10px;
  ${device.xs} {
    text-align: left;
    font-size: 31px;
    margin: 0 24px;
    line-height: normal;
  }
`

export const Language = styled(Text)`
  color: ${(text: TextProps) =>
    text.accent ? (props) => props.theme.color.background : text.color};
`

export const Lang = styled.div`
  display: flex;
  width: 60px;
  justify-content: space-between;
  & p {
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
    }
  }
`

export const Illustration = styled.div`
  z-index: 2;
  background-color: ${colors.greyish};
  background-image: url(${STRIPE});
  background-repeat: no-repeat;
  background-position: right;
  position: absolute;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(50% + 90px);
  height: 100vh;
  margin: 0 auto;
  ${device.lg} {
    background-image: none;
  }
  ${device.sm} {
    display: none;
  }
`

export const Brand = styled.img``
export const Logo = styled.img`
  display: none;
  ${device.sm} {
    display: block;
  }
`
