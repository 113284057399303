import { StatsServices } from 'types'
import API, { STATISTICS, headers } from 'api'
import { errors, api } from 'services/index'

const response = async (promise, key: string = null) => {
  const res = await promise.json()

  return {
    ...res,
    status: promise.status,
    response: key ? errors.notify(key[promise.status], promise.status) : null,
  }
}

const statistics: StatsServices = {
  collection: async () => {
    const promise = await fetch(`${API(STATISTICS.DEFAULT)}`, {
      method: 'GET',
      headers: headers(),
    })
    return response(promise, api.wallet.collection)
  },
}

export default statistics
