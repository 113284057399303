import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { CgSortAz, FiCircle } from 'react-icons/all'

import {
  TableContent,
  HeadTable,
  Row,
  Cell,
  Body,
  Wrapper,
  CellButton,
  Container,
  Skeleton,
  Empty,
  Next,
  Pagination,
  Prev,
} from 'components/Table/styles'
import { Toggle } from 'components'
import { Text } from 'components/Typography'

type Column = {
  name: string
  key: string
  type?: string
  icon?: React.SVGAttributes<SVGElement>
  action?: (arg?) => void
  sortable?: boolean
}[]

/* eslint @typescript-eslint/no-explicit-any: off */
type Data = { [key: string]: boolean | string | number | void | Record<string, unknown> }[] | any[]

type Props = {
  data?: Data
  column: Column
  fixedColumn?: Column
  loading?: boolean
  pagination?: number
}

// Set table row background style if odd or not
const isOdd = (index: number) => index % 2 === 1
const getLength = (array): number => (!array ? 6 : Object.values(array).length)
const getValue = (object, key: string) => {
  const parts: string[] = key.split('.')
  const newObject: { [k: string]: unknown } = object[parts[0]]
  if (!parts[1]) return newObject
  parts.splice(0, 1)
  const newString = parts.join('.')
  return getValue(newObject, newString)
}

function cell(col, value, indexColumn): ReactElement {
  if (col.type === 'button') {
    return (
      <CellButton
        key={`${col.key}-button-${indexColumn}`}
        disabled={value.isDeleted || !getValue(value, col.key)}
        onClick={() => col.action && col.action(getValue(value, col.key))}
      >
        {col.icon || <FiCircle />}
      </CellButton>
    )
  } else if (col.type === 'switch') {
    return (
      <Cell key={`${col.key}-cell`}>
        <Toggle checked={getValue(value, col.key) === 'active'} />
      </Cell>
    )
  } else {
    return <Cell key={col.key}>{getValue(value, col.key)}</Cell>
  }
}
// Remaining now only in Notification Layout
// Will be completely deleted once Notification is refactored
const Table = ({ data, pagination, loading, fixedColumn, column }: Props): ReactElement => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <Container>
        <TableContent>
          <HeadTable col={getLength(column)}>
            {column.map(({ name, sortable }, index) => (
              <Cell key={`${index}-col`}>
                {name}
                {sortable && <CgSortAz size={18} />}
              </Cell>
            ))}
          </HeadTable>
          <Body>
            {loading && <Skeleton />}
            {!loading &&
              data.length > 0 &&
              data.map((value, index) => (
                <Row
                  key={`${index}-row`}
                  col={getLength(column)}
                  isOdd={isOdd(index)}
                  disabled={value.isDeleted}
                >
                  {column.map((col, indexColumn) => cell(col, value, indexColumn))}
                </Row>
              ))}
          </Body>
        </TableContent>
        {fixedColumn && (
          <TableContent isAction>
            <HeadTable col={getLength(fixedColumn) > 6 ? 6 : getLength(fixedColumn)}>
              {fixedColumn.map(({ name, key }) => (
                <Cell key={key}>{name}</Cell>
              ))}
            </HeadTable>
            <Body>
              {loading && <Skeleton />}
              {!loading &&
                data.map((value, index) => (
                  <Row key={`${index}-fixed-row`} col={4} isOdd={isOdd(index)}>
                    {fixedColumn.map((col, i) =>
                      col.type === 'button' ? (
                        <CellButton key={`${col.key}-button-${i}`} onClick={col.action}>
                          {col.icon || <FiCircle />}
                        </CellButton>
                      ) : (
                        <Cell key={`${col.key}-cell`}>{getValue(value, col.key)}</Cell>
                      ),
                    )}
                  </Row>
                ))}
            </Body>
          </TableContent>
        )}
      </Container>
      {!data || (data.length < 1 && <Empty>{t('table.empty')}</Empty>)}
      {data && pagination > 10 && (
        <Pagination>
          <Prev onClick={() => null} />
          <Text accent>
            {t('table.pagination', { current: 1, total: Math.ceil(pagination / 30) })}
          </Text>
          <Next onClick={() => null} />
        </Pagination>
      )}
    </Wrapper>
  )
}

export default Table
