type Params = {
  text: string
  possibleDelimiters: Array<string>
}

function weedOut(delimiter: string, text: string) {
  let cache = -1
  function checkLength(line) {
    if (!line) {
      return true
    }

    const length = line.split(delimiter).length
    if (cache < 0) {
      cache = length
    }
    return cache === length && length > 1
  }

  return text.split('\n').every(checkLength)
}

const findCsvDelimiter = ({ text, possibleDelimiters }: Params): string => {
  return possibleDelimiters.find((delimiter) => weedOut(delimiter, text)) || ','
}

export default findCsvDelimiter
