import { ErrorServices, Notify } from 'types'

type AdminErrors = {
  collection: { [key: string]: string }
  create: { [key: string]: string }
  once: { [key: string]: string }
  remove: { [key: string]: string }
  update: { [key: string]: string }
}

type CompanyErrors = {
  collection: { [key: string]: string }
  create: { [key: string]: string }
  once: { [key: string]: string }
  remove: { [key: string]: string }
  update: { [key: string]: string }
  settings: {
    once: { [key: string]: string }
    update: { [key: string]: string }
  }
}

type CustomerErrors = {
  collection: { [key: string]: string }
  create: { [key: string]: string }
  once: { [key: string]: string }
  remove: { [key: string]: string }
  update: { [key: string]: string }
  import: { [key: string]: string }
  wallet: {
    once: { [key: string]: string }
    update: { [key: string]: string }
    remove: { [key: string]: string }
  }
}

type LoginErrors = {
  user: { [key: number]: string }
  switch: { [key: number]: string }
  refresh: { [key: number]: string }
  reset: { [key: number]: string }
  save: { [key: number]: string }
}

export type Errors = {
  admin: AdminErrors
  company: CompanyErrors
  customer: CustomerErrors
  login: LoginErrors
  wallet: unknown
  notification: unknown
  500: string
}

/* eslint @typescript-eslint/no-explicit-any: off */
export const api: any | Errors = {
  admin: {
    collection: {
      200: 'Collection successfully retrieved',
    },
    create: {
      201: 'admin.successfullyCreated',
      409: 'admin.emailAlreadyExist',
      400: 'error',
      422: 'error',
    },
    once: {
      200: 'Administrator successfully retrieved',
      404: 'adminNotFound',
    },
    remove: {
      204: 'Administrator successfully deleted',
      404: 'Unable to delete a non found administrator',
    },
    update: {
      200: 'Administrator successfully updated',
      400: 'Administrator email is invalid',
      404: 'Unable to update a non found administrator',
      409: 'An administrator with the same email address already exists',
      422: 'Unprocessable entry',
    },
  },
  company: {
    collection: {
      200: 'Collection successfully retrieved',
    },
    create: {
      201: 'Company successfully created',
      400: 'Company email is invalid',
      422: 'Unprocessable entry',
    },
    once: {
      200: 'Company successfully retrieved',
      404: 'Company not found',
    },
    remove: {
      204: 'Company successfully deleted',
      404: 'Unable to delete a non found company',
    },
    update: {
      200: 'Company successfully updated',
      400: 'Company email is invalid',
      404: 'Unable to update a non found company',
      422: 'Unprocessable entry',
    },
    settings: {
      once: {
        200: 'Company successfully retrieved',
        404: 'Company not found',
      },
      update: {
        200: "Company's settings successfully updated",
        400: "Company's settings input invalid",
        404: "Unable to update a non found company's settings",
        422: "Unprocessable company's setting entry",
      },
    },
  },
  customer: {
    collection: {
      200: 'Collection successfully retrieved',
    },
    create: {
      202: 'User successfully created',
      400: 'Administrator email is invalid',
      422: 'Unprocessable entry',
    },
    once: {
      200: 'User successfully retrieved',
      404: 'User not found',
    },
    remove: {
      202: 'User successfully deleted',
      404: 'Unable to delete a non founded user ',
    },
    update: {
      202: 'User successfully updated',
      400: 'User email is invalid',
      404: 'Unable to update a non founded user',
      422: 'Unprocessable entry',
    },
    import: {
      202: 'Customers successfully imported',
      400: 'Invalid CSV input',
      422: 'Unprocessable CSV file',
    },
    export: {
      202: 'Customers successfully exported',
      404: 'error',
      429: 'Please wait before exporting clients again',
    },
    wallet: {
      once: {
        200: 'User successfully retrieved',
        404: "User's wallet not found",
      },
      update: {
        200: "User's wallet successfully updated",
        404: "Unable to update a non founded user's wallet",
      },
      remove: {
        204: "User's wallet successfully deleted",
        404: "Unable to delete a non founded user's wallet",
      },
    },
  },
  login: {
    user: {
      200: 'Successfully logged in',
      401: 'Email or password invalid',
    },
    switch: {
      200: 'Successfully logged in',
      401: 'Email or password invalid',
    },
    refresh: {
      200: "User's credentials successfully refreshed",
      400: "Bad user's credentials inputs",
      422: 'Unable to connect as this user',
    },
    reset: {
      200: 'emailSent',
      400: 'Please insert a valid email',
      404: 'adminNotFound',
      422: 'Unprocessable entry',
    },
    save: {
      200: 'Password successfully updated',
      400: 'One or more characters set in new password are not authorized',
      404: 'Can not update a password for a not found user',
      422: 'Unprocessable entry',
    },
  },
  wallet: {
    collection: {
      200: 'Collection successfully retrieved',
    },
    once: {
      201: 'Wallet successfully retrieved',
      404: 'Wallet not found',
    },
    create: {
      201: 'Collection successfully retrieved',
      400: 'Please provide good wallet data',
      422: 'Unprocessable entry',
    },
    update: {
      201: 'Wallet successfully updated',
      400: 'Please provide good wallet data',
      404: 'Can not update a not found wallet',
      422: 'Unprocessable entry',
    },
    remove: {
      204: 'Wallet successfully removed',
      404: 'Can not remove a not found wallet',
    },
  },
  notification: {
    collection: {
      200: 'Notification successfully retrieved',
    },
    once: {
      201: 'Notification successfully retrieved',
      404: 'Notification not found',
    },
    create: {
      201: 'notification.form.create.success',
      400: 'notification.form.create.error',
      422: 'notification.form.create.error',
    },
    update: {
      200: 'notification.form.update.success',
      400: 'notification.form.update.error',
      404: 'notification.form.update.error',
      422: 'notification.form.update.error',
    },
    remove: {
      204: 'Notification successfully removed',
      404: 'Can not remove a not found notification',
    },
    enable: {
      200: 'notification.successfullyEnabled',
      400: 'error',
      404: 'error',
      422: 'error',
    },
    disable: {
      200: 'notification.successfullyDisabled',
      400: 'error',
      404: 'error',
      422: 'error',
    },
    customers: {
      200: 'Targeted customers found',
      403: 'error',
      404: 'error',
    },
    export: {
      202: 'Notifications successfully exported',
      404: 'error',
      429: 'Please wait before exporting notifications again',
    },
  },
  me: {
    once: {
      200: 'Your informations have been successfully found',
      404: 'Can not find your informations',
    },
  },
  500: 'Something goes wrong, please try again later!',
}

const errorsType = (
  status: number | string | undefined,
): 'success' | 'warning' | 'error' | 'info' => {
  if (!status) return 'info'
  switch (status.toString().charAt(0)) {
    case '2':
      return 'success'
    case '4':
      return 'warning'
    case '5':
      return 'error'
    default:
      return 'info'
  }
}

const errors: ErrorServices = {
  notify: (message, status, title): Notify => {
    return {
      title: title || errorsType(status),
      message: message || api[500],
      type: errorsType(status),
    }
  },
}

export default errors
